import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { CurrencyLocalPipe } from '../currency-local/currency-local.pipe';

@Directive({selector: '[app-currency-formatter]' })
export class AppCurrencyFormatterDirective implements OnInit {

  private el: any;

  constructor(private elementRef: ElementRef, private currencyLocalPipe: CurrencyLocalPipe) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.el.value || this.el.value === 0 ? this.currencyLocalPipe.transform(this.el.value) : this.el.value;
  }

  @HostListener('focus', ['$event.target.value']) onFocus(value) {
    this.el.value = value ? this.currencyLocalPipe.prepareToEdit(value) : value;
  }

  @HostListener('blur', ['$event.target.value']) onBlur(value) {
    this.el.value = value ? this.currencyLocalPipe.transform(value) : value;
  }
}

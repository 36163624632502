import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_CONFIG, PARAM } from '../../configs';
import { ApiService } from '.';

export interface TopupPlan {
  bonus_coins: number;
  coins: number;
  price: number;
  price_discount: number;
  slug: string;
}

export interface Transaction {
  slug: string,
  created: string,
  value: number;
  description: string,
  running_balance: number;
  expiry_date: string,
  payment_detail: string;
}

@Injectable()
export class WalletService {

  constructor(private apiService: ApiService) { }

  public getWallet(): Observable<{coin: number}> {
    return this.apiService.get(API_CONFIG.payment.wallet);
  }

  public getWalletTopupPlans(): Observable<{results: TopupPlan[]}> {
    return this.apiService.get(API_CONFIG.payment.walletTopupPlans);
  }

  public getTransactions(value?: number): Observable<Transaction[]> {
    const limit = value ? value.toString() : '';

    const url = API_CONFIG.payment.getWalletTransactions
      .replace(PARAM, limit);

    return this.apiService.get(url).pipe(map(data => data.results));
  }

  public sendTopupPlan(topupPlanId: string): Observable<{coin: number}> {
    return this.apiService.post(
      API_CONFIG.payment.topupWallet,
      { topup_plan: topupPlanId }
    );
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html'
})
export class StarsComponent implements OnInit {

  @Input() rating: any;
  @Input() readonly: boolean = false;
  @Output() ngModelChange = new EventEmitter();
  stars: number[] = [1, 2, 3, 4, 5];

  constructor() { }

  ngOnInit() {
  }

  onRate(star) {
    if (!this.readonly) {
      this.rating = star;
      this.ngModelChange.emit(this.rating);
    }
  }
}

import { Component, Input, ElementRef, HostListener, OnDestroy } from '@angular/core';


@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnDestroy {
  static allInstances: DropdownMenuComponent[] = [];

  @Input() hideOnClick: boolean;
  showOptions: boolean;

  constructor(private _eref: ElementRef) {
    DropdownMenuComponent.allInstances.push(this);
  }

  toggleOptions(event: MouseEvent) {
    event.stopPropagation();

    DropdownMenuComponent.allInstances
      .filter((item: DropdownMenuComponent) => item !== this)
      .forEach((item: DropdownMenuComponent) => item.showOptions = false);

    setTimeout(() => this.showOptions = !this.showOptions, 0);
  }

  @HostListener('document:click', ['$event'])
  onClickOut(event: MouseEvent) {
    if (this.hideOnClick || (this.showOptions && !this._eref.nativeElement.contains(event.target))) {
      this.showOptions = false;
    }
  }

  ngOnDestroy() {
    DropdownMenuComponent.allInstances.every((item, index) => {
      if (item === this) {
        DropdownMenuComponent.allInstances.splice(index, 1);
        return false;
      }

      return true;
    });
  }
}

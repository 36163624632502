import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

import { Observable } from 'rxjs';

import { ApiService } from '../../api';
import { API_CONFIG, PARAM } from '../../../configs';

@Injectable()
export class UserProfileService {
  constructor(private apiService: ApiService) {
  }

  profile(): Observable<Response> {
    return this.apiService.get(API_CONFIG.profile.get);
  }
}

import { Component, AfterViewInit } from '@angular/core';
import { ViralLoopsService } from '../viral-loops/services/viral-loops.service';

@Component({
  selector: 'app-referral-popup',
  templateUrl: './referral-popup.component.html',
  styleUrls: ['./referral-popup.component.scss']
})
export class ReferralPopupComponent implements AfterViewInit {
  animatedPopup: boolean = false;
  popupOpened: boolean = false;
  widgetLoadingStarted: boolean = false;

  constructor(
    private viralLoopsService: ViralLoopsService,
  ) {}

  ngAfterViewInit() {
    const interval = setInterval(() => {
      // Check when it's possible to start Viral Loops widget loading
      if (this.viralLoopsService.campaign && this.viralLoopsService.campaign.widgets) {
        clearInterval(interval);

        setTimeout(() => {
          // Start widget loading in some time to be sure we have correct campaign data (ref url etc.)
          this.viralLoopsService.loadWidgets();

          // Some time to wait while widget is loaded
          setTimeout(() => {
            this.widgetLoadingStarted = true;
            setTimeout(() => this.animatedPopup = true, 1000);

          }, 1000);

        }, 500);
      }

    }, 500);
  }

  togglePopup(opened: boolean): void {
    this.popupOpened = opened;
  }
}

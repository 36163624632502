import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, AfterViewInit,
  ViewChild, ElementRef, NgZone} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import { _t } from 'app/modules/core/other/translate-marker';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() accept: string;
  @Input() acceptShow: string;
  @Input() changeable = true;
  @Input() disabled = false;
  @Input() filledLabel = _t('contacts.opportunities.change');
  @Input() emptyLabel = _t('shared.choose');
  @Input() files: File[];
  @Input() maxFileSize = 1048576; // bytes, default is 1 MB
  @Input() previewWidth = 50;
  @Input() removable = false;
  @Output() onRemove: EventEmitter<any> = new EventEmitter();
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('content') content: ElementRef;

  focus: boolean;
  selfInputChange: boolean;

  constructor(public sanitizer: DomSanitizer, public zone: NgZone) {}

  ngOnInit() {
    this.files = [];
  }

  ngAfterViewInit() {
    this.zone.runOutsideAngular(() => {
      this.content.nativeElement.addEventListener('dragover', this.onDragOver.bind(this));
    });
  }

  onFileSelect(event) {
    if (this.isIE11() && this.selfInputChange) {
      this.selfInputChange = false;
      return;
    }

    this.files = [];

    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!this.isFileSelected(file)) {
        if (this.validate(file)) {
            if (this.isImage(file)) {
              file.objectURL = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(files[i])));
            }

            this.files.push(files[i]);
        }
      }
    }

    this.onSelect.emit({originalEvent: event, files: this.files});

    this.clearInputElement();
  }

  isFileSelected(file: File): boolean {
    for (const sFile of this.files) {
      if ((sFile.name + sFile.type + sFile.size) === (file.name + file.type + file.size)) {
        return true;
      }
    }

    return false;
  }

  isIE11() {
    return !!window['MSInputMethodContext'] && !!document['documentMode'];
  }

  validate(file: File): boolean {
    if (this.accept && !this.isFileTypeValid(file)) {
      // TODO: handle 'accept error'
      return false;
    }

    if (this.maxFileSize  && file.size > this.maxFileSize) {
      // TODO: handle 'maxsize error'
      return false;
    }

    return true;
  }

  private isFileTypeValid(file: File): boolean {
    const acceptableTypes = this.accept.split(',');
    for (const type of acceptableTypes) {
      const acceptable = this.isWildcard(type) ? this.getTypeClass(file.type) === this.getTypeClass(type)
                                              : file.type == type || this.getFileExtension(file) === type;

      if (acceptable) {
        return true;
      }
    }

    return false;
  }

  getTypeClass(fileType: string): string {
    return fileType.substring(0, fileType.indexOf('/'));
  }

  isWildcard(fileType: string): boolean {
    return fileType.indexOf('*') !== -1;
  }

  getFileExtension(file: File): string {
    return '.' + file.name.split('.').pop();
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  onImageLoad(img: any) {
    window.URL.revokeObjectURL(img.src);
  }

  remove(event: Event, index: number) {
    this.clearInputElement();
    this.onRemove.emit({originalEvent: event, file: this.files[index]});
    this.files.splice(index, 1);
  }

  clearInputElement() {
    if (this.fileInput && this.fileInput.nativeElement) {
      if (this.isIE11()) {
        this.selfInputChange = true; // IE11 fix to prevent onFileChange trigger again
      }

      this.fileInput.nativeElement.value = '';
    }
  }

  onDragEnter(e) {
    if (!this.disabled) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onDragOver(e) {
    if (!this.disabled) {
      e.stopPropagation();
      e.preventDefault();
    }
  }

  onDrop(event) {
    if (!this.disabled) {
      event.stopPropagation();
      event.preventDefault();

      const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      const allowDrop = files && files.length === 1;

      if (allowDrop) {
        this.onFileSelect(event);
      }
    }
  }

  onFocus() {
    this.focus = true;
  }

  onBlur() {
    this.focus = false;
  }

  formatSize(bytes) {
    if (bytes == 0) {
      return '0 B';
    }
    const k = 1000,
    dm = 3,
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  ngOnDestroy() {
    if (this.content && this.content.nativeElement) {
      this.content.nativeElement.removeEventListener('dragover', this.onDragOver);
    }
  }
}


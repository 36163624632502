import { Component, OnInit } from '@angular/core';
import { DialogObserverService } from '../../core/services/dialog-observer/dialog-observer.service';
import { FormChoicesService } from '../../core/services/form-helpers/form-choises.service';
import { TableBaseItemComponent } from '../table/table-base-item.component';

@Component({
  selector: 'app-list-card-activity',
  templateUrl: './list-card-activity.component.html',
  styleUrls: ['./list-card-activity.component.scss']
})
export class ListCardActivityComponent extends TableBaseItemComponent implements OnInit {

  type = 'activity';
  date: string;

  constructor(dialogObserver: DialogObserverService, private formChoicesService: FormChoicesService) {
    super(dialogObserver);
  }

  ngOnInit() {
    this.date = this.item.activity_type === 'task' ? this.item.to_datetime : this.item.from_datetime;
  }

  get dateExpired(): boolean {
    return new Date(this.date) < new Date() && (this.item.status === 'pending' || this.item.status === 'postponed');
  }
}

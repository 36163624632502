export class Opportunity {
  slug?: string = null;
  contact: string | any = null;
  name: string = null;
  closing_datetime?: Date = null;
  source: string = null;
  amount: number = null;
  commission: number = null;
  notes?: string = null;
  status: string = null;
  probability: number = null;

  constructor() {};
}

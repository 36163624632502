import { Component, OnInit } from '@angular/core';
import { PLAN_OPTIONS } from './premium-plans-option.config';
import { ApiService } from 'app/modules/api';
import { API_CONFIG } from 'app/configs';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router } from '@angular/router';



@Component({
    selector : 'premium-plan-component',
    templateUrl : './premium-plan-detail.component.html',
    styleUrls : ['./premium-plan-detail.component.scss']
})

export class PremiumPlanDetailsComponent implements OnInit{
    options: any[] = [];
    selectedOptions: any[] = [];
    otherOption: FormControl = new FormControl('');
    requestData: any = {'reasons' : []};
    isLoading: boolean = false;


    constructor (private apiService: ApiService, private toastr: ToastrService,  private router: Router, private translateService: TranslateService, private location: Location) {}

    ngOnInit () {
        this.options = PLAN_OPTIONS;
    }

    selectOption(index: number) {
        if (this.selectedOptions.includes(index)) {
            this.selectedOptions = this.selectedOptions.filter( i => i !== index)
        } else {
            this.selectedOptions.push(index);
        }
    }

    isSelected(index: number) {
        return this.selectedOptions.includes(index) ? '2px solid #F58320' : '';
    }

    sendReasons() {
        this.isLoading = true;
        if (this.otherOption.value) {
            this.selectedOptions = this.selectedOptions.filter(option => option !== this.options.length - 1 );
            this.requestData['reasons'].push(this.otherOption.value);
        }
        // Get translation data
        this.selectedOptions.forEach(option => {
            this.translateService.get(this.options[option].text).subscribe(
                value => this.requestData['reasons'].push(value)
            )
        });


        this.apiService.post(API_CONFIG.analytics.premiumPlanQuery, this.requestData)
            .pipe(finalize(() => {
                this.isLoading = false;
                this.requestData['reasons'] = [];
            }))
            .subscribe((response) => {
                this.toastr.success(this.translateService.instant('premiumPlanDetails.successMessage'), '', {
                    timeOut: 7000,
                    closeButton: true
                  });

                  this.router.navigate(['profile/builder/edit']);


            },
                (error) => {
                    this.toastr.error(this.translateService.instant('premiumPlanDetails.errorMessage'), '', {
                    timeOut: 7000,
                    closeButton: true
                  });
                }
            );
    }

    goBack() {
        this.location.back();
    }
}
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserTokenService, IUserToken } from '../auth/services/user.token.service';
import { API_CONFIG } from '../../configs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  authApi: string[];

  constructor(private userTokenService: UserTokenService) {
    this.authApi = Object.keys(API_CONFIG.auth).map((key: string) => API_CONFIG.auth[key]);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authApi.indexOf(req.url) === -1) {
      const userToken: IUserToken = this.userTokenService.get();

      if (userToken && this.userTokenService.isValid()) {
        const tokenPrefix = req.url.includes('ipinfo.io') ? 'Token' : userToken['tokenPrefix'];

        const authReq = req.clone({
          headers: req.headers
            .set('Authorization', `${tokenPrefix} ${userToken['token']}`)
        });

        return next.handle(authReq);
      }
    }

    return next.handle(req);
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SearchService {

  searchParam = new Subject();
  searchParam$ = this.searchParam.asObservable();

  resetSearchParam() {
    this.searchParam.next(null);
  }
}

export const REGEX_CONFIG = {
  URL_REGEX: new RegExp(/^(?:http|ftp)s?:\/\/(?:(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z]{2,}\.?)|localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[?[A-F0-9]*:[A-F0-9:]+\]?)(?::\d+)?(?:\/?|[\/?]\S+)$/, 'i'),
  DATE_REGEX: /^(0[1-9]|[12][0-9]|3[01])[\/\-](0[1-9]|1[012])[\/\-]\d{4}$/,
  DATE_TIME_REGEX: /^(0[1-9]|[12][0-9]|3[01])[\/\-](0[1-9]|1[012])[\/\-]\d{4} [0-2][0-9]:[0-5][0-9]$/,
  TIME_REGEX: /^[0-2][0-9]:[0-5][0-9]$/,
  HTTP_PREFIX_REGEX: /^(http)s?:\/\//,
  EMAIL_REGEX: /^(.+)@(.+){1,}\.(.+){2,}$/,
  PHONE_REGEX: /^\+?1?\d{9,15}$|^$/,
  POSITIVE_NUMBER_REGEX: /^[0-9]+(\.[0-9]{1,2})?/,
  POSITIVE_NUMBER_REGEX_WITHOUT_ZERO: /^([1-9][0-9]?)+(\.[0-9]{1,2})?|0+\.[0-9]*[1-9][0-9]*/,
  POSITIVE_INTEGER_NUMBER_REGEX: /^[0-9][^\.]*$/,
  POSITIVE_INTEGER_NUMBER_REGEX_WITHOUT_ZERO: /^([1-9][0-9]?)[^\.]*$/,
  NUMBER_REGEX: /^-?[0-9]+(\.[0-9]{1,2})?/,
  NAME_REGEX: /^[a-zA-Z]+$/,
};

import { Input } from '@angular/core';
import { DialogObserverService } from '../../core/services/dialog-observer/dialog-observer.service';
import { BaseComponent } from '../../core/base/base-component';

export class TableBaseItemComponent extends BaseComponent {

  public type;
  @Input('item') item: any;
  

  constructor(private dialogObserver: DialogObserverService) {
    super();
  }

  viewItem(event: Event, item: any): void {
    event.stopPropagation();
    this.dialogObserver.emit(`view_${this.type}`, item);
  }

  editItem(event: Event, item: any): void {
    event.stopPropagation();
    this.dialogObserver.emit(`edit_${this.type}`, item);
  }

  deleteItem(event: Event, item: any): void {
    event.stopPropagation();
    this.dialogObserver.emit(`delete_${this.type}`, item);
  }
}

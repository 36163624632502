import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import * as isToday from 'date-fns/is_today';
import * as isTomorrow from 'date-fns/is_tomorrow';

@Pipe({
  name: 'dateActivity'
})
export class DateActivityPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (isToday(value)) {
      return 'Today';
    }
    if (isTomorrow(value)) {
      return 'Tomorrow';
    }
    let datePipe = new DatePipe("en-US");
    return datePipe.transform(value, 'd MMM y');
  }
}

import { _t } from 'app/modules/core/other/translate-marker';

export default [
    {
        title: _t('subscription.isYearlyDiscountTitle'),
        text: _t('subscription.isYearlyDiscountText')
    },
    {
        title: _t('subscription.howCancelTitle'),
        text: _t('subscription.howCancelText')
    },
    {
        title: _t('subscription.howStartWritingTitle'),
        text: _t('subscription.howStartWritingText')
    },
    {
        title: _t('subscription.haveMoreQuestionsTitle'),
        text: _t('subscription.haveMoreQuestionsText')
    }
];

import { FormControl, FormGroup } from '@angular/forms';
import { format, isDate } from 'date-fns';
import { REGEX_CONFIG } from '../../../configs';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { LOCALIZATION_CONFIG } from '../../../configs/localization.config';
import { _t } from 'app/modules/core/other/translate-marker';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ValidationService {

  static REGEX = REGEX_CONFIG;

  static valueWithoutCurrencySymbol(value: string): string {
    value = value.toString().replace(/,/g, '');
    LOCALIZATION_CONFIG.GET_CURRENCY_SYMBOLS.map(symbol => value = value.replace(symbol, ''));
    return value;
  }

  static numberValidator(control) {
    if (control.value) {
      const controlValue = ValidationService.valueWithoutCurrencySymbol(control.value);
      if (controlValue.match(ValidationService.REGEX.NUMBER_REGEX) || !control.value) {
        return null;
      } else {
        return {'invalidNumber': true};
      }
    }
  }

  static positiveNumberValidator(control) {
    if (control.value) {
      const controlValue = ValidationService.valueWithoutCurrencySymbol(control.value);
      if (controlValue.match(ValidationService.REGEX.POSITIVE_NUMBER_REGEX) || !control.value) {
        return null;
      } else {
        return {'invalidPositiveNumber': true};
      }
    }
  }

  static positiveNumberValidatorWithoutZero(control) {
    if (control.value) {
      const controlValue = ValidationService.valueWithoutCurrencySymbol(control.value);
      if (controlValue.match(ValidationService.REGEX.POSITIVE_NUMBER_REGEX_WITHOUT_ZERO) || !controlValue) {
        return null;
      } else {
        return {'invalidPositiveNumberWithoutZero': true};
      }
    }
  }

  static positiveIntegerNumberValidator(control) {
    if (control.value) {
      const controlValue = ValidationService.valueWithoutCurrencySymbol(control.value);
      if (controlValue.match(ValidationService.REGEX.POSITIVE_INTEGER_NUMBER_REGEX) || !controlValue) {
        return null;
      } else {
        return {'invalidPositiveIntegerNumber': true};
      }
    }
  }

  static positiveIntegerNumberValidatorWithoutZero(control) {
    if (control.value) {
      const controlValue = ValidationService.valueWithoutCurrencySymbol(control.value);
      if (controlValue.match(ValidationService.REGEX.POSITIVE_INTEGER_NUMBER_REGEX_WITHOUT_ZERO) || !controlValue) {
        return null;
      } else {
        return {'invalidPositiveIntegerNumberWithoutZero': true};
      }
    }
  }

  static currencyMaxValue(control): any | null {
    if (control.value) {
      const controlValue = parseFloat(ValidationService.valueWithoutCurrencySymbol(control.value));
      return controlValue <= 1000000000000 ? null : {'invalidCurrencyMaxValue': true };
    }
  }

  static dateTypeValidator(control) {
    if (!control.value || isDate(control.value)) {
      return null;
    } else {
      return { 'invalidDate': true };
    }
  }

  static dateTimeTypeValidator(control) {
    if (!control.value || isDate(control.value)) {
      return null;
    } else {
      return { 'invalidDateTime': true };
    }
  }

  static maxLengthValidator(control) {
    if (control && control.value) {
      const val: string = (control.value).toString();
      return parseInt(control.value, 10) > 0 && val.length <= 4 ? null : {
        'invalidYear': true
      };
    } else {
      return null;
    }
  }

  static emailValidator(control) {
    if (!control.value || control.value.match(ValidationService.REGEX.EMAIL_REGEX)) {
      return null;
    } else {
      return {'invalidEmailAddress': true};
    }
  }

  static nameValidator(control) {
    if (!control.value || control.value.match(ValidationService.REGEX.NAME_REGEX)) {
      return null;
    } else {
      return {'invalidName': true};
    }
  }

  static phoneValidator(control) {
    if (!control.value || control.value.match(ValidationService.REGEX.PHONE_REGEX)) {
      return null;
    } else {
      return {'invalidPhoneNumber': true};
    }
  }

  static requiredPhoneOrEmail(control) {
    if (control['parent']) {
      const phoneNumberValue = control.parent.controls.phone_number.value;
      const emailValue = control.parent.controls.email.value;

      if (phoneNumberValue || emailValue) {
        return null;
      } else {
        return {'requiredPhoneOrEmail': true};
      }
    }
  }

  static passwordMatch(control: FormControl) {
    if (control['parent']) {
      const password = (<FormGroup>control['parent']).controls['password'];

      if (control.value === password.value) {
        return null;
      } else {
        return {'passwordNotEqual': true};
      }
    }
  }

  static passwordValidator(control) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(.+){8,128}$/)) {
      return null;
    } else {
      return {'invalidPassword': true};
    }
  }

  constructor(private toastr: ToastrService, private translateService: TranslateService) { }

  getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: _t('common.validatorErrorMessage.required'),
      requiredPhoneOrEmail: _t('common.validatorErrorMessage.requiredPhoneOrEmail'),
      invalidCreditCard: _t('common.validatorErrorMessage.invalidCreditCard'),
      invalidPhoneNumber: _t('common.validatorErrorMessage.invalidPhoneNumber'),
      invalidEmailAddress: _t('common.validatorErrorMessage.invalidEmailAddress'),
      invalidName: _t('common.validatorErrorMessage.invalidName'),
      invalidNumber: _t('common.validatorErrorMessage.invalidNumber'),
      invalidPositiveNumber: _t('common.validatorErrorMessage.invalidPositiveNumber'),
      invalidPositiveNumberWithoutZero: _t('common.validatorErrorMessage.invalidPositiveNumberWithoutZero'),
      invalidPositiveIntegerNumber: _t('common.validatorErrorMessage.invalidPositiveIntegerNumber'),
      invalidPositiveIntegerNumberWithoutZero: _t('common.validatorErrorMessage.invalidPositiveIntegerNumberWithoutZero'),
      invalidPassword: _t('common.validatorErrorMessage.invalidPassword'),
      invalidYear: _t('common.validatorErrorMessage.invalidYear'),
      passwordNotEqual: _t('common.validatorErrorMessage.passwordNotEqual'),
      mismatchedPasswords: _t('common.validatorErrorMessage.mismatchedPasswords'),
      minlength: validatorValue ? `${this.translateService.instant('common.validatorErrorMessage.minlength')} ${validatorValue.requiredLength}` : null,
      maxlength: validatorValue ? `${this.translateService.instant('common.validatorErrorMessage.maxlength')} ${validatorValue.requiredLength}` : null,
      max: validatorValue ? `${this.translateService.instant('common.validatorErrorMessage.max')} ${validatorValue.max}` : null,
      minDate: _t('common.validatorErrorMessage.minDate'),
      invalidCurrencyMaxValue: _t('common.validatorErrorMessage.invalidCurrencyMaxValue'),
      invalidDate: _t('common.validatorErrorMessage.invalidDate'),
      invalidDateTime: _t('common.validatorErrorMessage.invalidDateTime')
    };

    return config[validatorName];
  }

  handleFormErrorMessage(validatorName: string, validatorValue?: any) {
    return validatorValue;
  }

  validateForm(form: any) {
    if (!form) {
      return;
    }
    for (const control in  form.controls) {
      if (form.controls.hasOwnProperty(control)) {
        const errors = form.get(control).errors;
        for (const error in errors) {
          if (errors.hasOwnProperty(error)) {
            form.controls[control].setErrors[control] = this.getValidatorErrorMessage(error);
          }
        }
      }
    }
  }

  handleServerError(form: any, errors: { field_errors: any, non_field_errors: any }) {
    if (errors && errors.field_errors && Object.keys(errors.field_errors).length) {
      for (const control in form.controls) {
        if (form.controls.hasOwnProperty(control) && errors.field_errors[control]) {
          form.controls[control].setErrors({'formErrors': errors.field_errors[control]});
        }
      }
    }

    if (errors && errors.non_field_errors && Object.keys(errors.non_field_errors).length) {
      this.toastr.error(errors.non_field_errors.message, '', {
        timeOut: 7000,
        closeButton: true
      });
    }
  }
}

import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { filter, switchMap } from 'rxjs/operators';
import { Activity } from '../models/activity.model';
import { ActivityFormDialogComponent } from './activity-form-dialog/activity-form-dialog.component';
import { ActivityService } from '../../api/activity.service';
import { ActivityTypeDialogComponent, ActivityTypes } from './activity-type-dialog/activity-type-dialog.component';
import { ActivityViewDialogComponent } from './activity-view-dialog/activity-view-dialog.component';
import { BaseComponent } from '../../core/base/base-component';
import { Contact } from '../../contacts/models/contact.model';
import { ContactFormDialogService } from '../../shared/contact-form-dialog/contact-form-dialog.service';
import { DialogOption } from '../../shared/dialog-option-card/dialog-option-card.component';
import { DialogObserverService } from '../../core/services/dialog-observer/dialog-observer.service';
import { DialogService } from '../../shared/dialog/dialog.service';
import { Opportunity } from '../../opportunities/opportunity-list/opportunity';
import { OpportunityDialogService } from '../../opportunities/opportunity-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { _t } from 'app/modules/core/other/translate-marker';
import { TranslateService } from '@ngx-translate/core';

/**
 * Complicated flow with Dialogs interaction (Activity, Opportunity, Contact form dailogs)
 *
 *   Activity
 *      -> forward to add Contact
 *          -> back to Activity
 *            - returns created Contact
 *            - returns nothing (false, null etc.)
 *      -> forward to add Opportunity
 *          -> back to Activity
 *            - returns created Opportunity and Contact (from Opportunity)
 *            - returns nothing
 *          -> forward to add Contact
 *              -> back to Opportunity
 *                - returns created Contact
 *                - returns nothing
 *                  -> back to Activity
 *                    - returns created Opportunity and Contact (from Opportunity)
 *                    - returns nothing
 *      -> back to page
 *          - returns created Activity
 *          - returns nothing
 */

@Injectable()
export class ActivityDialogsService extends BaseComponent  {

  dialogObserver: DialogObserverService;

  constructor (
    private activityService: ActivityService,
    private contactFormDialogService: ContactFormDialogService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private opportunityDialogService: OpportunityDialogService,
    private toastr: ToastrService,
    private translateService: TranslateService
  ) {
    super();
  }

  public initSubscription(dialogObserver: DialogObserverService)  {
    if (!this.dialogObserver) {
      this.dialogObserver = dialogObserver;
      this.dialogRequestSubscriber();
    }
  }

  private dialogRequestSubscriber(): void {
    this.subs = this.dialogObserver.dialogRequest$
      .subscribe(data => {
        switch (data.type) {
          case 'add_activity' : this.openActivityTypeDialog(data.item); break;
          case 'view_activity' : this.openActivityViewDialog(data.item); break;
          case 'edit_activity' : this.openActivityFormDialog(null, data.item); break;
          case 'delete_activity' : this.deleteActivity(data.item); break;
        }
      });
  }

  /**
   * Handle different cases of Activity dialog form closing:
   * contact creation was opened, opportunity creation was opened, activity dialog was closed
   */
  private onCloseActivityFormDialogHandler(activityType: DialogOption, dialogCloseData: any): void {
    // Activity -> forward to add Contact
    if (dialogCloseData.contact && dialogCloseData.notSubmittedActivity) {
      this.openReferredContactFormDialog(activityType, dialogCloseData.notSubmittedActivity, dialogCloseData.contact);

    // Activity -> forward to add Opportunity
    } else if (dialogCloseData.opportunity && dialogCloseData.notSubmittedActivity) {
      this.openReferredOpportunityFormDialog(activityType, dialogCloseData.notSubmittedActivity, dialogCloseData.opportunity);

    // Activity -> back to page
    } else if (dialogCloseData.activity) {
      this.dialogObserver.emit('refreshActivityList');
    }
  }

  /**
   * Handle different cases of Opportunity dialog form closing:
   * contact creation was opened, opportunity was submitted, opportunity dialog was closed
   */
  private onCloseReferredOpportunityFormDialogHandler(
    activityType: DialogOption,
    notSubmittedActivity: Activity,
    dialogCloseData: any
  ): void {
    // Opportunity -> forward to Contact
    if (dialogCloseData.contact && dialogCloseData.notSubmittedOpportunity) {
      this.openReferredContactFormDialogFromOpportunityFormDialog(activityType, notSubmittedActivity, dialogCloseData);

    // Opportunity -> back to Activity
    } else if (dialogCloseData.opportunity) {
      // returns created Contact
      notSubmittedActivity = JSON.parse(JSON.stringify(notSubmittedActivity));
      notSubmittedActivity.opportunity = dialogCloseData.opportunity.slug;
      notSubmittedActivity.contact = dialogCloseData.opportunity.contact;
      this.openActivityFormDialog(activityType, notSubmittedActivity);

    } else {
      // returns nothing
      this.openActivityFormDialog(activityType, notSubmittedActivity);
    }
  }

  private openActivityFormDialog(activityType: DialogOption, activity: Activity): void {
    let dialogRef: MatDialogRef<ActivityFormDialogComponent>;
    dialogRef = this.dialog.open(ActivityFormDialogComponent);

    if (!activityType) {
      activityType = ActivityTypes.find(type => activity.activity_type === type.name);
    }

    // init dialog component properties
    dialogRef.componentInstance.activity = JSON.parse(JSON.stringify(activity));
    dialogRef.componentInstance.activityType = activityType;

    this.subs = dialogRef.afterClosed()
      .pipe(filter(result => result))
      .subscribe(dialogCloseData => {
        this.onCloseActivityFormDialogHandler(activityType, dialogCloseData);
      });
  }

  private openActivityTypeDialog(data?: any): void {
    let dialogRef: MatDialogRef<ActivityTypeDialogComponent>;
    dialogRef = this.dialog.open(ActivityTypeDialogComponent);

    const activity = data && data.activity ? data.activity : new Activity();

    this.subs = dialogRef.afterClosed()
      .subscribe(activityType => {
      if (activityType) {
        this.openActivityFormDialog(activityType, activity);
      }
    });
  }

  private openActivityViewDialog(activity: Activity) {
    let dialogRef: MatDialogRef<ActivityViewDialogComponent>;
    dialogRef = this.dialog.open(ActivityViewDialogComponent);

    const activityType = ActivityTypes.find(type => activity.activity_type === type.name);

    // init dialog component properties
    dialogRef.componentInstance.activity = JSON.parse(JSON.stringify(activity));
    dialogRef.componentInstance.activityType = activityType;

    this.subs = dialogRef.afterClosed()
      .subscribe((action: string | boolean) => {
      if (action === 'edit') {
        this.openActivityFormDialog(null, activity);
      }
      if (action === 'delete') {
        this.deleteActivity(activity);
      }
    });
  }

  /**
   * Used for creating contact from activity dialog
   */
  private openReferredContactFormDialog(activityType: DialogOption, notSubmittedActivity: Activity, contact: Contact): void {
    this.subs = this.contactFormDialogService.openContactFormDialog(contact)
      .subscribe(submittedContact => {

        // Contact -> back to Activity
        if (submittedContact) {
          // returns created Contact
          notSubmittedActivity = JSON.parse(JSON.stringify(notSubmittedActivity));
          (<Contact>notSubmittedActivity.contact) = submittedContact;
        }
        this.openActivityFormDialog(activityType, notSubmittedActivity);
      });
  }

  /**
   * Open new contact dialog form from Opportunity dialog form
   */
  private openReferredContactFormDialogFromOpportunityFormDialog(
    activityType: DialogOption,
    notSubmittedActivity: Activity,
    dialogCloseData: any
  ): void {
    this.subs = this.contactFormDialogService.openContactFormDialog(dialogCloseData.contact)
      .subscribe(submittedContact => {
        // Contact -> back to Opportunity
        if (submittedContact) {
          // returns created Contact
          dialogCloseData.notSubmittedOpportunity = JSON.parse(JSON.stringify(dialogCloseData.notSubmittedOpportunity));
          dialogCloseData.notSubmittedOpportunity.contact = submittedContact;
        }
        this.openReferredOpportunityFormDialog(activityType, notSubmittedActivity, dialogCloseData.notSubmittedOpportunity);
      });
  }

  /**
   * Used for creating opportunity from activity dialog
   */
  private openReferredOpportunityFormDialog(
    activityType: DialogOption,
    notSubmittedActivity: Activity,
    opportunity: Opportunity
  ): void {
    this.subs = this.opportunityDialogService.openOpportunityFormDialog(opportunity)
      .subscribe(dialogCloseData => {
        this.onCloseReferredOpportunityFormDialogHandler(activityType, notSubmittedActivity, dialogCloseData);
      });
  }

  private deleteActivity(activity): void {
    this.dialogService.confirm(
      'Delete Activity',
      'Are you sure that you want to delete activity?',
      _t('common.confirm')
    )
    .pipe(
      filter(Boolean),
      switchMap(() => this.activityService.deleteActivity(activity.slug))
    )
    .subscribe(() => {
      this.translateService.get('common.activities.headerTitles.activitySuccessfullyDeleted').subscribe((res: string) => {
        this.toastr.success(res, '', {
          timeOut: 7000,
          closeButton: true
        });
      });
      this.dialogObserver.emit('refreshActivityList');
    }, () => {
      this.translateService.get('common.someErrorsOccur').subscribe((res: string) => {
        this.toastr.error(res, '', {
          timeOut: 7000,
          closeButton: true
        });
      });
    });
  }
}

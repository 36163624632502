import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../core/base/base-component';
import { AuthService } from '../../auth/services';
import {IntercomService} from '../intercom/intercom.service';
import { UserTokenService } from '../../auth/services/user.token.service';

@Component({
  selector: 'app-security-layout',
  templateUrl: './security-layout.component.html',
  styleUrls: ['./security-layout.component.scss'],
})
export class SecurityLayoutComponent extends BaseComponent {

  public pageTitle: string;
  public stepNumber: string | number;
  public pageSubtitle: string;
  public socialLinks = environment.socialLinks;
  public supportEmail = environment.supportEmail;
  public currentYear = new Date().getFullYear();
  public baseUrl = environment.baseHost;
  public isLoggedIn: boolean;
  public isLoginPage: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private authService: AuthService,
    private _intercom: IntercomService,
    private userTokenService: UserTokenService
  ) {
    super();

    this.subs = this._router.events.subscribe((evn) => {
      if (evn instanceof NavigationEnd) {
        const currentUser = this.userTokenService.get();
        this.isLoggedIn = !!currentUser;
        this.isLoginPage = evn.url.indexOf('/login') > -1;

        let data: any;
        if (currentUser && currentUser.has_active_subscription === false) {
          // TODO: old routed removed, add smth if needed or remove the first if condition
        } else if (this._route.snapshot.data['isParent']) {
          data = this._route.snapshot.firstChild.data;
        } else {
          data = this._route.snapshot.data;
        }

        this.pageTitle = data.title;
        this.stepNumber = data.stepNumber || '';
        this.pageSubtitle = data.subtitle;
      }
    });

  }

  logout() {
    this.authService.logout();
    this._intercom.shutDown();
  }

  public goTo(key) {
    let route = '/auth/login';
    if ('register' === key) {
      route = '/register';
    }

    this._router.navigate([route]);
  }

}

import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[autosize]'
})
export class ElasticDirective implements AfterViewInit {
  el: HTMLElement;

  constructor(private element: ElementRef) {
    this.el = element.nativeElement;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.adjust();
    });
  }

  @HostListener('input') onInput() {
    this.adjust();
  }

  adjust(): void {
    this.el.style.height = 'auto';
    this.el.style.height = `${this.el.scrollHeight}px`;
  }
}

import { filter } from 'rxjs/operators';
import { BaseComponent } from '../base/base-component';
import { MatDialogRef } from '@angular/material';
import { interval } from 'rxjs';
import { RouteBoundDialogService } from './route-bound-dialog.service';

export class RouteBoundDialog extends BaseComponent {

  public dialogRef: MatDialogRef<any>;
  public routeBoundDialogService: RouteBoundDialogService;

  constructor() {
    super();

    /**
     * Save to service dialog ref when child class got dialogRef and routeBoundDialogService instances
     */
    const subscription = interval(100).pipe(
      filter(() => !!(this.dialogRef && this.routeBoundDialogService))
    )
    .subscribe(() => {
      this.routeBoundDialogService.dialogInstanceRef = this.dialogRef;
      subscription.unsubscribe();
    });
  }
}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class FormDatesService {

  constructor(private translateService: TranslateService) {}

  /**
   * Parse any date string to the following format: 'YYYY-MM-DD'
   * @param date - any string reperesentation of date
   */
  formatDateToInternational(date: string | Date, withTime: boolean = false): string {
    if (date) {
      let result: string;

      // Add zero to value less then 10, e.g. 08, 02, 00 etc.
      const addZero = (i) => i.toString().length < 2 ? '0' + i : i;

      const d = new Date(date as string),
      year = d.getFullYear(),
      month = addZero(d.getMonth() + 1),
      day = addZero(d.getDate());

      result = [year, month, day].join('-');

      // Add time if it's needed
      if (withTime) {
        const hours = addZero(d.getHours()),
        minutes = addZero(d.getMinutes()),
        seconds = '00';

        result += `T${ hours }:${ minutes }:${ seconds }`;
      }

      return result;
    }
    return null;
  }

  /**
   * Parse duration in minutes to UX view, e.g. '2 hours', '35 minutes', '1 hour 20 minutes'
   * @param minutes - number of minutes
   */
  getFormattedDuration(minutes: number): string {
    if (minutes && minutes > 0) {
      const h = Math.floor(minutes / 60),
      m = minutes % 60,
      hString = h > 0 ? `${h} ` + (h > 1 ? this.translateService.instant('contacts.hours') : this.translateService.instant('contacts.hour')) : '',
      mString = m > 0 ? ` ${m} ` + (m > 1 ? this.translateService.instant('contacts.minutes') : this.translateService.instant('contacts.minute')) : '';

      return hString + mString;
    }
    return '';
  }

  getMondayOfCurrentWeek(d: Date): Date {
    const day = d.getDay();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? -6 : 1) - day);
  }
  getSundayOfCurrentWeek(d: Date): Date {
    const day = d.getDay();
    return new Date(d.getFullYear(), d.getMonth(), d.getDate() + (day === 0 ? 0 : 7) - day);
  }

  getFirstDayOfCurrentMonth(d: Date): Date {
    return new Date(d.getFullYear(), d.getMonth(), 1);
  }

  getLastDayOfCurrentMonth(d: Date): Date {
    return new Date(d.getFullYear(), d.getMonth() + 1, 0);
  }

  getFirstDayOfCurrentQuarter(d: Date): Date {
    return new Date(d.getFullYear(), Math.floor((d.getMonth() / 3)) * 3, 1);
  }

  getLastDayOfCurrentQuarter(d: Date): Date {
    const firstDate = new Date(d.getFullYear(), Math.floor((d.getMonth() / 3)) * 3, 1);
    return new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
  }

  getFirstDayOfCurrentYear(d: Date): Date {
    return new Date(d.getFullYear(), 0, 1);
  }

  getLastDayOfCurrentYear(d: Date): Date {
    return new Date(d.getFullYear() + 1, 0, 0);
  }
}

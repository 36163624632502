import { Injectable } from '@angular/core';
declare const require;

export interface ICountry {
  code: string;
  name: string;
}

@Injectable()
export class CountryListService {

  private countryList = require('country-list')();

  constructor() {}

  getCountryList(): ICountry[] {
    return this.countryList.getData();
  }

  getCountryName(code: string): string {
    return this.countryList.getName(code);
  }
}

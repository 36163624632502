import { Routes } from '@angular/router';


export const MarketplaceRoutes: Routes = [
  {
    path: 'app',
    children: [
      {
        path: 'testroute',
        loadChildren: 'app/modules/marketplace/routed/routed-component/routed-component.module#RoutedComponentModule'
      },
      {
        path: '**',
        redirectTo: '404'
      },
      {
        path: '404',
        loadChildren: './modules/404/404.module#Page404Module'
      }
    ]
  },
  {
    path: 'dynamic-loading-demo',
    loadChildren: 'app/modules/marketplace/routed/demo/dynamic-demo.module#DynamicDemoModule'
  }
];

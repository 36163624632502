import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material';


@Injectable()
export class RouteBoundDialogService  {
  private _dialogInstanceRef: MatDialogRef<any>;

  get dialogInstanceRef(): MatDialogRef<any> {
    return this._dialogInstanceRef;
  }
  set dialogInstanceRef(dialogRef: MatDialogRef<any>) {
    if (dialogRef) {
      dialogRef.afterClosed().subscribe(() => this.clearOpenedDialog());
      this._dialogInstanceRef = dialogRef;
    }
  }

  clearOpenedDialog(): void {
    this._dialogInstanceRef = null;
  }
}

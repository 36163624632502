import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { RouteBoundDialog } from '../../../../core/route-bound-dialog/route-bound-dialog.class';
import { of } from 'rxjs';
import { switchMap, map, first } from 'rxjs/operators';
import { ValidationService } from '../../../../shared/validation/validation.service';
import { Router } from "@angular/router";
import { DialogService } from '../../../../shared/dialog/dialog.service';
import { _t } from 'app/modules/core/other/translate-marker';
import { FilterGroupService } from './filter-group.service';
import { FilterTag } from '../../filters.model';

@Component({
  selector: 'app-form-dialog-filter-group',
  templateUrl: './form-dialog-filter-group.component.html',
  styleUrls: ['./form-dialog-filter-group.component.scss'],
})
export class FormDialogFilterGroupComponent extends RouteBoundDialog implements OnInit {

  form: FormGroup;
  filterTags: FilterTag[] = [];
  isLoading: boolean = false;
  contactId: string;
  isGroupsLoading: boolean = false;
  filterGroups: any;

  constructor(
    public dialogRef: MatDialogRef<FormDialogFilterGroupComponent>,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialogService: DialogService,
    private filterGroupService: FilterGroupService
  ) {
    super();
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.getSegmentFilters();
    this.buildFilterGroupForm();
  }

  getSegmentFilters() {
    this.isGroupsLoading = true;
    this.subs = this.filterGroupService.getFilterGroups()
      .subscribe((response) => {
        this.filterGroups = response.results;
      },
        (error) => { },
        () => this.isGroupsLoading = false
      );
  }

  toggleOption(e: Event, source: string) {
    if (source === 'edit') {
      // enable edit section
      this.form.controls['isEditGroup'].setValue(true);
      this.form.controls['selectedGroup'].enable();
      // disable new section
      this.form.controls['isNewGroup'].setValue(false);
      this.form.controls['groupName'].disable();
    }
    else if (source === 'new') {
      // enable new section
      this.form.controls['isNewGroup'].setValue(true);
      this.form.controls['groupName'].enable();
      // disable edit section
      this.form.controls['isEditGroup'].setValue(false);
      this.form.controls['selectedGroup'].disable();
    }
  }

  buildFilterGroupForm(): void {
    this.form = this.formBuilder.group({
      isEditGroup: false,
      selectedGroup: [{value: '', disabled: true}, [Validators.required]],
      isNewGroup: true,
      groupName: ['', [Validators.required]],
    });
    this.form['submitted'] = false;
  }

  prepareFilterGroupBody(formValue: any) {
    const body = {
      name: (this.form.controls.isNewGroup.value) ? formValue.groupName : this.form.controls.selectedGroup.value.name,
      predicate: []
    };

    for (let i = 0; i < this.filterTags.length; i++) {
      let filterTag = this.filterTags[i];

      switch (filterTag.filterCode) {
        case "contact_type":
        case "gender":
        case "marital_status":
        case "rating":
        case "geography":
        case "search":
          filterTag.valueCodes.map((code) => {
            body.predicate.push({
              op: "eq",
              val: code.toString(),
              attr: filterTag.filterCode,
              data_type: 'string'
            });
          })

          break;

        case "age":
        case "income":
          for (let j = 0; j < filterTag.valueCodes.length; j++) {
            body.predicate.push({
              op: (j===0) ? "gte" : "lte",
              val: filterTag.valueCodes[j].toString(),
              attr: filterTag.filterCode,
              data_type: 'string'
            });
          }
          break;

        default:
          break;
      }
    }

    return body;
  }

  onSubmit(): void {
    this.form['submitted'] = true;

    if (this.form.valid) {
      this.isLoading = true;
      const body = this.prepareFilterGroupBody(this.form.value);
      
      this.subs = of(this.form.controls.isNewGroup.value)
        .pipe(switchMap(result => {
          if (result) {
            return this.filterGroupService.createFilterGroup(body)
          } else {
            let slug = this.form.controls.selectedGroup.value.slug;
            return this.filterGroupService.updateFilterGroup(slug, body)
          }
        }))
        .subscribe((filterGroup) => {
          this.dialogRef.close(filterGroup);
          this.filterGroupService.notifyFilterGroupChange();
        }, () => { },
          () => this.isLoading = false
        );
    }
  }

  onClose(event: Event) {
    if (this.form.dirty) {
      this.openCancelDialog().pipe(first()).subscribe();
    } else {
      this.dialogRef.close();
    }
  }

  openCancelDialog() {
    return this.dialogService
      .confirm(
        _t('contacts.contactForm.youHaveUnsavedChanges'),
        _t('contacts.contactForm.closeForm'),
        _t('common.confirm')
      )
      .pipe(map(res => {
        if (res === true) {
          this.dialogRef.close();
        }
        return res;
      }));
  }

}

import { _t } from 'app/modules/core/other/translate-marker';

export const GuideTourId = 'contacts-introjs';

export const CONTACTS_STEPS = [
  {
    element: `#toolbar-new-button`,
    intro: _t('contacts.introjs.addNewContactMessage'),
    position: 'right',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${GuideTourId}-step-0-tooltip`
  },
  {
    element: `#${GuideTourId}-step-1`,
    intro: _t('contacts.introjs.fillSimpleForm'),
    position: 'top',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${GuideTourId}-step-1-tooltip`
  },
  {
    element: `#${GuideTourId}-step-2`,
    intro: _t('contacts.introjs.nowYouCan'),
    position: 'bottom',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${GuideTourId}-step-2-tooltip`
  },
  {
    element: `#${GuideTourId}-step-3`,
    intro: _t('contacts.introjs.useImportForQuick'),
    position: 'bottom',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${GuideTourId}-step-3-tooltip`
  }
]

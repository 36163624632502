const PARAM = '%param%';
const API_CONFIG = {
  prefixVersion: `/api/v1`,
  prefixAuth: `/auth`,

  consultant: {
    consultantTasks: `rewards/consultant_tasks/`,
    claimReward: `rewards/consultant_tasks/${PARAM}/claim_reward/`,
    markComplete: `rewards/consultant_tasks/${PARAM}/mark_complete/`
  },

  activity: {
    list: `activities/?status_group=${PARAM}&limit=${PARAM}&page=${PARAM}&search=${PARAM}&ordering=${PARAM}${PARAM}&status=${PARAM}`,
    momentum: `activity/momentum/?from_date=${PARAM}&to_date=${PARAM}&step=${PARAM}`,
    upcoming: `activities/upcoming/?utc_time=${PARAM}`,
    relatedToContact: `activities/${PARAM}/`,
    relatedToOpportunity: `activities/${PARAM}/?opportunity__slug=${PARAM}`,
    create: `activities/`,
    update: `activity/${PARAM}/`,
    delete: `activity/${PARAM}/`
  },

  contact: {
    autocompleteContacts: `contact/autocomplete/?q=${PARAM}`,
    clientsRatingsPie: `contact/rating/?from_date=${PARAM}&to_date=${PARAM}`,
    numberOfContacts: `contact/stats/?from_date=${PARAM}&to_date=${PARAM}&step=${PARAM}`,
    get: `contact/${PARAM}/`,
    list: `contacts/?limit=${PARAM}&page=${PARAM}&segment_id=${PARAM}&search=${PARAM}&contact_type=${PARAM}&rating=${PARAM}&age_gte=${PARAM}&age_lte=${PARAM}&income_gte=${PARAM}&income_lte=${PARAM}&gender=${PARAM}&marital_status=${PARAM}&geography=${PARAM}&ordering=${PARAM}`,
    bulkDelete: `contacts/bulk_delete/`,
    delete: `contact/${PARAM}/`,
    create: `contacts/`,
    update: `contact/${PARAM}/`,
    import: `contacts/import/`,
    createDependent: `contact/${PARAM}/dependents/`,
    createFamilyMember: `contact/${PARAM}/family_histories/`,
    createPriority: `contact/${PARAM}/priorities/`,
    getAssets: `contact/${PARAM}/assets/`,
    createAsset: `contact/${PARAM}/assets/`,
    getLiabilities: `contact/${PARAM}/liabilities/`,
    createLiability: `contact/${PARAM}/liabilities/`,
    getIncomes: `contact/${PARAM}/incomes/`,
    createIncome: `contact/${PARAM}/incomes/`,
    getExpenses: `contact/${PARAM}/expenses/`,
    createExpense: `contact/${PARAM}/expenses/`,
    getSegments: `contact/segments/`,
    createSegment: `contact/segments/`,
    updateSegment: `contact/segment/${PARAM}/`,
    contactPie: `contact_consumer/count/`,
  },

  referralWidgets : {
    getSWMilstone: `rewards/sw_referral_milestones/`,
    claimSWReward :`rewards/sw_referral_milestones/${PARAM}/claim_reward/`,
    getSyncbizMilstone:`rewards/syncbiz_referral_milestones/`,
    claimSBReward:`rewards/syncbiz_referral_milestones/${PARAM}/claim_reward/`
  },

  priority: {
    delete: `priority/${PARAM}/`,
    update: `priority/${PARAM}/`
  },

  document: {
    create: `document/create/`,
    delete: `document/${PARAM}/`,
    update: `document/${PARAM}/`
  },

  financialGoals: {
    get: `contact/${PARAM}/financial_goals/`,
    getBySlug: `contact/${PARAM}/financial_goals/${PARAM}/`,
  },

  rediscoverSection: {
    get : `contact/${PARAM}/quiz_responses/`,
    getBySlug : `contact/${PARAM}/quiz_responses/${PARAM}/`
  },

  goals: {
    list: `goals/`,
    create: `goals/`,
    update: `goal/${PARAM}/`,
    delete: `goal/${PARAM}/`
  },

  opportunity: {
    list: `opportunities/?status_group=${PARAM}&limit=${PARAM}&page=${PARAM}&search=${PARAM}&ordering=${PARAM}${PARAM}&status=${PARAM}&min_amount=${PARAM}&max_amount=${PARAM}`,
    create: `opportunities/`,
    update: `opportunity/${PARAM}/`,
    get: `opportunity/${PARAM}/`,
    delete: `opportunity/${PARAM}/`,
    byContact: `opportunities/${PARAM}/?status_group=${PARAM}`,
    pie: `opportunities/funnel/?from_date=${PARAM}&to_date=${PARAM}`,
    closingAndCommissions: `opportunity/performance/?from_date=${PARAM}&to_date=${PARAM}&step=${PARAM}`
  },

  profile: {
    get: `profile/`
  },

  profileBuilder: {
    get: `profile_builder/profile/${PARAM}/`,
    save: `profile_builder/profile/${PARAM}/`,
    update: `profile_builder/profile/${PARAM}/`,
    getUserPhoto: `profile_builder/profile/${PARAM}/base64_photo/`,
    uploadProfileImage: `profile_builder/images/`,
    createContact: `profile_builder/contact/${PARAM}/`,
    createTestimonial: `profile_builder/testimonials/${PARAM}/`,
    checkAvailability: `profile_builder/personalized_link_check/`,
    publicProfile: `profile_builder/public_profile/${PARAM}/`,
    addTestimonial: `profile_builder/testimonial/add_to_profile/${PARAM}/`,
  },

  payment: {
    cancelSubsciption: `payment/subscription/cancel/`,
    getSubscription: `payment/subscription/`,
    createSubsciption: `payment/subscription/`,
    saveCard: `payment/default_card/`,
    invoices: `payment/invoices/`,
    defaultCard: `payment/default_card/`,
    checkPromocode: `payment/coupon/${PARAM}/`,
    checkPromocodeForPlan: `payment/coupon/${PARAM}/${PARAM}/`,
    plansList: `payment/subscription/plans/`,
    updateSubscription: `payment/subscription/change/`,
    subscriptionPlansDetails: `payment/subscription/plan_details/`,
    discount: `payment/discount/${PARAM}/`,
    wallet: `payment/wallet/`,
    topupWallet: `payment/wallet/topup/`,
    walletTopupPlans: `payment/wallet/topup_plans/`,
    getWalletTransactions: `payment/wallet/transactions/?limit=${PARAM}`
  },

  planGroup: {
    get: `plan_group/`
  },

  analytics: {
    dashboardAvailabilityCheck: `analytics/dashboard/availability_check/`,
    profileVisitsLast2Weeks: `analytics/profile_visits/?start_date=${PARAM}&end_date=${PARAM}`,
    profileVisitDetailsLast3Months: `analytics/profile_visit_details/`,
    profileVisitDetails: `analytics/profile_visit_details/?start_date=${PARAM}&end_date=${PARAM}`,
    profileVisitCity: `analytics/profile_visit_city/?start_date=${PARAM}&end_date=${PARAM}`,
    cashflow: `analytics/contact/cashflow/${PARAM}/`,
    networth: `analytics/contact/networth/${PARAM}/`,
    premiumPlanQuery : `analytics/premium_plan_query/`
  },

  account: {
    get: `account/`,
    financialConsultant: `financial_consultant/${PARAM}/`,
    getConsultant: `user_details/`
  },

  product: {
    insurancePolicies: `product/insurance_policies/${PARAM}/`,
    create: `product/${PARAM}/${PARAM}/`,
    update: `product/${PARAM}/${PARAM}/${PARAM}/`,
    delete: `product/${PARAM}/${PARAM}/${PARAM}/`,
    getAvailable: `products/${PARAM}/?category=${PARAM}`
  },

  financialInstitution: {
    list: `financial_institutions/`,
    search: `financial_institutions/autocomplete/?q=${PARAM}`
  },

  financialInformation: {
    get: `financial_information/${PARAM}/`,
    sendPdpa: `financial_information/${PARAM}/send_pdpa/`
  },

  dependent: {
    delete: `dependent/${PARAM}/`,
    update: `dependent/${PARAM}/`,
    setPicture: `dependent/${PARAM}/`,
  },

  familyHistory: {
    delete: `family_history/${PARAM}/`,
    update: `family_history/${PARAM}/`
  },

  marketplace: {
    listApps: `marketplace/apps/`,
    listInstaledApps: `marketplace/apps/installed/?component=${PARAM}&is_uninstalled=${PARAM}`,
    appDetails: `marketplace/app/${PARAM}/`,
    installedAppByAppId: `marketplace/apps/installed/${PARAM}/`,
    installApp: `marketplace/apps/installed/`,
    getAppGallery: `marketplace/app/${PARAM}/gallery/`,
    getServiceGallery: `marketplace/app/${PARAM}/gallery/`,
    appRating: `marketplace/app/${PARAM}/rating/`,
    rateApp: `marketplace/app/${PARAM}/rating/`,
    uninstallInstalledUserApp: `marketplace/app/${PARAM}/uninstall/`,
    listServices: `marketplace/services/`,
    serviceDetails: `marketplace/service/${PARAM}/`,
    listServiceRequests: `marketplace/services/request/`,
    sendServiceRequest: `marketplace/services/request/`
  },

  insuranceSummary: {
    base64Report: `marketplace/insurance_summary/base64_report/${PARAM}/`,
    addToContactBase64Doc: `contact/base64_doc/${PARAM}/`
  },

  snapAndSync: {
    createSasDocument: `marketplace/snap_sync/document/`,
    deleteSasDocument: `marketplace/snap_sync/document/${PARAM}/`,
    getSasRequestsList: `marketplace/snap_sync/request/${PARAM}/`,
    createSasRequest: `marketplace/snap_sync/request/${PARAM}/`
  },

  auth: {
    login: `/auth/login/`,
    resetPassword: `/auth/password/reset/`,
    confirmPasswordReset: `/auth/password/reset/confirm/`,
    verifyResetPassword: `/auth/password/reset/verify/${PARAM}/${PARAM}/`,
    register: `/auth/register/`,
    activate: `/auth/activate/`,
    socialAuth: `/socialauth/signin/`
  },

  authWithToken: {
    password: `auth/password/`,
    logout: `auth/logout/`
  },

  financialFreedom: {
    lead: `financial_freedom/lead/`,
    referralCode: `referral/code/`,
    specificLead: `financial_freedom/lead/${PARAM}/`,
  },

  asset: {
    update: `asset/${PARAM}/`,
    delete: `asset/${PARAM}/`
  },

  liability: {
    update: `liability/${PARAM}/`,
    delete: `liability/${PARAM}/`
  },

  income: {
    update: `income/${PARAM}/`,
    delete: `income/${PARAM}/`
  },

  expense: {
    update: `expense/${PARAM}/`,
    delete: `expense/${PARAM}/`
  },

  referral: {
    inviteLink: `referral/invite_link/`,
  }
};


Object.keys(API_CONFIG)
  .filter((blockKey: string) => typeof API_CONFIG[blockKey] === 'object')
  .forEach((blockKey: string) => {
    const block = API_CONFIG[blockKey];
    Object.keys(block).forEach((key: string) => {
      block[key] = `${blockKey !== 'auth' ? API_CONFIG.prefixVersion + '/' : ''}${block[key]}`;
    });
  });

export { API_CONFIG, PARAM };

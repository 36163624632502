import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnChanges {

  @Input() size = 40;
  @Input() tickness = 4;
  @Input() color = '#F58320';
  @Input() opacity = '.1';
  borderColor = '';

  ngOnInit(): void {
    this.initLoader();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.color && changes.color.currentValue) {
      this.initLoader();
    }
  }

  private hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
  }

  private initLoader(): void {
    const c = this.hexToRgb(this.color);
    this.borderColor = `rgba(${ c.r },${ c.g },${ c.b },${ 1
      }) rgba(${ c.r },${ c.g },${ c.b },${ this.opacity
      }) rgba(${ c.r },${ c.g },${ c.b },${ this.opacity })`;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { FormChoicesService } from '../../../core/services/form-helpers/form-choises.service';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  constructor(
    private formChoicesService: FormChoicesService
  ) {}

  transform(id: string, list: string): string {
    return this.formChoicesService.getFormChoiceValueFromList(id, list);
  }
}

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { BaseComponent } from '../core/base/base-component';
import { Contact } from '../contacts/models/contact.model';
import { ContactFormDialogService } from '../shared/contact-form-dialog/contact-form-dialog.service';
import { DialogObserverService } from '../core/services/dialog-observer/dialog-observer.service';
import { DialogOpportunityComponent } from './dialog-opportunity/dialog-opportunity.component';
import { DialogService } from '../shared/dialog/dialog.service';
import { Opportunity } from './opportunity-list/opportunity';
import { OpportunityService } from '../api/opportunity.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { _t } from '../core/other/translate-marker';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OpportunityDialogService extends BaseComponent {

  dialogObserver: DialogObserverService;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private config: MatDialogConfig,
    private contactFormDialogService: ContactFormDialogService,
    private dialogService: DialogService,
    private opportunityService: OpportunityService,
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {
    super();
  }

  initSubscription(dialogObserver: DialogObserverService)  {
    if (!this.dialogObserver) {
      this.dialogObserver = dialogObserver;
      this.dialogRequestSubscriber();
    }
  }

  /**
   * Opportunity form dialog Observable
   * @param opportunity - opportunity object
   */
  openOpportunityFormDialog(opportunity: Opportunity): Observable<any> {
    this.config.data = opportunity;
    return this.dialog.open(DialogOpportunityComponent, this.config).afterClosed();
  }

  private dialogRequestSubscriber(): void {
    this.subs = this.dialogObserver.dialogRequest$
      .subscribe(data => {
        switch (true) {
          case data.type === 'add_opportunity' : this.addHandler(data.item); break;
          case data.type === 'view_opportunity'  : this.router.navigate([`/opportunities/details/${ data.item.slug }`]); break;
          case data.type === 'edit_opportunity'  : this.open(DialogOpportunityComponent, data.item); break;
          case data.type === 'delete_opportunity': this.deleteHandler(data.item); break;
        }
      });
  }

  private open(component, data?, config?): void {

    if (data) {
      this.config.data = data;
    }

    this.config = Object.assign({}, this.config, config);

    this.subs = this.dialog.open(component, this.config)
      .afterClosed()
      .pipe(filter(result => result))
      .subscribe(dialogCloseData => {
        if (dialogCloseData.contact && dialogCloseData.notSubmittedOpportunity) {
          this.openReferredContactFormDialog(dialogCloseData.notSubmittedOpportunity, dialogCloseData.contact);
        } else if (dialogCloseData.opportunity) {
          this.dialogObserver.emit('refreshOpportunityList', dialogCloseData.opportunity);
        }
      })
  }

  /**
   * Used for creating contact from opportunity dialog
   */
  private openReferredContactFormDialog(notSubmittedOpportunity: Opportunity, contact: Contact): void {
    this.subs = this.contactFormDialogService.openContactFormDialog(contact)
      .subscribe(updatedContact => {
        if (updatedContact) {
          notSubmittedOpportunity = JSON.parse(JSON.stringify(notSubmittedOpportunity));
          notSubmittedOpportunity.contact = updatedContact;
        }
        this.open(DialogOpportunityComponent, notSubmittedOpportunity);
      });
  }

  private addHandler(data?: any): void {
    const opportunity = data && data.opportunity ? data.opportunity : new Opportunity();
    this.open(DialogOpportunityComponent, opportunity);
  }

  private deleteHandler(opportunity): void {
    this.subs = this.dialogService.confirm(
      _t('contacts.opportunities.deleteOpportunity'),
      _t('contacts.opportunities.removeRelatedActivities'),
      _t('common.confirm')
    )
      .pipe(
        filter(Boolean),
        switchMap(() => this.opportunityService.deleteOpportunity(opportunity.slug))
      )
      .subscribe(() => {
        this.toastr.success(this.translateService.instant('contacts.opportunities.opportunitySuccessDeleted'), '', {
          timeOut: 7000,
          closeButton: true
        });
        this.dialogObserver.emit('OpportunityDeleted');
      }, () => {
        this.toastr.error(this.translateService.instant('common.someErrorsOccur'), '', {
          timeOut: 7000,
          closeButton: true
        });
      },
    );
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';

import { API_CONFIG, PARAM } from '../../configs';

@Injectable()
export class ProfileService {

  public get slug() {
    return this._storage.retrieve('userForm')['slug'];
  }

  constructor(
    private _http: HttpClient,
    private _storage: LocalStorageService
  ) { }

  public save(data) {
    return this._http.patch(API_CONFIG.profileBuilder.save.replace(PARAM, this.slug), data);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BaseComponent } from '../../modules/core/base/base-component';
import { PlanUpgradeService } from '../../modules/shared/services/plan-upgrade.service';

@Component({
	selector: 'app-upgrade-page',
	templateUrl: './upgrade-page.component.html',
	styleUrls: ['./upgrade-page.component.scss']
})
export class UpgradePageComponent extends BaseComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private planUpgradeService: PlanUpgradeService
	) {
		super();
	}

	ngOnInit() {
		// subscribe to query params and store in PlanUpgradeService after processing
		this.subs = this.route.queryParams.subscribe(
			(params: Params) => {
				switch (params.plan) {
					case "fc_basic_monthly":
					case "fc_basic_yearly":
					case "fc_consultant_monthly":
					case "fc_consultant_yearly":
						let planType = params.plan.split('_')[1];
						let planDuration = params.plan.split('_')[2];
						let coupon = params.coupon;

						this.planUpgradeService.planUpgradeParams = params;
						this.planUpgradeService.coupon = coupon;
						this.planUpgradeService.planType = planType;
						this.planUpgradeService.planDuration = planDuration;

					default:
						break;
				}

			});
	}
}

import { Contact } from '../../contacts/models/contact.model';
import { Opportunity } from '../../opportunities/opportunity-list/opportunity';

export class Activity {
  slug?: string = null;
  activity_type: string = null;
  contact: string & Contact = null;
  location?: string = null;
  notes?: string = null;
  to_datetime?: string;
  opportunity?: any;
  from_datetime?: string;
  priority?: string = null;
  subject?: string = null;
  status?: string ;

  constructor() {};
}

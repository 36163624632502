import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { finalize, mergeMap, map } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import { StripeService } from '../stripe/stripe.service';
import { Router } from '@angular/router';
import { UserTokenService } from '../../auth/services/user.token.service';
import { BaseComponent } from '../../core/base/base-component';
import { CardData } from '../subscription-plans/card-details-form/card-details-form.component';
import { MatRadioChange } from '@angular/material';
import { PaymentCouponComponent, PromoCodeConfig } from '../payment-coupon/payment-coupon.component';
import { SubscriptionService } from '../../api/subscription.service';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../api/payment.service';
import { PaymentFormConfig } from './payment-form.config';
import { TranslateService } from '@ngx-translate/core';
import { ViralLoopsService } from '../viral-loops/services/viral-loops.service';
import { PlanUpgradeService } from '../services/plan-upgrade.service';

export interface ICurrentPlan {
  monthly_plan?: any;
  yearly_plan?: any;
  name?: string;
}

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent extends BaseComponent implements OnInit {

  @Input() plan: ICurrentPlan;
  @Input() card: CardData;
  @Input() set formMode(mode: 'SIGN_UP_MODE' | 'UPGRADE_MODE') {
    this.paymentFormConfig = PaymentFormConfig[mode];
  };
  @Output() resultEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild(PaymentCouponComponent) promoCodeComponent: PaymentCouponComponent;

  CARD_ADD_SUCCESSES = PaymentFormConfig.CARD_ADD_SUCCESSES;
  UPGRADE_SUCCESSES = PaymentFormConfig.UPGRADE_SUCCESSES;
  UPGRADE_ERROR = PaymentFormConfig.UPGRADE_ERROR;

  cardForm: FormGroup;
  currentPlan: ICurrentPlan;
  currentPlanId: string;
  cardFormVisible = true;

  price: any;
  promoCodeConfig = new PromoCodeConfig();
  periodTogglerVisible = true;
  paymentFormConfig: any;

  isAnnualy = true;
  isCompleting = false;
  planIsChanging = false;
  showFormErrors = false;
  totalPrice: number;

  private toastrConfig = {
    timeOut: 7000,
    closeButton: true
  }

  constructor(
    private router: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private translateService: TranslateService,
    private stripe: StripeService,
    private paymentService: PaymentService,
    private userTokenService: UserTokenService,
    private subscriptionService: SubscriptionService,
    private viralLoopsService: ViralLoopsService,
    private planUpgradeService: PlanUpgradeService
  ) {
    super();
  }

  ngOnInit() {
    this.retrieveCurrentPlan();
    this.cardFormVisiblityHandler();
    this.periodTogglerVisibilityHandler();
    this.setYearlyPlan();
  }

  changePlanHandler(event: MatRadioChange): void {
    this.currentPlanId = event.value;
    this.promoCodeComponent.removePromocode();

    if (!this.promoCodeConfig.discount) {
      return this.switchPlanHandler(this.currentPlanId, this.currentPlan);
    }
    this.planIsChanging = true;
  }

  promoCodeUpdateHandler(promoCodeConfig: PromoCodeConfig): void {
    this.planIsChanging = false
    this.promoCodeConfig = promoCodeConfig;
    this.switchPlanHandler(this.currentPlanId, this.currentPlan);
  }

  showBasePrice(months) {
    return (this.totalPrice * months + this.promoCodeConfig.discount).toFixed(2);
  }

  onFormChanged(form: FormGroup) {
    this.cardForm = form;
  }


  submit() {
    this.showFormErrors = true;
    // TODO: optimaze functions bellow

    switch (true) {
      case this.paymentFormConfig.mode === 'sign-up-mode':
        this.cardFormVisible ? this.submitSignUpWithCardData() : this.submitSignUpRequest(); break;
      case this.paymentFormConfig.mode === 'upgrade-mode':
        this.cardFormVisible ? this.submitUpdateWithCardData() : this.submitUpdateRequest(); break;
    }
  }

  private stripCardTokenRequest(): Promise<any> {
    const card: CardData = this.cardForm.value;

    return this.stripe
      .createCardToken({
        name: card.cardholder,
        number: card.cardNumber,
        cvc: card.cvc,
        exp_month: card.expMonth,
        exp_year: card.expYear
      });
  }

  private submitSignUpWithCardData(): void {
    if (this.cardForm && this.cardForm.valid) {
      this.stripCardTokenRequest()
        .then((cardToken: string) => {
          this.submitSignUpRequest(cardToken);
        })
        .catch(() => {
          this.router.navigate(['404']);
        });
    }
  }

  private submitSignUpRequest(cardToken?: string): void {
    this.isCompleting = true;

    const payload: any = {
      plan: this.currentPlanId,
      coupon: this.promoCodeConfig.promocode
    };

    if (cardToken) {
      payload.card = { token: cardToken };
    }

    this.subs = this.api.subscription.create(payload)
      .pipe(
        mergeMap(() => this.subscriptionService.getCurrentUsersPlan()),
        finalize(() => this.isCompleting = false)
      )
      .subscribe((planGroup: string) => {
        this.currentUserUpdateHandler(planGroup);
        // TODO: uncomment when need to get Viral Loop back
        // this.viralLoopsService.trackConversion();
        this.toastr.success(this.translateService.instant(this.UPGRADE_SUCCESSES), '', this.toastrConfig);
        this.resultEvent.emit({ planGroup });
      },
        err => {
          this.toastr.error(this.translateService.instant(this.UPGRADE_ERROR), '', this.toastrConfig);
          this.router.navigate(['400']);
        }
      );
  }

  private submitUpdateWithCardData(): void {
    if (this.cardForm && this.cardForm.valid) {
      const payload: any = {
        plan: this.currentPlanId,
        coupon: this.promoCodeConfig.promocode
      };
      this.isCompleting = true;

      this.stripCardTokenRequest()
        .then((cardToken: string) => {
          this.api.payment.saveCardToken({ token: cardToken })
            .pipe(
              mergeMap(() => this.subscriptionService.updateSubscriptionPlan(payload)),
              finalize(() => this.isCompleting = false),
              map((planGroup: string) => {
                this.toastr.success(this.translateService.instant(this.CARD_ADD_SUCCESSES), '', this.toastrConfig);
                this.toastr.success(this.translateService.instant(this.UPGRADE_SUCCESSES), '', this.toastrConfig);
                this.currentUserUpdateHandler(planGroup);
              }),
              mergeMap(() => this.paymentService.getDefaultCard())
            )
            .subscribe(
              card => {
                const planGroup = this.userTokenService.get().plan_group;
                this.resultEvent.emit({ card: card, planGroup: planGroup });
              },
              err => this.toastr.error(this.translateService.instant(this.UPGRADE_ERROR), '', this.toastrConfig)
            )
        })
        .catch((error) => {
          this.toastr.error(error['message'], '', this.toastrConfig);
        });
    }
  }

  private submitUpdateRequest(): void {
    this.isCompleting = true;
    const payload: any = {
      plan: this.currentPlanId,
      coupon: this.promoCodeConfig.promocode
    };

    this.subs = this.subscriptionService.updateSubscriptionPlan(payload)
      .pipe(
        mergeMap(() => this.subscriptionService.getCurrentUsersPlan()),
        finalize(() => this.isCompleting = false)
      )
      .subscribe(
        (planGroup: string) => {
          this.toastr.success(this.translateService.instant(this.UPGRADE_SUCCESSES), '', this.toastrConfig);
          this.currentUserUpdateHandler(planGroup);
          this.resultEvent.emit({ planGroup });
        },
        err => this.toastr.error(this.translateService.instant(this.UPGRADE_ERROR), '', this.toastrConfig)
      );
  }

  private currentUserUpdateHandler(planGroup?: string): void {
    const currentUser = this.userTokenService.get();
    if (planGroup) {
      currentUser.plan_group = planGroup;
    }
    currentUser.has_active_subscription = true;
    this.userTokenService.put(currentUser);
  }

  private retrieveCurrentPlan(): void {
    this.currentPlan = this.prepareInputPlan(this.plan);

    this.currentPlanId = this.currentPlan.yearly_plan.id;
  }

  private prepareInputPlan(plan: any): ICurrentPlan {
    return {
      name: plan.month.name,
      monthly_plan: {
        id: plan.month.stripe_id,
        price: plan.month.amount,
      },
      yearly_plan: {
        id: plan.year.stripe_id,
        price: plan.year.amount / 12
      }
    }
  }

  private isFreePlan(): boolean {
    return this.currentPlan.monthly_plan.price === 0 && this.currentPlan.yearly_plan.price === 0;
  }

  private cardFormVisiblityHandler(): void {
    if (this.isFreePlan() || this.card) {
      this.cardFormVisible = false;
    }
  }

  private periodTogglerVisibilityHandler(): void {
    if (this.currentPlan.monthly_plan.price === 0 && this.currentPlan.yearly_plan.price === 0) {
      this.periodTogglerVisible = false;
    }

    // handle query params
    if (this.planUpgradeService.planDuration) {
      setTimeout(() => {
        this.handlePlanDuration();
      }, 500);
    }
  }

  handlePlanDuration() {
    let planDuration = this.planUpgradeService.planDuration;
    let radioOptions = document.getElementsByClassName('mat-radio-label');
    let yearlyRadioOption = <HTMLElement>radioOptions[0];
    let monthlyRadioOption = <HTMLElement>radioOptions[1];

    switch (planDuration) {
      case "yearly":
        if (yearlyRadioOption) {
          yearlyRadioOption.click();
        }
        break;

      case "monthly":
        if (monthlyRadioOption) {
          monthlyRadioOption.click()
        }
        break;

      default:
        break;
    }
  }

  private switchPlanHandler(planId: string, currentPlan: ICurrentPlan): void {
    planId === currentPlan.monthly_plan.id ? this.setMonthlyPlan() : this.setYearlyPlan();
  }

  private setMonthlyPlan(): void {
    let buf = '';
    this.totalPrice = this.currentPlan.monthly_plan.price;

    if (this.promoCodeConfig.discount) {
      this.totalPrice -= this.promoCodeConfig.discount;
      buf = this.totalPrice.toFixed(2);
    } else {
      buf = this.totalPrice.toString();
    }
    this.price = {
      integer: buf.split('.')[0],
      decimal: buf.split('.')[1] || '00'
    };
    this.isAnnualy = false;
  }

  private setYearlyPlan(): void {
    this.totalPrice = this.currentPlan.yearly_plan.price;

    if (this.promoCodeConfig.discount) {
      this.totalPrice -= this.promoCodeConfig.discount / 12;
    }
    const buf = (12 * this.totalPrice).toFixed(2).toString();

    this.price = {
      integer: buf.split('.')[0],
      decimal: buf.split('.')[1] || '00'
    };
    this.isAnnualy = true;
  }
}

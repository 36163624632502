import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable()
export class UserTokenService {
  key = 'currentUser';

  constructor(private localStorage: LocalStorageService) {
  }

  get(): IUserToken {
    return this.localStorage.retrieve(this.key);
  }

  put(value: IUserToken): void {
    this.localStorage.store(this.key, value);
  }

  remove(): void {
    this.localStorage.clear('currentuser');
    this.localStorage.clear('userform');
    this.localStorage.clear('contactsviewlayout');
    this.localStorage.clear('wallet');
  }

  isValid(): boolean {
    const token: IUserToken = this.get();
    return token && new Date() <= new Date(token['expiration_date']);
  }
}

export interface IUserToken {
  token: string;
  expiration_date: Date;
  has_active_subscription: boolean;
  plan_group: string;
  subscription_interval: string;
  tokenPrefix?: string;
}

export class UserToken implements IUserToken {
  expiration_date: Date;

  constructor(public token: string, public has_active_subscription: boolean, public plan_group: string, public subscription_interval: string,
    public tokenPrefix?: string) {
    this.has_active_subscription = has_active_subscription;
    this.plan_group = plan_group;
    this.subscription_interval = subscription_interval;
    this.token = token;
    this.tokenPrefix = tokenPrefix || 'Token';
    this.expiration_date = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);
  }
}

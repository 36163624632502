import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'measurement'
})
export class MeasurementPipe implements PipeTransform {

  transform(value: string, measurementSymbol: string): any {
    if (isNaN(parseFloat(value)) && value.length) {
      value = '0';
    }

    return value === `` ? `${value} ${measurementSymbol}` : `${parseFloat(value)} ${measurementSymbol}`;
  }

  /**
   * Custom called method to parse string value to number
   */
  parseMeasurementFieldValue(value: string): number {
    if (value == null) {
      return null;
    }
    if (isNaN(parseFloat(value))) {
      return null;
    }
    return parseFloat(value);
  }
}

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { RouteBoundDialog } from '../../core/route-bound-dialog/route-bound-dialog.class';
import { RouteBoundDialogService } from '../../core/route-bound-dialog/route-bound-dialog.service';

@Component({
  selector: 'confirm-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class ConfirmDialog extends RouteBoundDialog {

  confirmBtnText: string;
  cancelBtnText: string;
  public title: string;
  public message: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialog>,
    public routeBoundDialogService: RouteBoundDialogService
  ) {
    super();
  }
}

import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/auth/services';
import { DefaultPages } from '../../auth/services/permissions/permissions.config';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  _removeBorder = false;
  @Input() set isProfileBuilderPage (value: boolean) {
    this._removeBorder = !!value;
  }
  @Output() menuToggled: EventEmitter<boolean> = new EventEmitter();
  midSize = 1440;
  isMenuToggled = false;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    if (window.innerWidth <= this.midSize) {
      this.toggleLeftMenu();
    }
  }

  toggleLeftMenu() {
    this.isMenuToggled = !this.isMenuToggled;
    this.menuToggled.emit(this.isMenuToggled);
  }

  logoClick() {
    this.router.navigate([DefaultPages[this.authService.currentUser().plan_group]]);
  }

}

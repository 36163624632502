import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { RouteBoundDialogService } from '../../../core/route-bound-dialog/route-bound-dialog.service';
import { RouteBoundDialog } from '../../../core/route-bound-dialog/route-bound-dialog.class';
import { ICompleteDialogConfig } from './dialog-complete.config';

@Component({
  selector: 'app-dialog-complete',
  templateUrl: './dialog-complete.component.html',
  styleUrls: ['./dialog-complete.component.scss']
})
export class DialogCompleteComponent  extends RouteBoundDialog {
  public title: string = "";
  config: ICompleteDialogConfig;

  constructor(
    public dialogRef: MatDialogRef<DialogCompleteComponent>,
    public routeBoundDialogService: RouteBoundDialogService
  ) {
    super();
  }

}

import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-progress-points-bar',
  templateUrl: './progress-points-bar.component.html',
  styleUrls: ['./progress-points-bar.component.scss']
})
export class ProgressPointsBarComponent implements OnInit, OnChanges {
  @Input() points: string[];
  @Input() value: string;

  lastCheckedIndex = 0;

  constructor() { }

  ngOnInit() {
    this.lastCheckedIndex = this.points.indexOf(this.value);
  }

  ngOnChanges() {
    this.lastCheckedIndex = this.points.indexOf(this.value);
  }
}

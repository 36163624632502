import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CropperSettings, ImageCropperComponent } from 'ngx-img-cropper';
import { BaseComponent } from '../../core/base/base-component';

@Component({
  selector: 'oc-cropper',
  templateUrl: './cropper.component.html'
})
export class CropperComponent extends BaseComponent implements OnInit {

  @Input() compressRatio: number = 3 / 4;
  @Input() imageWidth: number = 320;
  @Input() imageHeight: number = 400;
  @Input() width: number;
  @Input() observerService: any;
  @Input() dataSource: Observable<{
    type: string;
    data: any;
  }>;
  @ViewChild('cropper') cropper: ImageCropperComponent;

  file: File;
  data: any = {};
  cropperSettings: CropperSettings = new CropperSettings();

  static b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(b64Data.replace(/^data:image\/(jpg|jpeg|png|gif);base64,/, ''));
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.croppedWidth = this.imageWidth;
    this.cropperSettings.croppedHeight = this.imageHeight;
    this.cropperSettings.width = this.imageWidth;
    this.cropperSettings.height = this.imageHeight;
    this.cropperSettings.cropperDrawSettings.strokeColor = '#F58320';
    this.cropperSettings.cropperDrawSettings.dragIconStrokeColor = '#F58320';
    this.cropperSettings.cropperDrawSettings.dragIconFillColor = '#fafafa';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 1;
    this.cropperSettings.preserveSize = true;

    this.cropperSettings.compressRatio = this.compressRatio;
    this.cropperSettings.canvasWidth = this.width;

    this.subs = this.dataSource
      .pipe(filter(source => source && source.type === 'cropperFile'))
      .subscribe(source => {
        this.loadImage(source.data);
      });
  }

  loadImage(file: File) {
    this.file = file;
    const image: HTMLImageElement = new Image();
    const fr: FileReader = new FileReader();

    fr.addEventListener('load', (e: any) => {
      image.src = e.target.result;
      setTimeout(() => {
        this.cropper.setImage(image);
      }, 100);
    });

    fr.readAsDataURL(this.file);
  }

  onCrop() {
    this.observerService.emit({
      type: 'cropperImage',
      data: CropperComponent.b64toBlob(this.data.image, this.file.type)
    });
  }
}

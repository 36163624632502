import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { filter } from 'rxjs/operators';

import { AuthService } from '../../../auth/services';
import { ContactDetailsHelperService } from '../../../contacts/contact-details/contact-details-helper.service';
import { BaseComponent } from '../../../core/base/base-component';
import { LOCALIZATION_CONFIG } from '../../../../configs/localization.config';

import sg from '@angular/common/locales/en-SG';
import ms from '@angular/common/locales/ms';
import fil from '@angular/common/locales/fil';
import th from '@angular/common/locales/th';
import hk from '@angular/common/locales/en-HK';
import zh from '@angular/common/locales/zh';
import mo from '@angular/common/locales/en-MO';
import tw from '@angular/common/locales/zh-Hant';
import au from '@angular/common/locales/en-AU';

registerLocaleData(sg);
registerLocaleData(ms);
registerLocaleData(fil);
registerLocaleData(th);
registerLocaleData(hk);
registerLocaleData(zh);
registerLocaleData(mo);
registerLocaleData(tw);
registerLocaleData(au);

@Pipe({
  name: 'currencyLocal'
})
export class CurrencyLocalPipe extends BaseComponent implements PipeTransform {

  locale: string;
  format: string;
  symbol: string;
  localCurrencySymbol: string;

  constructor(
    private authService: AuthService,
    private contactDetailsHelperService: ContactDetailsHelperService,
    private currencyPipe: CurrencyPipe
  ) {
    super();
    if (this.authService.isLoggedIn()) {
      this.subs = this.authService.retrieveAccountDetails()
        .pipe(filter(Boolean))
        .subscribe((userData: any) => {
          this.locale = LOCALIZATION_CONFIG.GET_LOCALE_ID(userData.country);
          this.format = LOCALIZATION_CONFIG.GET_CURRENCY_FORMAT(userData.currency);
          this.symbol = LOCALIZATION_CONFIG.GET_CURRENCY_SYMBOL(userData.currency);
          this.localCurrencySymbol = this.symbol;
          this.setSymbol();
        });
    } else {
      const defaultCode: string = LOCALIZATION_CONFIG.SUPPORTED_CURRENCIES.find(c => c.isDefault).code;
      this.locale = LOCALIZATION_CONFIG.GET_LOCALE_ID(defaultCode);
      this.format = LOCALIZATION_CONFIG.GET_CURRENCY_FORMAT(defaultCode);
      this.symbol = LOCALIZATION_CONFIG.GET_CURRENCY_SYMBOL(defaultCode);
      this.localCurrencySymbol = this.symbol;
    }
  }

  transform(value: any, format?: string): any {
    this.setSymbol();
    // if value exist and not null or zero getValue otherwise return value
    value = value && typeof value === 'string' ? this.getValue(value) : value;
    // if after getValue method value is empty set zero value
    value = value ? value : 0;
    return this.currencyPipe.transform(value, this.symbol, 'symbol', format || this.format, this.locale);
  }

  // if value equal null or zero - return symbol else return value with symbol
  prepareToEdit(value: string): string {
    this.setSymbol();
    return this.getValue(value) ? value : this.symbol;
  }

  // if value equal null or zero - return zero
  // if value exist convert it to string, cut currency symbol, remove comas
  getValue(value: string | number | null): number {
    this.setSymbol();  
    return value ? parseFloat((value.toString().replace(this.symbol, '')).replace(/,/g, '')) : 0;
  }

  setSymbol() {
    let contactCurrencySymbol;
    // check if consumer has currency defined and change associate currency symbol
    if (this.contactDetailsHelperService.contact && this.contactDetailsHelperService.contact.consumer) {
      contactCurrencySymbol = LOCALIZATION_CONFIG.GET_CURRENCY_SYMBOL(this.contactDetailsHelperService.contact.consumer['currency']);
    }
    this.symbol = contactCurrencySymbol || this.localCurrencySymbol;
  }

}

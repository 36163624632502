import { Component, HostListener, Input, SimpleChanges, OnInit, OnChanges, SimpleChange } from '@angular/core';
import { Router, Route, NavigationEnd } from '@angular/router';
import { MainNavRoutes } from '../../../../app-routing.module';
import { FilterGroupService } from '../../search/segment-filters/form-dialog-filter-group/filter-group.service';
import { BaseComponent } from '../../../core/base/base-component';
import { SearchFilters } from '../../search/filters.model';
import { SearchFiltersObserverService } from '../../search/search-filters-observer.service';
import { AuthService } from '../../../auth/services/auth.service';
import { LOCALIZATION_CONFIG, ICountryData } from '../../../../configs/localization.config';
import { SearchService } from '../../search/search.service';

const ua = navigator.userAgent;
let isMobile = false;

/* if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
  isMobile = true;
} else {
  isMobile = false;
} */

@Component({
  selector: 'app-nav-bar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent implements OnInit {
  @Input() parentMenuToggled: any;
  navRoutes: Route[] = MainNavRoutes;
  isOpen = false;
  filterGroups: any;
  filterInputs: SearchFilters = new SearchFilters();
  showFilterGroups: boolean = true;
  countriesOptions: ICountryData[];
  selectedGroupIndex: number = 0;

  constructor(
    public router: Router,
    private filterGroupService: FilterGroupService,
    private authService: AuthService,
    private observer: SearchFiltersObserverService,
    private searchService: SearchService
  ) {
    super();
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event: any) {
    if (event.srcElement && event.srcElement['scrollingElement'].scrollTop > 55) {
      this.isOpen = false;
    }
  }

  ngOnInit() {
    this.countriesOptions = LOCALIZATION_CONFIG.SUPPORTED_COUNTRIES;
    this.filterGroupsSubscriber();

    // call API to get contact segments only for users with active subscription
    if (this.authService.currentUser().has_active_subscription) {
      this.getSegmentFilters();
    }

    // Set active contacts segment to 'All users'
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.selectedGroupIndex = 0;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.parentMenuToggled.currentValue) {
      this.showFilterGroups = false;
    }
    if (changes.parentMenuToggled.previousValue) {
      this.showFilterGroups = true;
    }
  }

  toggleState() {
    this.isOpen = !this.isOpen;
  }

  filterGroupsSubscriber() {
    this.subs = this.filterGroupService.filterGroupsSub$
      .subscribe(() => {
        this.getSegmentFilters();
      })
  }

  getSegmentFilters() {
    this.subs = this.filterGroupService.getFilterGroups()
      .subscribe((response) => {
        this.filterGroups = response.results;

        // Insert 'All users' item into array at index 0
        let allUsers = {
          name: 'All users',
          slug: '',
          predicate: [],
          contact_count: response.total_contacts || ''
        };
        this.filterGroups.splice(0, 0, allUsers);
      },
        (error) => { },
        () => { }
      );
  }

  applyFilters(filterGroup: any, index: number) {
    // navigate to contacts list if segments accessed from other route
    this.router.navigate(['/contacts']);
    this.searchService.resetSearchParam();

    setTimeout(() => {
      let mapping = {};

      // clear existing filters and search values
      this.filterInputs = new SearchFilters();


      filterGroup.predicate.map((item) => {
        switch (item.attr) {
          case "contact_type":
            this.filterInputs.contact_type[item.val] = true;
            break;

          case "financial_information__gender":
            mapping = { M: 'male', F: 'female' };
            this.filterInputs.gender[mapping[item.val]] = true;
            break;

          case "financial_information__marital_status":
            mapping = { sin: 'single', mar: 'married' };
            this.filterInputs.marital_status[mapping[item.val]] = true;
            break;

          case "rating":
            this.filterInputs.rating = item.val;

          case "financial_information__birth_date":
            if (item.op === 'gte') {
              this.filterInputs.age[0] = item.val;
            } else if (item.op === 'lte') {
              this.filterInputs.age[1] = item.val;
            }

          case "financial_information__gross_annual_income":
            if (item.op === 'gte') {
              this.filterInputs.income[0] = item.val;
            } else if (item.op === 'lte') {
              this.filterInputs.income[1] = item.val;
            }

          case "financial_information__country":
            this.countriesOptions.map((country) => {
              if (country.code === item.val) {
                this.filterInputs.geography.push(country);
              }
            })
            break;

          default:
            break;
        }
      })

      this.selectedGroupIndex = index;
      this.filterInputs.isFilterApplied = true;
      this.filterInputs.segmentId = filterGroup.slug;
      this.observer.emit('advanced_filters', this.filterInputs, 'blur');
    }, 500);
  }

  toggleDropdown(e: Event) {
    e.stopPropagation();
    this.showFilterGroups = !this.showFilterGroups;
  }
}

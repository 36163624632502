import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { RouteBoundDialogService } from './route-bound-dialog.service';
import { ResolutionHelperService } from '../services/resolution/resolution.service';


/**
 * Guard handles route navigation when dialog is opened,
 * Mostly is need to close dialog on Back button for Mobile devices instead going to previous url (page)
 */
@Injectable()
export class RouteBoundDialogGuard implements CanActivate, CanActivateChild {

  constructor(
    public resolutionHelper: ResolutionHelperService,
    private routeBoundDialogService: RouteBoundDialogService
  ) {}

  canActivate(): boolean {
    return this.canActivateAll();
  }

  canActivateChild(): boolean {
    return this.canActivateAll();
  }

  private canActivateAll(): boolean {
    const dialogInstance = this.routeBoundDialogService.dialogInstanceRef;

    if (dialogInstance && this.resolutionHelper.isMobile) {
      window.history.pushState({}, '', window.location.href);
      dialogInstance.close(false);
      this.routeBoundDialogService.clearOpenedDialog();

      return false;
    }

    return true;
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  MatDialogModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatCardModule,
  MatExpansionModule,
  MatInputModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatTabsModule,
  MatDialogConfig,
  MatSliderModule,
  MatTableModule,
  MatPaginatorModule
} from '@angular/material';
import { DateTimePickerModule } from 'ng-pick-datetime';
import { ImageCropperModule } from 'ngx-img-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LocalStorageService } from 'ngx-webstorage';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { TextMaskModule } from 'angular2-text-mask';
import { TranslateModule } from '@ngx-translate/core';
import { NouisliderModule } from 'ng2-nouislider';
import { TagInputModule } from 'ngx-chips';
import { GravatarModule } from 'ngx-gravatar';
import { ViralLoopsModule } from './viral-loops/viral-loops.module';
import { ClipboardModule } from 'ngx-clipboard';


// Components
import { AcStar } from '../contacts/shared/stars/star';
import { ActivityFormDialogComponent } from '../activities/activity-list/activity-form-dialog/activity-form-dialog.component';
import { ActivityTypeDialogComponent } from '../activities/activity-list/activity-type-dialog/activity-type-dialog.component';
import { ActivityViewDialogComponent } from '../activities/activity-list/activity-view-dialog/activity-view-dialog.component';
import { AvatarUploaderComponent } from './avatar-uploader';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CancelSubscriptionDialogComponent } from './cancel-subscription-dialog/cancel-subscription-dialog.component';
import { CardAvatarComponent } from './card-avatar/card-avatar.component';
import { CardDetailsFormComponent } from './subscription-plans/card-details-form/card-details-form.component';
import { ConfirmDialog } from './dialog/dialog.component';
import { ContactFormDialogComponent } from './contact-form-dialog/contact-form-dialog.component';
import { ContactListEmptyCardComponent } from '../contacts/shared/contact-list-empty-card/contact-list-empty-card.component';
import { CropperComponent } from './cropper/cropper.component';
import { CustomDatePickerComponent } from './custom-date-picker/custom-date-picker.component';
import { CustomDateRangeComponent } from '../shared/custom-date-range/custom-date-range.component';
import { DialogActivityFiltersComponent } from './search/dialog-activity-filters/dialog-activity-filters.component';
import { DialogCompleteComponent } from './dialog/dialog-complete/dialog-complete.component';
import { DialogContactsFiltersComponent } from './search/dialog-contacts-filters/dialog-contacts-filters.component';
import { DialogOpportunityComponent } from '../opportunities/dialog-opportunity/dialog-opportunity.component';
import { DialogOpportunityFiltersComponent } from './search/dialog-opportunity-filters/dialog-opportunity-filters.component';
import { DialogOptionCardComponent } from './dialog-option-card/dialog-option-card.component';
import { DropdownMenuComponent } from './dropdown-menu';
import { EmptyStateCardComponent } from './empty-state-card/empty-state-card.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { HeaderComponent } from './auth-onboarding/header/header.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageUploadDialogComponent } from './image-upload/image-dialog/image-dialog.component';
import { InlineErrorComponent } from './inline-error/inline-error.component';
import { LanguageComponent } from './security-layout/language/language.component';
import { LanguageComponent as LanguageSelectComponent } from './auth-onboarding/language/language.component';
import { ListCardActivityComponent } from './list-card-activity/list-card-activity.component';
import { ListCardOpportunityComponent } from './list-card-opportunity/list-card-opportunity.component';
import { LoaderComponent } from './loader/loader.component';
import { NavbarComponent } from './sidebar/navbar';
import { PaymentCouponComponent } from './payment-coupon/payment-coupon.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { PaymentFormDialogComponent } from './payment-form-dialog/payment-form-dialog.component';
import { PlanIntervalSwitcherComponent } from './subscription-plans/plan-switcher/plan-interval-switcher.component';
import { PlanListComponent } from './plan-list';
import { ProfileNotFoundComponent } from './profile-not-found/profile-not-found.component';
import { ProgressPointsBarComponent } from './progress-points-bar/progress-points-bar.component';
import { ReferralPopupComponent } from './referral-popup/referral-popup.component';
import { ReferralSharePopupComponent } from './referral-share-popup/referral-share-popup.component';
import { SearchComponent } from './search/search.component';
import { SearchFilterListComponent } from './search/search-filter-list/search-filter-list.component';
import { SearchFilterToggleComponent } from './search/search-filter-toggle/search-filter-toggle.component';
import { SearchFiltersMultipleComponent } from './search/search-filters-multiple/search-filters-multiple.component';
import { SecurityLayoutComponent } from './security-layout/security-layout.component';
import { SegmentFiltersComponent } from './search/segment-filters/segment-filters.component';
import { SidebarComponent } from './sidebar';
import { StarsComponent } from '../contacts/shared/stars/stars.component';
import { StripeModule } from './stripe/stripe.module';
import { SubscriptionCurrentComponent } from './subscription-plans/subscription-current/subscription-current.component';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { SubscriptionTableComponent } from './subscription-plans/subscription-table/subscription-table.component';
import { TableComponent } from './table/table.component';
import { TabsComponent } from '../shared/tabs';
import { TabsTogglerComponent } from './tabs-toggler/tabs-toggler.component';
import { ThreePositionsSliderComponent } from './three-positions-slider/three-positions-slider.component';
import { ToolbarActionsButtonComponent } from './toolbar-actions-button/toolbar-actions-button.component';
import { ToolbarComponent } from './toolbar/';
import { UserProfileComponent } from './user-profile';
import { ValidationMessagesComponent } from './validation/validation-messages.component';
import { ShareBoxComponent } from './share-box/share-box.component';
import { FormDialogFilterGroupComponent } from './search/segment-filters/form-dialog-filter-group/form-dialog-filter-group.component';
import { UpgradeToolbarComponent } from './upgrade-toolbar/upgrade-toolbar.component';
import { SyncGemWalletComponent } from './sync-gem-wallet/sync-gem-wallet.component';
import { ImageTitleCardComponent } from '../../modules/dashboard/dashboard-main/shared/image-title-card/image-title-card.component';

// Services
import { ActivityDialogsService } from '../activities/activity-list/activity-dialogs.service';
import { ActivityService } from '../api/activity.service';
import { AuthGuard } from '../auth/services/auth.guard.service';
import { ContactFormDialogService } from './contact-form-dialog/contact-form-dialog.service';
import { ContactService } from '../api/contact.service';
import { DialogService } from './dialog/dialog.service';
import { FormDatesService } from '../core/services/form-helpers/form-dates.service';
import { ImageUploadDialogService } from './image-upload/image-dialog/image-dialog.service';
import { ImageObserverService } from './image-upload/image-observer.service';
import { IntercomService } from './intercom';
import { OpportunityService } from '../api/opportunity.service';
import { OpportunityDialogService } from '../opportunities/opportunity-dialog.service';
import { PermissionsGuard } from '../auth/services/permissions/permissions.guard';
import { SearchFiltersObserverService } from './search/search-filters-observer.service';
import { UserProfileService } from './user-profile';
import { ValidationService } from '../shared/validation/validation.service';
import { FilterGroupService } from './search/segment-filters/form-dialog-filter-group/filter-group.service';
import { ContactDetailsHelperService } from '../contacts/contact-details/contact-details-helper.service';
import { SearchService } from './search/search.service';
import { PlanUpgradeService } from './services/plan-upgrade.service';
import {ReferralService} from '../api/referral.service';

// Directives
import { AvoidMatDialogAutoFocusDirective } from './avoid-mat-dialog-auto-focus/avoid-mat-dialog-auto-focus.directive';
import { AppCurrencyFormatterDirective } from './pipes/currency-formatter/currency-formatter.directive';
import { ElasticDirective } from './auto-grow/auto-grow.component';
import { IntlTelInputDirective } from './phone-prefix';
import { MeasurementFormatterDirective } from './pipes/measurement/measurement-formatter.directive';
import { OffsetFromParentBottomDirective } from './offset-from-parent-bottom/offset-from-parent-bottom.directive';
import { PrefixFormatterDirective } from './prefix-formatter/prefix-formatter.component';

// Pipes
import { AgeingPipe } from './pipes/ageing/ageing.pipe';
import { ArraySortPipe } from './pipes/orderBy/orderby.pipe';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { CeilPipe } from './pipes/ceil/ceil.pipe';
import { ReplaceUnderscore } from './pipes/replace-underscore/replace-underscore.pipe';
import { ClassFileExtensionPipe } from './pipes/class-file-extension.pipe';
import { CountdownPipe } from './pipes/countdown/countdown.pipe';
import { DateActivityPipe } from './pipes/date-activity/date-activity.pipe';
import { InnerTruncatePipe } from './pipes/inner-truncate/inner-truncate.pipe';
import { MeasurementPipe } from './pipes/measurement/measurement.pipe';
import { PriorityTitlePipe } from './pipes/priority-title-type/priority-title-type.pipe';
import { StatusPipe } from './pipes/status/status.pipe';
import { RouteBoundDialogGuard } from '../core/route-bound-dialog/route-bound-dialog.guard';
import { CurrencyLocalPipe } from './pipes/currency-local/currency-local.pipe';
import { ContactFinanceService } from '../contacts/shared/contact-finance.service';
import { ImageSliderComponent } from './image-slider/image-slider.component';
import { PremiumPlanDetailsComponent } from './subscription-plans/premium-plan-details/premium-plan-detail.component';
import { ConsultantReferralDialogComponent } from './consultant-referral-dialog/consultant-referral-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    DateTimePickerModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatTabsModule,
    MatTooltipModule,
    MatCardModule,
    MatExpansionModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    StripeModule,
    ImageCropperModule,
    MatDialogModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    InfiniteScrollModule,
    MatSliderModule,
    TextMaskModule,
    SwiperModule,
    TranslateModule,
    NouisliderModule,
    TagInputModule,
    GravatarModule,
    ViralLoopsModule,
    ClipboardModule,
    MatTableModule,
    MatPaginatorModule
  ],
  declarations: [
    AcStar,
    ActivityFormDialogComponent,
    ActivityTypeDialogComponent,
    ActivityViewDialogComponent,
    StarsComponent,
    AvatarUploaderComponent,
    AvoidMatDialogAutoFocusDirective,
    BreadcrumbsComponent,
    SearchComponent,
    ValidationMessagesComponent,
    AppCurrencyFormatterDirective,
    CountdownPipe,
    ArraySortPipe,
    CardAvatarComponent,
    ConfirmDialog,
    DialogOptionCardComponent,
    ToolbarComponent,
    FileUploadComponent,
    IntlTelInputDirective,
    ElasticDirective,
    MeasurementPipe,
    MeasurementFormatterDirective,
    PrefixFormatterDirective,
    SidebarComponent,
    NavbarComponent,
    UserProfileComponent,
    CropperComponent,
    InlineErrorComponent,
    PlanListComponent,
    CancelSubscriptionDialogComponent,
    ProfileNotFoundComponent,
    SecurityLayoutComponent,
    TabsComponent,
    TableComponent,
    DialogOpportunityFiltersComponent,
    ThreePositionsSliderComponent,
    ProgressPointsBarComponent,
    AgeingPipe,
    StatusPipe,
    TabsTogglerComponent,
    SubscriptionPlansComponent,
    CapitalizePipe,
    CeilPipe,
    ReplaceUnderscore,
    SearchFilterListComponent,
    SearchFilterToggleComponent,
    DialogActivityFiltersComponent,
    ToolbarActionsButtonComponent,
    DialogContactsFiltersComponent,
    CustomDatePickerComponent,
    CustomDateRangeComponent,
    DateActivityPipe,
    EmptyStateCardComponent,
    InnerTruncatePipe,
    DropdownMenuComponent,
    CardDetailsFormComponent,
    SubscriptionTableComponent,
    ListCardActivityComponent,
    ListCardOpportunityComponent,
    PlanIntervalSwitcherComponent,
    ContactListEmptyCardComponent,
    DialogOpportunityComponent,
    PriorityTitlePipe,
    SubscriptionCurrentComponent,
    ContactFormDialogComponent,
    LanguageComponent,
    LoaderComponent,
    OffsetFromParentBottomDirective,
    ClassFileExtensionPipe,
    DialogCompleteComponent,
    CurrencyLocalPipe,
    PaymentFormComponent,
    PaymentCouponComponent,
    PaymentFormDialogComponent,
    PremiumPlanDetailsComponent,
    SearchFiltersMultipleComponent,
    SegmentFiltersComponent,
    ReferralPopupComponent,
    HeaderComponent,
    LanguageSelectComponent,
    ImageSliderComponent,
    ReferralSharePopupComponent,
    ShareBoxComponent,
    ImageUploadComponent,
    ImageUploadDialogComponent,
    FormDialogFilterGroupComponent,
    UpgradeToolbarComponent,
    SyncGemWalletComponent,
    ConsultantReferralDialogComponent,
    ImageTitleCardComponent
  ],
  providers: [
    ActivityDialogsService,
    ActivityService,
    AuthGuard,
    ContactFormDialogService,
    ContactFinanceService,
    ContactService,
    DatePipe,
    CurrencyPipe,
    DialogService,
    FormDatesService,
    IntercomService,
    MatDialogConfig,
    MeasurementPipe,
    LocalStorageService,
    OpportunityService,
    OpportunityDialogService,
    PermissionsGuard,
    RouteBoundDialogGuard,
    UserProfileService,
    ValidationService,
    DecimalPipe,
    CurrencyLocalPipe,
    ImageUploadDialogService,
    ImageObserverService,
    ContactDetailsHelperService,
    ReferralService
  ],
  entryComponents: [
    ActivityFormDialogComponent,
    ActivityTypeDialogComponent,
    ActivityViewDialogComponent,
    CancelSubscriptionDialogComponent,
    ConfirmDialog,
    ContactFormDialogComponent,
    DialogActivityFiltersComponent,
    DialogContactsFiltersComponent,
    DialogOpportunityComponent,
    DialogOpportunityFiltersComponent,
    DialogCompleteComponent,
    PaymentFormDialogComponent,
    ImageUploadDialogComponent,
    FormDialogFilterGroupComponent,
    ConsultantReferralDialogComponent
  ],
  exports: [
    TranslateModule,
    AcStar,
    AvoidMatDialogAutoFocusDirective,
    RouterModule,
    ClassFileExtensionPipe,
    DateTimePickerModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatTabsModule,
    MatTooltipModule,
    MatInputModule,
    MatExpansionModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NouisliderModule,
    TagInputModule,
    AvatarUploaderComponent,
    BreadcrumbsComponent,
    SearchComponent,
    ValidationMessagesComponent,
    AppCurrencyFormatterDirective,
    CountdownPipe,
    CapitalizePipe,
    ReplaceUnderscore,
    ArraySortPipe,
    CardAvatarComponent,
    ConfirmDialog,
    DialogOptionCardComponent,
    FileUploadComponent,
    InnerTruncatePipe,
    ToolbarComponent,
    ToolbarActionsButtonComponent,
    IntlTelInputDirective,
    ElasticDirective,
    MeasurementPipe,
    MeasurementFormatterDirective,
    PrefixFormatterDirective,
    SidebarComponent,
    NavbarComponent,
    UserProfileComponent,
    CropperComponent,
    InlineErrorComponent,
    PlanListComponent,
    TabsComponent,
    ProfileNotFoundComponent,
    SecurityLayoutComponent,
    TableComponent,
    MatSliderModule,
    ThreePositionsSliderComponent,
    ProgressPointsBarComponent,
    AgeingPipe,
    StatusPipe,
    TabsTogglerComponent,
    SubscriptionPlansComponent,
    CustomDatePickerComponent,
    CustomDateRangeComponent,
    EmptyStateCardComponent,
    DropdownMenuComponent,
    CardDetailsFormComponent,
    SubscriptionTableComponent,
    TextMaskModule,
    ListCardActivityComponent,
    ListCardOpportunityComponent,
    PlanIntervalSwitcherComponent,
    ContactListEmptyCardComponent,
    SegmentFiltersComponent,
    MatDialogModule,
    DateActivityPipe,
    PriorityTitlePipe,
    StarsComponent,
    LoaderComponent,
    OffsetFromParentBottomDirective,
    CurrencyLocalPipe,
    PaymentFormComponent,
    PaymentCouponComponent,
    LanguageComponent,
    ReferralPopupComponent,
    ViralLoopsModule,
    SwiperModule,
    HeaderComponent,
    LanguageSelectComponent,
    ImageSliderComponent,
    ReferralSharePopupComponent,
    ViralLoopsModule,
    ShareBoxComponent,
    ImageUploadComponent,
    ClipboardModule,
    UpgradeToolbarComponent,
    SyncGemWalletComponent,
    MatTableModule,
    MatPaginatorModule,
    ImageTitleCardComponent
  ]
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [SearchFiltersObserverService, FilterGroupService, SearchService, PlanUpgradeService]
    };
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchFilters } from '../filters.model';
import { SearchFiltersObserverService } from '../search-filters-observer.service';
import { LOCALIZATION_CONFIG, ICountryData } from '../../../../configs/localization.config';
import { EventHandlerService } from '../../../core/services/event-handler';
import { BaseComponent } from '../../../core/base/base-component';

@Component({
  selector: 'app-search-filters-multiple',
  templateUrl: './search-filters-multiple.component.html',
  styleUrls: ['./search-filters-multiple.component.scss']
})

export class SearchFiltersMultipleComponent extends BaseComponent implements OnInit {

  isOpen: boolean = false;
  filterInputs: SearchFilters = new SearchFilters();

  ageRangeConfig: any = {
    behaviour: 'drag',
    connect: true,
    limit: 100,
    step: 1,
    range: {
      min: 0,
      max: 100
    },
  };

  incomeRangeConfig: any = {
    behaviour: 'drag',
    connect: true,
    limit: 500000,
    step: 10000,
    range: {
      min: 0,
      max: 500000
    },
  };

  countriesOptions: ICountryData[];

  private searchEmitter = EventHandlerService.get('search_emitter');

  constructor(
    private observer: SearchFiltersObserverService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeFilterChanges();
    this.countriesOptions = LOCALIZATION_CONFIG.SUPPORTED_COUNTRIES;
  }

  subscribeFilterChanges(): void {
    this.subs = this.searchEmitter.subscribe(params => {
      if(params.advanced_filters) {
        this.filterInputs = Object.assign({}, params.advanced_filters);
      }
    });
  }

  toggleFilterOptions() {
    this.isOpen = !this.isOpen;
  }

  closeFiltersOverlay() {
    this.isOpen = false;
  }

  submitFilters() {
    this.closeFiltersOverlay();
    this.filterInputs.segmentId = null;
    this.filterInputs.isFilterApplied = true;
    this.observer.emit('advanced_filters', this.filterInputs, 'blur');
  }

  clearFilters() {
    this.closeFiltersOverlay();
    this.filterInputs = new SearchFilters();
    this.observer.emit('advanced_filters', this.filterInputs, 'blur');
  }

  onRatingChange(value: number) {
    this.filterInputs.rating = value;
  }

}
import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../core/base/base-component';
import { SubscriptionService, SubscriptionPlan } from '../../../api/subscription.service';
import Faq from '../../../shared/plan-list/plan-list.faq';
import { _t } from 'app/modules/core/other/translate-marker';
import { PlanUpgradeService } from '../../services/plan-upgrade.service';

export interface Feature {
  name: string;
  description: string;
  is_active: boolean;
}

export interface PlanDetails {
  features: Feature[];
  activeFeature: {};
  is_active: boolean;
  is_recommended: boolean;
  plan: SubscriptionPlan;
  yearly_plan: SubscriptionPlan;
}

export interface PlansSet {
  month: SubscriptionPlan;
  year: SubscriptionPlan;
}

@Component({
  selector: 'app-subscription-table',
  templateUrl: './subscription-table.component.html',
  styleUrls: ['./subscription-table.component.scss']
})
export class SubscriptionTableComponent extends BaseComponent implements OnInit {
  private _planGroup;
  @Input() set planGroup(value) {
    this._planGroup = value;
    this.updateCurrentPlan();
  };
  @Input() buttonText: string;
  @Input() isLoading: boolean;
  @Input() showFaq: boolean;
  @Output() planSelected: EventEmitter<PlansSet> = new EventEmitter(null);
  @Output() currentSubscriptionEvent: EventEmitter<PlansSet> = new EventEmitter(null);

  @ViewChild('upgradePlanBtn') upgradePlanBtn: ElementRef;

  features: Feature[] = [];
  plans: PlanDetails[];
  viewModel: any[] = [];
  currentPlanId: string;
  faq: { title: string, text: string }[];

  premiumPlanDetail: PlanDetails;
  premiumPlan: SubscriptionPlan = {id : 998, stripe_id : _t('premiumPlanDetails.monthly'), amount : 360, currency : 'usd' , interval: 'month', interval_count: 1, name: _t('premiumPlanDetails.name'), statement_descriptor: '', trial_period_days: null, metadata: '{}', stripe_account: null};
  premiumYearlyPlan: SubscriptionPlan = {id : 999, stripe_id : _t('premiumPlanDetails.monthly'), amount : 3600, currency : 'usd' , interval: 'year', interval_count: 1, name: _t('premiumPlanDetails.name'), statement_descriptor: '', trial_period_days: null, metadata: '{}', stripe_account: null};
  preimumFeatures: Feature[] = [{description : '', is_active: true, name: _t('premiumPlanDetails.description')}]

  isMonth :Boolean = false;
  constructor(
    private subscriptionService: SubscriptionService,
    private planUpgradeService: PlanUpgradeService,
    private router: Router,
    private route: ActivatedRoute
    ) {
    super();
  }

  ngOnInit() {
    this.faq = Faq;
    this.subscriptionService.getSubscriptionPlansDetails()
      .subscribe(
        res => {
          this.plans = res['results'];
          const features = {};
          this.plans.forEach((plan: PlanDetails) => {
            plan.activeFeature = {};

            plan.features
              .filter((feature: Feature) => feature.is_active)
              .forEach((feature: Feature) => {
                if (!features[feature.name]) {
                  features[feature.name] = true;
                  this.features.push(feature);
                }

                plan.activeFeature[feature.name] = true;
              });
          });

          let recommendedPlan: any = this.plans.filter(plan => plan.is_recommended === true);
          this.plans = this.plans.filter(plan => plan.is_recommended === false);
          this.plans.push(<PlanDetails><any>recommendedPlan[0]);

          let premiumPlanDetail : PlanDetails = {
            features : this.preimumFeatures,
            plan : this.premiumPlan,
            yearly_plan : this.premiumYearlyPlan,
            is_active : true,
            is_recommended : false,
            activeFeature : { 'Premium Feature' : true }
          };
          this.plans.push(premiumPlanDetail);


          this.viewModel.push(this.features, ...this.plans);

          // handle query params
          if (this.planUpgradeService.planType) {
            setTimeout(() => {
              this.handleQueryParams();
            }, 100)
          }
        });

  }

  handleQueryParams() {
    let planType = this.planUpgradeService.planType;

    let basicPlanUpgradeBtn = document.getElementById('upgradeBtn-1');
    let consultantPlanUpgradeBtn = document.getElementById('upgradeBtn-2');

    switch (planType) {
      case "basic":
        if (basicPlanUpgradeBtn) {
          basicPlanUpgradeBtn.click();
        }
        break;

      case "consultant":
        if (consultantPlanUpgradeBtn) {
          consultantPlanUpgradeBtn.click();
        }
        break;

      default:
        break;
    }
  }

  showToolTip(name: string, feature: string) {
    return (feature) ? true : false;
  }

  updateCurrentPlan() {
    this.subscriptionService.currentSubscription()
      .subscribe(data => {
        if (data) {
          this.currentSubscriptionEvent.emit(data);
        }
        if (data && data.plan) {
          this.currentPlanId = data.plan.stripe_id;
        }
      }, () => { },
        () => this.isLoading = false
      );
  }

  isItCurrrentPlan(plan: PlanDetails): boolean {
    if (!this.currentPlanId) {
      return false;
    }

    return (this.isMonth ? plan.plan.stripe_id === this.currentPlanId:plan.yearly_plan.stripe_id === this.currentPlanId );
  }

  upgradePlan(plan: PlanDetails, last: boolean) {
    if ( last ) {
      this.router.navigate(['./premium-plan'], { relativeTo: this.route });
      return;
     }
    this.planSelected.emit({
      year: plan.yearly_plan,
      month: plan.plan
    });
  }
  changeValue(e) {
    this.isMonth= e.target.value
    if(e.target.value==='true')
    {
     this.isMonth= true
    }
    else{
      this.isMonth= false
    }
  }
}

import { Injectable } from '@angular/core';
import { Router, Params } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { Response } from '@angular/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { API_CONFIG, PARAM } from '../../../configs';
import { ApiService } from '../../api';
import { IUserToken, UserTokenService } from './user.token.service';
import { ViralLoopsService } from 'app/modules/shared/viral-loops/services/viral-loops.service';
import { Profile } from 'app/modules/profile/models/profile';
import { BaseComponent } from '../../core/base/base-component';

@Injectable()
export class AuthService extends BaseComponent {
  userModel: any = {};
  userFormKey = 'userForm';
  defaultUrl = '/contacts';
  redirectUrl: string;
  redirectQueryParams: Params;

  readonly accountDetailsBehaviorSubject: BehaviorSubject<AccountDetailsResponse> = new BehaviorSubject(null);

  private get slug(): string | null {
    const userData = this.localStorage.retrieve('userForm');
    return userData ? userData.slug : '';
  }

  constructor(
    private router: Router,
    private apiService: ApiService,
    private userTokenService: UserTokenService,
    private localStorage: LocalStorageService,
    private viralLoopsService: ViralLoopsService,
  ) {
    super();
  }

  public returnSlug(): string | null {
    return this.slug;
  }

  // Store base user profile form data in local storage
  storeUserData(formModel: Profile): void {
    const data = this.localStorage.retrieve(this.userFormKey);

    if (data && Object.keys(data).length) {
      this.userModel = Object.assign(data, formModel);
    } else {
      this.userModel = formModel;
    }

    this.localStorage.store(this.userFormKey, this.userModel);
  }

  retrieveStoredUserData(): any {
    return this.localStorage.retrieve(this.userFormKey);
  }

  updateFinancialConsultantPhoto(formModel: any, slug: string): Observable<Response> {
    if (formModel.photo) {
      const fd: FormData = new FormData();
      fd.append('picture', formModel.photo as Blob, formModel.photo_name);
      return this.apiService.patchFormData(API_CONFIG.account.financialConsultant.replace(PARAM, slug), fd);
    }

    return this.apiService.patch(API_CONFIG.account.financialConsultant.replace(PARAM, slug), { picture: null });
  }

  updateProfileBuilderPhoto(formModel: Profile): Observable<Response> {
    const storageData = this.localStorage.retrieve(this.userFormKey);

    if (storageData) {
      formModel.personalized_link = storageData.personalized_link;
    }

    if (formModel && formModel['photo']) {
      const fd: FormData = new FormData();
      fd.append('photo', formModel.photo as Blob, formModel.photo_name);
      return this.apiService.patchFormData(API_CONFIG.profileBuilder.update.replace(PARAM, this.slug), fd);
    }

    return this.apiService.patch(API_CONFIG.profileBuilder.update.replace(PARAM, this.slug), formModel);
  }

  updateUserCustomize(id: any, formModel: any): Observable<Response> {
    return this.apiService.patch(API_CONFIG.profileBuilder.update.replace(PARAM, id), formModel);
  }

  updateProfileBuilder(formModel: any, id?): Observable<Profile> {
    const storageData = this.localStorage.retrieve(this.userFormKey);

    if (storageData) {
      formModel['personalized_link'] = storageData['personalized_link'];
    }

    return this.apiService.put(API_CONFIG.profileBuilder.update.replace(PARAM, this.slug), formModel);
  }

  retrieveUserProfile(personalized_link?): Observable<Profile> {
    if (personalized_link) {
      return this.apiService.get(API_CONFIG.profileBuilder.publicProfile.replace(PARAM, personalized_link));
    }
    return this.apiService.get(API_CONFIG.profileBuilder.get.replace(PARAM, this.slug));
  }

  acceptTestimonialRequest(slug: string): Observable<Profile> {
    if(slug){
      return this.apiService.post(API_CONFIG.profileBuilder.addTestimonial.replace(PARAM, slug), null);
    }
  }

  retrieveAccountDetails(forceRequest:boolean=false): Observable<AccountDetailsResponse> {
    if (this.accountDetailsBehaviorSubject.value && !forceRequest) {
      return this.accountDetailsBehaviorSubject;
    } else {
      return this.apiService.get(API_CONFIG.account.get)
        .pipe(tap(response => this.onAccountUpdated(response)));
    }
  }

  getAccountInfo(): Observable<any> {
    return this.apiService.get(API_CONFIG.account.get);
  }

  resetRetrievedAccountDetails(): void {
    this.accountDetailsBehaviorSubject.next(null);
  }

  updateAccountFinancialConsultant(data, slug: string): Observable<AccountDetailsResponse> {
    return this.apiService.patch(API_CONFIG.account.financialConsultant.replace(PARAM, slug), data)
      .pipe(tap(response => this.onAccountUpdated(response)));
  }

  /**
   * Retreive from API combined information of account and profile of a logged in consultant
   * @param slug - contact id
   */
  getConsultantInfo(): Observable<any> {
    return this.apiService.get(API_CONFIG.account.getConsultant);
  }

  /**
   * @deprecated
   * this method doesn't use anywhere
   * @param id
   * @returns {Observable<Response>}
   */
  retrieveUserPhoto(id?): Observable<Response> {
    const slug = this.slug || id;
    return this.apiService.get(API_CONFIG.profileBuilder.getUserPhoto.replace(PARAM, slug));
  }

  uploadProfileImages(formModel: any) {
    const fd: FormData = new FormData();
    fd.append('image', formModel['photo'], formModel['photo_name']);
    return this.apiService.post(API_CONFIG.profileBuilder.uploadProfileImage, fd);
  }

  userProfileCreateContact(formModel: any, personalized_link: string) {
    formModel['profile'] = personalized_link;
    return this.apiService.post(API_CONFIG.profileBuilder.createContact.replace(PARAM, personalized_link), formModel);
  }

  userProfileCreateTestimonial(formModel: any, personalized_link: string) {
    delete formModel.recaptcha;

    const body = new FormData();

    for (const key in formModel) {
      if (formModel[key]) {
        body.append(key, formModel[key]);
      }
    }
    return this.apiService.post(API_CONFIG.profileBuilder.createTestimonial.replace(PARAM, personalized_link), body);
  }

  login(user: IUserLogin): Observable<Response> {
    return this.apiService.post(API_CONFIG.auth.login, user);
  }

  logout() {
    this.subs = this.apiService.post(API_CONFIG.authWithToken.logout, null)
      .subscribe((response) => {
        this.userTokenService.remove();
        this.resetRetrievedAccountDetails();
        this.router.navigate(['/auth/login']);
      },
        (error) => { },
        () => { }
      );

    // TODO: uncomment when need to get Viral Loop back
    // this.viralLoopsService.logout()
  }

  reset(form: any): Observable<Response> {
    return this.apiService.post(API_CONFIG.auth.resetPassword, form).pipe(
      tap(() => this.resetRedirectUrl())
    );
  }

  resetConfirm(uid: string, token: string, new_password: string): Observable<Response> {
    return this.apiService.post(API_CONFIG.auth.confirmPasswordReset, {
      'uid': uid,
      'token': token,
      'new_password': new_password
    });
  }

  verifyToken(uid: string, token: string): Observable<Response> {
    return this.apiService.get(API_CONFIG.auth.verifyResetPassword
      .replace(PARAM, uid)
      .replace(PARAM, token)
    );
  }

  register(user: IUserCreate): Observable<Response> {
    return this.apiService.post(API_CONFIG.auth.register, user);
  }

  activate(uid: string, token: string): Observable<Response> {
    return this.apiService.post(API_CONFIG.auth.activate, {
      'uid': uid,
      'token': token
    }
    );
  }

  currentUser(): IUserToken {
    return this.userTokenService.get();
  }

  isLoggedIn(): boolean {
    if (this.userTokenService.get() && this.userTokenService.isValid()) {
      return true;
    } else {
      return false;
    }
  }

  getStoredPersonalizedLink(): string {
    const userform: {
      email: string;
      for_profile_builder: boolean;
      personalized_link: string;
      slug: string;
    } = this.localStorage.retrieve(this.userFormKey);

    return userform ? userform.personalized_link : null;
  }

  socialAuth(data: any): Observable<any> {
    return this.apiService.post(API_CONFIG.auth.socialAuth, data);
  }

  resetRedirectUrl(): void {
    this.redirectUrl = null;
  }

  resetRedirectQueryParams(): void {
    this.redirectQueryParams = null;
  }

  private onAccountUpdated(response: AccountDetailsResponse): void {
    this.accountDetailsBehaviorSubject.next(response);
    // TODO: uncomment when need to get Viral Loop back
    // this.viralLoopsService.identify(response, false);
  }
}

export interface IUser {
  id: number;
  email: string;
  password: string;
}

export interface IUserCreate {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  slug?: string;
  country: string;
  password: string;
  language?: string;
}

export interface I6FPUserCreate extends IUserCreate {
  company: string,
  position: string,
  workshop: string,
  source: string
}

export class UserCreate implements IUserCreate {
  first_name = '';
  last_name = '';
  email = '';
  phone = '';
  country = '';
  password = '';
}

export class FPUserCreate implements I6FPUserCreate {
  first_name = '';
  last_name = '';
  email = '';
  phone = '';
  country = '';
  password = '';
  company = '';
  position = '';
  workshop = '';
  source = '';
}



export interface IUserLogin {
  email: string;
  password: string;
}
export class AccountDetailsResponse {
  slug: string;
  email: string;
  timezone: string;
  created: string;
  first_name: string;
  job_title: string;
  last_name: string;
  dp_url: string;
  phone: string;
  is_agency_manager: boolean;
  profile: string;
  advisory_agency: string;
  onboarding_step: string;
  country: string;
  currency: string;
  language: string;
  onboarding_status: {
    account: number,
    profile: number
  };
  picture: string;

  constructor() { }
}

export class UserLogin implements IUserLogin {
  email = '';
  password = '';
}

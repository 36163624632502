import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../../api/api.service';
import { API_CONFIG, PARAM } from '../../../configs';

@Injectable()
export class ShareBoxService {

	constructor(private apiService: ApiService) { }

	getInviteLink(): Observable<any> {
		return this.apiService.get(API_CONFIG.referral.inviteLink);
	}

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material';
import { FormDialogFilterGroupComponent } from './form-dialog-filter-group.component';
import { FilterTag } from '../../filters.model';

@Injectable()
export class FilterGroupFormDialogService {

  constructor (private dialog: MatDialog) {}

  openModal(filterTags: FilterTag[]): Observable<any> {
    let dialogRef: MatDialogRef<FormDialogFilterGroupComponent>;
    dialogRef = this.dialog.open(FormDialogFilterGroupComponent);

    // init dialog component properties
    dialogRef.componentInstance.filterTags = filterTags;

    return dialogRef.afterClosed();
  }
}

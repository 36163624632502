import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router} from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { ResolutionHelperService } from './../../resolution/resolution.service';
import { IntroJSCurrentStep, IIntrojsStep } from './../intro-js-models';
import { CONTACTS_STEPS } from './contacts-steps';
import { CONTACTS_STEPS_FAKE_DATA } from './contacts-fake-data';
import { MARKETPLACE_APPS_CONFIG } from './../../../../../configs/marketplace-apps.config';
import { BaseDynamicComponent } from '../../../../marketplace/dynamic-component-loader/base-dynamic-component';
import { DynamicComponentLoader } from '../../../../marketplace/dynamic-component-loader/dynamic-component-loader.service';

declare const introJs;

@Injectable()
export class ContactsIntrojsService {
  private introJsTour: any;
  private renderer: Renderer2;
  private steps: IIntrojsStep[] = CONTACTS_STEPS;
  private tourIsProcessingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  stepBehaviourSubject: Subject<IntroJSCurrentStep> = new Subject();

  get introJsContactsWasSeen(): boolean {
    return this.localStorageService.retrieve('introJsContactsWasSeen');
  }

  get tourIsProcessing(): boolean {
    return this.tourIsProcessingSubject.value;
  }

  set tourIsProcessing(isProcessing: boolean) {
    this.tourIsProcessingSubject.next(isProcessing);
  }

  constructor(
    private localStorageService: LocalStorageService,
    private rendererFactory: RendererFactory2,
    private resolutionHelper: ResolutionHelperService,
    private router: Router,
    private dynamicComponentLoader: DynamicComponentLoader,
    private location: PlatformLocation,
    private translateService: TranslateService,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    this.handleBackForwardBrowserClick();
  }

  initTour(): void {
    this.tourIsProcessing = true;

    this.steps.forEach((step) => step.intro = this.translateService.instant(step.intro));

    this.introJsTour = introJs().setOptions({
      exitOnOverlayClick: false,
      keyboardNavigation: false,
      nextLabel: '',
      prevLabel: '',
      doneLabel: '',
      overlayOpacity: 0.4,
      showStepNumbers: false,
      steps: this.steps
    });

    this.localStorageService.store('introJsContactsWasSeen', true);

    this.configureExit();
    this.startTour();
  }

  startTour(): void {
    this.configureBeforeStartTour();
    this.introJsTour.start();
    this.toggleBodyScrolling(false);
    this.preloadLazyModulesForSteps();
  }

  private configureBeforeEachStepChanghes(): void {
    const interval = setInterval(() => {
      const targetEl = document.querySelector(CONTACTS_STEPS[this.introJsTour._currentStep].element);

      if (targetEl) {
        const toolbarEl: Element = document.querySelector('#toolbar');

        switch (this.introJsTour._currentStep) {
          case 0:
          case 3:
            if (!this.resolutionHelper.isMobile) {
              this.renderer.setStyle(toolbarEl, 'width', '100%');
            }
            if (this.resolutionHelper.isMobile && this.introJsTour._currentStep === 0 ) {
              const toolbarBreadcrumbsBlockEl: Element = document.querySelector('#toolbar-breadcrumbs-block');
              const toolbarActionsBlockEl: Element = document.querySelector('#toolbar-actions-block');
              const leftSideEl: Element = document.querySelector('#left-side');
              this.renderer.addClass(toolbarBreadcrumbsBlockEl, 'introjs-fixParent');
              this.renderer.setStyle(toolbarActionsBlockEl, 'z-index', '-1');
              this.renderer.setStyle(leftSideEl, 'z-index', 'auto');
            }
            this.renderer.setStyle(toolbarEl, 'position', 'absolute');
            this.renderer.setStyle(toolbarEl, 'transition', 'none');
            this.renderer.addClass(toolbarEl, 'introjs-fixParent');
            this.renderer.addClass(targetEl, 'introjs-showElement');
            break;
          case 1:
          case 2:
            this.renderer.addClass(targetEl, 'introjs-showElement');
            break;
        }
        this.introJsTour._introItems[this.introJsTour._currentStep].element = targetEl;
        this.introJsTour.refresh();
        clearInterval(interval);
      }
    }, 100)
  }

  private configureBeforeStartTour(): void {

    this.introJsTour.onafterchange(targetElement => {
      const helperLayerEl: Element = document.querySelector('.introjs-helperLayer');
      if (helperLayerEl) {
        this.renderer.setStyle(helperLayerEl, 'display', 'none');
      }
      const tooltipEl: Element = document.querySelector('.introjs-tooltipReferenceLayer');
      if (tooltipEl) {
        this.renderer.setStyle(tooltipEl, 'display', 'none');
      }
    });

    this.introJsTour.onbeforechange(targetElement => {
      this.stepBehaviourSubject.next(new IntroJSCurrentStep(this.introJsTour._currentStep, this.introJsTour._direction));

      switch (this.introJsTour._currentStep) {
        case 0: {
          this.router.navigate(['/contacts']);
          this.configureBeforeEachStepChanghes();
        } break;

        case 1: {
          this.router.navigate(['/contacts/form']);
          this.configureBeforeEachStepChanghes();
        } break;

        case 2: {
          this.router.navigate([`/contacts/${CONTACTS_STEPS_FAKE_DATA[2].fakeData.contactId}`]);
          this.configureBeforeEachStepChanghes();
        } break;

        case 3: {
          this.router.navigate([`/contacts`]);
          this.configureBeforeEachStepChanghes();
        } break;
      }
    });
  }

  private toggleBodyScrolling(enable: boolean): void {
    if (enable) {
      this.renderer.removeStyle(document.body, 'overflow'); // enable body scrolling
    } else {
      this.renderer.setStyle(document.body, 'overflow', 'hidden'); // disable body scrolling
    }
  }

  private configureExit(): void {
    this.introJsTour.onexit(() => {
      this.stepBehaviourSubject.next(new IntroJSCurrentStep(null, null));
      this.tourIsProcessing = false;

      const toolbarEl: Element = document.querySelector('#toolbar');
      this.renderer.removeStyle(toolbarEl, 'width');
      this.renderer.removeStyle(toolbarEl, 'position');
      this.renderer.removeStyle(toolbarEl, 'left');
      setTimeout(() => this.renderer.removeStyle(toolbarEl, 'transition'))

      const toolbarActionsBlockEl: Element = document.querySelector('#toolbar-actions-block');
      const leftSideEl: Element = document.querySelector('#left-side');
      this.renderer.removeStyle(toolbarActionsBlockEl, 'z-index');
      this.renderer.removeStyle(leftSideEl, 'z-index');

      this.toggleBodyScrolling(true);

      setTimeout(() => this.router.navigate(['/contacts']));
    })
  }

  private handleBackForwardBrowserClick(): void {
    this.location.onPopState(() => {
      if (this.tourIsProcessing) {
        this.introJsTour.exit();
      }
    });
  }

  /**
   * Preload lazy modules to avoid freeze effect before steps which requires new page from lazy load module
   */
  private preloadLazyModulesForSteps(): void {
    setTimeout(() => {
      // preloading SnapAndSyncModule what contains ContactDetailsModule what used for Contacts Intro JS Guide
      this.dynamicComponentLoader
        .getComponentFactory<BaseDynamicComponent>(MARKETPLACE_APPS_CONFIG.contactDetailsApps.snapAndSync)
        .subscribe();
    }, 1000);
  }
}

import { AfterViewInit, Directive, ElementRef, HostListener, OnInit } from "@angular/core";

@Directive({selector: "[prefix-formatter]"})
export class PrefixFormatterDirective implements OnInit, AfterViewInit {
  private el: any;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (!this.el.value) this.el.value = this.el.placeholder;
  }

  @HostListener("keyup", ["$event"])
  onKeyUp(event) {
    if (event.target['value'].indexOf(event.target['placeholder']) === -1
      || event.target['value'].length < event.target['placeholder'].length) {
      this.el.value = event.target['placeholder'];
    }
  }

  @HostListener("blur", ["$event"])
  onBlur(event) {
    if (event.target['value'].indexOf(event.target['placeholder']) === -1) {
      this.el.value = event.target['placeholder'];
    }
  }
}



import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { _t } from 'app/modules/core/other/translate-marker';

@Component({
  selector: 'app-three-positions-slider',
  templateUrl: './three-positions-slider.component.html',
  styleUrls: ['./three-positions-slider.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ThreePositionsSliderComponent),
    multi: true,
  }]
})
export class ThreePositionsSliderComponent implements ControlValueAccessor {

  value: number;
  displayedLabel: string;

  @Input('min') min = 25;
  @Input('max') max = 75;
  @Input('step') step = 25;
  @Input('ticksInterval') ticksInterval = 1;

  @Input('placeholder') placeholder = _t('contacts.opportunities.probability');
  @Input('customClass') customClass = 'three-positions-slider';

  @Input('steps') steps = [25, 50, 75];
  @Input('labels') labels = [_t('contacts.priorities.priorityLevels.low'), _t('contacts.priorities.priorityLevels.medium'), _t('contacts.priorities.priorityLevels.high')];

  constructor() { }

  // this is the initial value set to the component
  public writeValue(value: number) {
    if (Number.isInteger(value)) {
      this.value = value;
      return this.labelHandler(this.value);
    }
    this.labelHandler(this.steps[0]);
  }

  // registers 'fn' that will be fired when changes are made
  // this is how we emit the changes back to the form
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // not used, used for touch input
  public registerOnTouched() { }

  // change events from the slider
  onChange(event) {
    this.value = event.value;
    this.labelHandler(this.value);
    this.propagateChange(this.value);
  }

  // the method set in registerOnChange to emit changes back to the form
  private propagateChange = (_: any) => { };

  mediumPosition(): boolean {
    return this.value === this.steps[1];
  }

  maxPosition(): boolean {
    return this.value === this.steps[2];
  }

  private labelHandler(value: number): void {
    switch (value) {
      case this.steps[0]: this.displayedLabel = this.labels[0]; break;
      case this.steps[1]: this.displayedLabel = this.labels[1]; break;
      case this.steps[2]: this.displayedLabel = this.labels[2]; break;
    }
  }
}

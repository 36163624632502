import { Component, Input } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent {

  @Input() imagesList;

  swiperConfig: SwiperConfigInterface = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      bulletActiveClass: 'swiper-pagination-white',
      clickable: true
    },
    spaceBetween: 30,
    slidesPerView: 1,
    loop: false,
    autoHeight: true,
    watchSlidesProgress: true,
    centeredSlides: true,
    observer: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }
  };
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `..angular-cli.json`.
import { VERSION } from './version';

export const environment = {
  production: false,
  env: 'dev',
  VERSION,
  apiUrl: 'https://freshcider.dev.synchestra.net',
  stripePublicKey: 'pk_test_AMfDvQ4ITwsi3aJQLQ9z8hX1',
  blogLink: 'http://dev.synchestra.net/',
  socialLinks: {
    facebook: 'https://www.facebook.com/synchestrafintech/',
    twitter: 'https://twitter.com/synchestra_app',
    linkedin: 'https://www.linkedin.com/company/13246222/',
    instagram: 'https://www.instagram.com/synchestra_marketing/',
  },
  supportEmail: 'info@synchestra.com',
  fbAppId: '1946139079004561',
  intercomAppId: 'fp4jd23q',
  intercomSecretKey: 'wASwShAqtV8UOF_DRW9yul-reCSzWfP2kHUGKFCT',
  baseHost: 'https://dev.synchestra.net',
  viralLoops: {
    campaignIds: {
      altruistic: 'YF7Uej29N1BxpWLbhVtTtjUIoSA'
    }
  },
  recaptchaSiteKey: '6LcyYowUAAAAAMPLoKwuPNBA3VhdCXp34M6tuJIk',
  ipinfoKey: '21b0c58edc4e60',
  oneSignalId: 'e5eb463e-11ff-4225-ad1c-5ee7cfc43bc6',
  mapsApiKey: 'AIzaSyCZmwWYrjkr79XmJxPfiiskM3B-k7TMBuA'
};

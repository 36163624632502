import { AfterViewInit, Directive, DoCheck, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appOffsetFromParentBottom]'
})
export class OffsetFromParentBottomDirective implements AfterViewInit, DoCheck  {

  private _elementRef: any;
  private _sourceElement: any;

  @Input() offset = 0;
  @Input() sourceElementId = '';

  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef.nativeElement;
  }

  ngAfterViewInit() {
   this.updatePosition();
  }

  ngDoCheck() {
    this.updatePosition();
  }

  private updatePosition() {
    if (this.sourceElementId) {
      this._sourceElement = document.querySelector(`#${this.sourceElementId}`);

      const sourceElementOffsetTop = this._sourceElement.offsetTop;
      const sourceElementHeight = parseFloat(window.getComputedStyle(this._sourceElement).height);
      const sourceElementBottom = sourceElementOffsetTop + sourceElementHeight;

      this._elementRef.style.top = `${sourceElementBottom - this.offset}px`;
    }
  }
}

export {};

declare global {
  interface Date {
    convertToISOStringWithTimezoneOffset(sourceDate: any): string;
    toISOStringWithTimezoneOffset(): string;
  }
}

Date.prototype.convertToISOStringWithTimezoneOffset = function (sourceDate) {
  if (!sourceDate) {
    return null;
  }
  return new Date(new Date(sourceDate)).toISOString();
};

Date.prototype.toISOStringWithTimezoneOffset = function () {
  return new Date(new Date()).toISOString();
};

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SubscriptionService } from '../../api/subscription.service';
import {LocalStorageService} from 'ngx-webstorage';
import Faq from './plan-list.faq';
import {Router} from '@angular/router';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.scss']
})
export class PlanListComponent implements OnInit {

  @Output() private onComplete: EventEmitter<any> = new EventEmitter();
  currentPlan: any;
  planList: any[];
  newSubscription = true;
  faq: any;

  constructor(private router: Router,
              private subscriptionService: SubscriptionService,
              private localStorage: LocalStorageService) {}

  ngOnInit() {
    // this.checkSubscription();
    if (this.localStorage.retrieve('plan')) {
      this.router.navigate(['/register/payment-details']);
    } else {
      this.getPlans();
      this.faq = Faq;
    }
  }

  checkSubscription() {
    this.subscriptionService
      .currentSubscription()
      .subscribe((res: any) => {
        if ('active' === res.status) {
          this.currentPlan = false;
        }
      });
  }

  public upgrade(plan) {
      const buf = Object.create({});
      buf.yearly_plan = plan.metadata.yearly_plan;
      buf.monthly_plan = plan.metadata.monthly_plan;
      buf.name = plan.name;
      buf.desc = plan.metadata.plan.subheading;
      this.localStorage.store('plan', buf);
      this.onComplete.emit();
  }

  getPlans () {
    this.subscriptionService.getSubscriptionPlans()
        .subscribe((data) => {
          this.planList = data;
          this.sortPlans(this.planList);
          this.parseMetadata(this.planList);
          this.planList = this.filterPlans(this.planList);
          this.transformFeaturesToArray(this.planList);
        });
    this.subscriptionService.currentSubscription()
        .subscribe(data => {
            if (data) {
                if (data.length === 0) {
                    this.newSubscription = true;
                    this.currentPlan = '';
                } else {
                    this.newSubscription = false;
                    this.currentPlan = data.plan.stripe_id;
                }
            }
        });
  }


  private sortPlans (plans) {
      plans.sort(function (a, b) {
         return a.id < b.id ? -1 : 1;
      });
  }

  private parseMetadata (plans) {
      for (let i = 0; i < plans.length; i++) {
          let buf = plans[i].metadata;
          buf = buf.replace(/'/g, '"');
          buf = buf.replace(/"{/g, '{');
          buf = buf.replace(/}"/g, '}');
          buf = buf.replace(/"\[/g, '[');
          buf = buf.replace(/\]"/g, ']');
          buf = buf.replace(/[a-z]"[a-z]/g, '\'');
          plans[i].metadata = JSON.parse(buf);
      }
  }

  private filterPlans (plans) {
      const result = [];
      for (let i = 0; i < plans.length; i++) {
          if (!plans[i].metadata.active || (plans[i].metadata.active === 'false')) {
              continue;
          }
          result.push(plans[i]);
      }
      return result;
  }

  private transformFeaturesToArray (plans) {
      for (let i = 0; i < plans.length; i++) {
          plans[i].metadata.featuresArr = [];
          for (let j = 1; j <= parseInt(plans[i].metadata.features); j++) {
              plans[i].metadata.featuresArr.push(plans[i].metadata['feature_' + j]);
          }
      }
  }

  public splitString (value, spliter, index, adSpliter): string {
      const result = value.split(spliter)[index];
      if (!result) {
          return '';
      }
      return  result + (adSpliter ? spliter : '');
  }

  public isCurrentPlan (plan) {
      if (plan.metadata.yearly_plan.id === this.currentPlan || plan.metadata.monthly_plan.id === this.currentPlan) {
          return true;
      }
      return false;
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseComponent } from '../../../core/base/base-component';
import { environment } from '../../../../../environments/environment';
import { DialogService } from '../../../shared/dialog/dialog.service';
import { FacebookDialogComponent } from './facebook-dialog/facebook-dialog.component';

declare const FB: any;

export interface IFBSocialUser {
  id: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  picture?: string;
  accessToken: string;
}

@Injectable()
export class FacebookService extends BaseComponent {

  serviceMode: string;
  socialUser: IFBSocialUser;
  socialSub = new Subject();
  socialSub$ = this.socialSub.asObservable();

  constructor(private dialogService: DialogService) {
    super();
    const timerId = setInterval(() => {
      if (window['FB']) {
        this.initFacebook();
        clearInterval(timerId);
      }
    }, 500);
  }

  initFacebook(): void {
    FB.init({
      appId            : environment.fbAppId,
      autoLogAppEvents : true,
      xfbml            : false,
      version          : 'v3.0',
      status           : true
    });

    FB['getLoginStatus']((res: { authResponse: any, status: string }) => {
      if (res.authResponse) {
        this.socialUser = {
          id: res.authResponse.userID,
          accessToken: res.authResponse.accessToken,
        };
      }
    })
  }

  private handleReRequest(serviceMode: string): void {
    this.subs = this.dialogService.custom(FacebookDialogComponent, { serviceMode, fb: this }).subscribe();
  }

  login(serviceMode: string): void {
    this.serviceMode = serviceMode;
    FB.login((res: { authResponse: any, status: string }) => {
      switch (true) {
        case res.status === 'connected' && res.authResponse.grantedScopes.indexOf('email') === -1:
          this.handleReRequest(this.serviceMode);
          break;
        case res.status === 'connected' && res.authResponse.grantedScopes.indexOf('email') !== -1:
          this.getAndStoreUserData(res.authResponse.accessToken);
          break;
      }
    }, { scope: 'email,public_profile', auth_type: 'rerequest', return_scopes: true })
  }

  private getPicture(socialUser): string {
    return socialUser.picture.data && socialUser.picture.data.url ? socialUser.picture.data.url : '';
  }

  private getAndStoreUserData(token: string): void {
    const requiredFields = { fields: 'id,first_name,last_name,picture,email' };

    FB['api']('/me', requiredFields, (socialUser) => {

      if (!socialUser.email) {
        return this.handleReRequest(this.serviceMode + '_NO_EMAIL');
      }

      this.socialUser = {
        id: socialUser.id,
        first_name: socialUser.first_name,
        last_name: socialUser.last_name,
        email: socialUser.email,
        picture: this.getPicture(socialUser),
        accessToken: token
      };

      this.socialSub.next(this.socialUser);
    })
  }
}

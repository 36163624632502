import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isDate } from 'date-fns';
import { MatDialogRef  } from '@angular/material';

import { switchMap, debounceTime, distinctUntilChanged, map, concat } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { Activity } from '../../models/activity.model';
import { ActivityService } from '../../../api/activity.service';
import { Contact } from '../../../contacts/models/contact.model';
import { ContactService, AutocompleteContact } from '../../../api/contact.service';
import { DialogOption } from '../../../shared/dialog-option-card/dialog-option-card.component';
import { FormChoicesService, FormChoiceType } from '../../../core/services/form-helpers/form-choises.service';
import { FormDatesService } from '../../../core/services/form-helpers/form-dates.service';
import { Opportunity } from '../../../opportunities/opportunity-list/opportunity';
import { OpportunityService } from '../../../api/opportunity.service';
import { ValidationService } from '../../../shared/validation/validation.service';
import { RouteBoundDialog } from '../../../core/route-bound-dialog/route-bound-dialog.class';
import { RouteBoundDialogService } from '../../../core/route-bound-dialog/route-bound-dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activity-form-dialog',
  templateUrl: './activity-form-dialog.component.html',
  styleUrls: ['./activity-form-dialog.component.scss']
})
export class ActivityFormDialogComponent extends RouteBoundDialog implements OnInit {

  activity: Activity;
  activityType: DialogOption;
  contactsAreLoading = false;
  contactOptions: AutocompleteContact[] = [];
  fieldEditable: any = {
    contact: true,
    opportunity: true
  }
  filteredContactOptions: Observable<AutocompleteContact[]>;
  form: FormGroup;
  isLoading: boolean;
  opportunitiesAreLoading = false;
  opportunitiesOptions: Opportunity[] = [];
  prioritiesOptions: FormChoiceType[] = [];
  statusesOptions: FormChoiceType[] = [];

  constructor(
    private activityService: ActivityService,
    private contactService: ContactService,
    public dialogRef: MatDialogRef<ActivityFormDialogComponent>,
    private formBuilder: FormBuilder,
    private formChoicesService: FormChoicesService,
    private formDatesService: FormDatesService,
    private opportunityService: OpportunityService,
    public routeBoundDialogService: RouteBoundDialogService,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.initState();
    this.defineStatusesOptions(new Date (this.activity.from_datetime));
    this.getContactFieldOptions(this.activity.contact || '');
    this.prepareActivityToEdit();
    this.initActivityForm();
  }

  private initState(): void {
    this.prioritiesOptions = this.formChoicesService.ACTIVITY_PRIORITY_TYPES;

    if (this.router.url.indexOf('/opportunities/details/') !== -1) {
      this.fieldEditable.contact = false;
      this.fieldEditable.opportunity = false;
    }

    if (this.router.url.indexOf('/contacts/') !== -1) {
      this.fieldEditable.contact = false;
      this.fieldEditable.opportunity = true;
    }

    if (this.activity.contact && this.activity.contact.slug) {
      this.getOpportunitiesByContact(this.activity.contact.slug);
    }
  }

  getOpportunityName(slug: string): string {
    if (this.opportunitiesOptions.length) {
      const result = this.opportunitiesOptions.filter(item => item.slug === slug);
      if (result.length) {
        return result[0].name;
      }
    }
  }

  /**
   * Clear value for contact form control on blur event
   * if contact wasn't chosen from list
   */
  contactControlBlur(): void {
    if (!this.form.value.contact || !this.form.value.contact['slug']) {
      this.form.controls.contact.setValue('');
    }
  }

  getContactFieldOptions(contact: any): void {
    if (!contact || !contact.slug) {
      this.contactsAreLoading = true;
      this.contactService.getAutocompleteContacts(contact)
        .subscribe(contacts => {
          this.contactOptions = contacts;
          this.filteredContactOptions = of(this.contactOptions);
        }, () => {},
        () => this.contactsAreLoading = false
      );
    }
  }

  /**
   * Search contacts and update Opportunity control state
   * on Contact controls changes
   */
  createContactChangeHandler(): void {
    this.form.controls.contact.valueChanges
    .pipe(
      map(value => {
        // Clear and disable opportunity control
        this.form.controls.opportunity.reset();
        this.form.controls.opportunity.disable();

        return value;
      }),
      debounceTime(400), // Pause for 400ms
      concat(),
      distinctUntilChanged()) // Only if the value has changed
    .subscribe((value: string & AutocompleteContact) => {
      if (value && value.slug) {
        // Get opportunities by contact and enable opportuniyty control
        this.getOpportunitiesByContact(value.slug);
        this.form.controls.opportunity.enable();
      } else {
        if (typeof value !== 'string') {
          value = '' as any;
        }
        this.getContactFieldOptions(value);
      }
    });
  }

  createFromDatetimeChangeHandler(): void {
    this.form.controls.from_datetime.valueChanges.subscribe(value => {
      if (isDate(value)) {
        const fromDateTime: Date = new Date(value);
        this.defineStatusesOptions(fromDateTime);
        this.autoPopulateToDatetime(fromDateTime);
      }
    });
  }

  // Filter statuses on from date start
  defineStatusesOptions(fromDateTime: Date): void {
    if (fromDateTime && (fromDateTime > new Date())) {
      this.statusesOptions = this.formChoicesService.ACTIVITY_STATUSES_TYPES.filter(item => item.id !== 'completed');
      if (this.form) {
        this.form.controls.status.reset();
      }
    } else {
      this.statusesOptions = this.formChoicesService.ACTIVITY_STATUSES_TYPES;
    }
  }

  /**
   * Function is needed for autocomplete showing name from object vaue
   */
  getContactDisplayFn(): Function {
    return (val): string => {
      return val ? val.full_name : '';
    };
  }

  /**
   * Retrieve opportunities by selected contact
   * @param contactId - slug (id) of selected contact in autocomplete
   */
  getOpportunitiesByContact(contactId: string): void {
    this.opportunitiesAreLoading = true;
    this.subs = this.opportunityService.getOpportuntitiesByContact(contactId, 'open')
      .subscribe((opportunities: Opportunity[]) => {
        this.opportunitiesOptions = opportunities;
      }, () => {},
      () => this.opportunitiesAreLoading = false
    );
  }

  /**
   * Reassign activity contact value
   * Get opportunity list by contact
   */
  prepareActivityToEdit(): void {
    if (this.activity && this.activity.contact && this.activity.contact.financial_information) {
      // Contact control
      const contactSlug = this.activity.contact.slug;
      this.activity.contact = this.activity.contact.financial_information as any;
      this.activity.contact.slug = contactSlug;

      if (this.activity.opportunity) {
        // Opportunity control
        this.getOpportunitiesByContact(this.activity.contact.slug);

        if (this.activity.opportunity && this.activity.opportunity.slug) {
          return this.activity.opportunity = this.activity.opportunity.slug as any;
        }
      }
    }
  }

  /**
   * Update some fields and remove null fields (required by API)
   * @param formValue - Activity data object from form group
   */
  prepareActivityBody(formValue: any): Activity {
    const body = JSON.parse(JSON.stringify(formValue));

    body.contact = formValue.contact ? formValue.contact.slug : this.activity.contact.slug;

    return body;
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    this.form['submitted'] = true;

    if (!this.form.pristine && this.form.valid && !this.isLoading) {
      this.isLoading = true;
      const body = this.prepareActivityBody(this.form.value);

      of(this.activity.slug).pipe(
        switchMap(slug => {
          if (slug) {
            return this.activityService.updateActivity(slug, body)
          } else {
            return this.activityService.createActivity(body)
          }
        })
      )
        .subscribe((activity: Activity) => {
          this.dialogRef.close( { activity } );
        }, () => {},
        () => this.isLoading = false
      );
    }
  }

  /**
   * Store activity form in service and open new contact/opportunity form dialog
   * @param event - click event
   */
  openReferredAddDialog(event: Event, fieldname: string): void {
    event.stopPropagation();
    this.activityService.activityForm = this.form; // store current activity form
    const closeData: any = { notSubmittedActivity: this.activity };

    switch (fieldname) {
      case 'contact' : closeData.contact = new Contact(); break;
      case 'opportunity' :
        closeData.opportunity = new Opportunity();
        closeData.opportunity.contact = this.form.value.contact;
        break;
    }

    this.dialogRef.close(closeData);
  }

  /**
   * Apply created earlier form and stored in service
   * Commonly used if dialog was closed to open referred new Contact/Opportunity dialog form
   */
  private applyStoredForm(): void {
    // use form stored in service if dialog was closed temporary
    this.form = this.activityService.activityForm;
    this.activityService.activityForm = null;

    if (this.activity.contact) {
      this.form.controls.contact.setValue(this.activity.contact);
    }

    if (this.form.value.contact) {
      this.getOpportunitiesByContact(this.form.value.contact.slug);
    }

    if (this.activity.opportunity) {
      this.form.controls.opportunity.setValue(this.activity.opportunity);
    } else {
      this.activity.opportunity = null;
    }
  }

  private autoPopulateToDatetime(fromDateTime: Date): void {
    if (!this.form.value.from_datetime && !this.form.value.to_datetime) {
      const toDateTime = new Date(fromDateTime.setHours(fromDateTime.getHours() + 1));
      this.form.controls.to_datetime.setValue(toDateTime);
    }
  }

  private buildActivityForm(): void {
    this.form = this.formBuilder.group({
      activity_type: [this.activityType.name, [Validators.required]],
      contact: [this.activity.contact, [Validators.required]],
      opportunity: [{ value: this.activity.opportunity, disabled: !this.activity.contact }],
      subject: [this.activity.subject, [Validators.required]],
      from_datetime: [this.activity.from_datetime,
        this.activityType.name === 'appointment' || this.activityType.name === 'call' ?
        [ Validators.required, ValidationService.dateTimeTypeValidator ] :
        [ ValidationService.dateTimeTypeValidator ]
      ],
      to_datetime: [this.activity.to_datetime,
        this.activityType.name === 'task' ?
        [ Validators.required, ValidationService.dateTimeTypeValidator ] :
        [ ValidationService.dateTimeTypeValidator ]
      ],
      location: [this.activity.location],
      priority: [this.activity.priority,
        this.activityType.name === 'task' ? [Validators.required] : []
      ],
      status: [this.activity.status ? this.activity.status : 'pending', [Validators.required]],
      notes: [this.activity.notes]
    });
  }

  private initActivityForm(): void {
    if (this.activityService.activityForm) {
      this.applyStoredForm();
    } else {
      this.buildActivityForm();
    }

    this.form['submitted'] = false;
    this.createContactChangeHandler();
    this.createFromDatetimeChangeHandler();
  }
}

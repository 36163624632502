import { Component } from '@angular/core';
import { TableBaseItemComponent } from '../table/table-base-item.component';
import { DialogObserverService } from '../../core/services/dialog-observer/dialog-observer.service';

@Component({
  selector: 'app-list-card-opportunity',
  templateUrl: './list-card-opportunity.component.html',
  styleUrls: ['./list-card-opportunity.component.scss']
})
export class ListCardOpportunityComponent extends TableBaseItemComponent {

  type = 'opportunity';

  constructor(dialogObserver: DialogObserverService) {
    super(dialogObserver);
  }

}

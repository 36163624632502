import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

declare const Stripe;

@Injectable()
export class StripeService {

  private stripe = Stripe;

  constructor() {
    this.stripe.setPublishableKey(environment.stripePublicKey);
  }

  /**
   *
   * @param data
   * @returns {Observable<T>}
   */
  public createCardToken(data) {
    data['number'] = data['number'].replace(/\s/g, '');

    return new Promise((resolve, reject) => {
      this.stripe.card.createToken(data, (status, res) => {
        if (200 === status) {
          resolve(res.id);
        } else {
          reject(res.error);
        }
      });
    });
  }


}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'innerTruncate'
})
export class InnerTruncatePipe implements PipeTransform {

  transform(value: string, stringLength: number): string {
    let firstPartLength: number;
    let lastPartLength: number;

    if (value.length <= stringLength) {
      return value;
    }

    if (stringLength % 2) {
      firstPartLength = stringLength / 2 - 1;
      lastPartLength = stringLength / 2 - 2;
    } else {
      firstPartLength = (stringLength - 1) / 2 - 1;
      lastPartLength = (stringLength - 1) / 2 - 1;
    }

    const firstPart = value.substring(0, firstPartLength).trim();
    const lastPart = value.substring(value.length - lastPartLength, value.length).trim();
    return firstPart + '...' + lastPart;
  }
}

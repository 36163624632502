import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { DialogObserverService } from '../../core/services/dialog-observer/dialog-observer.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges {

  private sortDirection: string;
  private currentSortedTitle: string;

  @Input('page') page: string;
  @Input('activeTab') activeTab: string;
  @Input('headerTitles') headerTitles: [string];
  @Input('sortedTitles') sortedTitles: any;

  @Output() sortEvent: EventEmitter <any> = new EventEmitter();
  @Output() scrollEvent: EventEmitter <any> = new EventEmitter();

  constructor(
    private dialogObserver: DialogObserverService,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeTab && changes.activeTab.currentValue && !changes.activeTab.firstChange) {
      this.currentSortedTitle = '';
    }
  }

  onScrollDown() {
    this.scrollEvent.emit();
  }

  sortItems (event: Event, title: string): void {
    event.stopPropagation();
    let sortDirection: string;
    let resetSorting = false;

    if (this.isSortAvailavleForTitle(title)) {

      if (this.currentSortedTitle !== title) {
        this.sortDirection = 'asc';
        sortDirection = '';
      }

      if (this.currentSortedTitle === title) {
        switch (this.sortDirection) {
          case '':
            this.sortDirection = 'asc';
            sortDirection = '';
            break;
          case 'asc':
            this.sortDirection = 'desc';
            sortDirection = '-';
            break;
          case 'desc':
            this.sortDirection = '';
            sortDirection = '';
            resetSorting = true;
        }
      }
      this.currentSortedTitle = title;

      this.sortEvent.emit({
        title: resetSorting ? '' : this.sortedTitles[this.currentSortedTitle],
        direction: sortDirection
      });
    }
  }

  isSortAvailavleForTitle(title: string): boolean {
    return this.sortedTitles && this.sortedTitles.hasOwnProperty(title);
  }

  viewItem(event: Event, item: any): void {
    event.stopPropagation();
    this.dialogObserver.emit('view', item);
  }

  editItem(event: Event, item: any): void {
    event.stopPropagation();
    this.dialogObserver.emit('edit', item);
  }

  deleteItem(event: Event, item: any): void {
    event.stopPropagation();
    this.dialogObserver.emit('delete', item);
  }

}

import { Injectable } from '@angular/core';
import { FinancialInformation } from '../models/financial-information.model';
import { CurrencyLocalPipe } from '../../shared/pipes/currency-local/currency-local.pipe';

export class OtherFinanceRecord {
  text = '';
  value: string | number = '';
}
@Injectable()
export class ContactFinanceService {

  otherFieldsGroupKeys: string[] = [
    'other_savings',
    'other_loan',
    'other_annual_income',
    'other_annual_expenses'
  ];

  constructor(private currencyLocalPipe: CurrencyLocalPipe) {}

  parseCurrencyFieldValue(value: string): number {
    return this.currencyLocalPipe.getValue(value);
  }

  /**
   * Parse data got from API to Finance Form
   * @param financialInformation - fin info got from API
   */
  parseFinanceFormDataFromApi(financialInformation: FinancialInformation) {
    // Other Fields should be arrays from api is got objects
    this.otherFieldsGroupKeys.forEach(fieldKey => {
      const otherRecords = [];

      for (const key in financialInformation[fieldKey]) {
        if (financialInformation[fieldKey].hasOwnProperty(key)) {
          otherRecords.push({
            text: key,
            value: financialInformation[fieldKey][key]
          });
        }
      }
      otherRecords.push(new OtherFinanceRecord());

      financialInformation[fieldKey] = otherRecords;
    });
  }

  /**
   * Parse Finance Form for sending to API
   * @param financialInformation - fin info got from model drive form
   */
  parseFinanceFormDataToApiConformity(financialInformation: FinancialInformation) {
    // Basic fields should be number on api but strings on ui (currency formatter directive using)
    const basicFieldsKeys = [
      'fixed_deposits',
      'savings',
      'investments',
      'mortgage_loan',
      'motor_loan',
      'education_loan',
      'gross_annual_income',
      'personal_expenses',
      'household_expenses',
      'insurance_premiums',
      'regular_savings'
    ];

    basicFieldsKeys.forEach(key => {
      financialInformation[key] = this.parseCurrencyFieldValue(financialInformation[key] as string);
    });

    // Other Fields should be objects on api but arrays on ui (model drive forms)
    this.otherFieldsGroupKeys.forEach(key => {
      const otherRecords = {};

      if (financialInformation[key].length) {
        financialInformation[key].forEach(record => {
          if (record.text) {
            otherRecords[record.text] = record.value;
          }
        });

        financialInformation[key] = otherRecords;
      }
    });
  }

  sumTotalAssets(formModel): number {
    return this.parseCurrencyFieldValue(formModel.savings) +
      this.parseCurrencyFieldValue(formModel.fixed_deposits) +
      this.parseCurrencyFieldValue(formModel.investments) +
      this.sumTotalOtherRecords(formModel.other_savings);
  }

  sumTotalLiabilities(formModel): number {
    return this.parseCurrencyFieldValue(formModel.mortgage_loan) +
      this.parseCurrencyFieldValue(formModel.motor_loan) +
      this.parseCurrencyFieldValue(formModel.education_loan) +
      this.sumTotalOtherRecords(formModel.other_loan);
  }

  sumTotalAnnualIncome(formModel): number {
    return this.parseCurrencyFieldValue(formModel.gross_annual_income) +
      this.sumTotalOtherRecords(formModel.other_annual_income);
  }

  sumTotalAnnualExpenses(formModel): number {
    return this.parseCurrencyFieldValue(formModel.personal_expenses) +
      this.parseCurrencyFieldValue(formModel.household_expenses) +
      this.parseCurrencyFieldValue(formModel.insurance_premiums) +
      this.parseCurrencyFieldValue(formModel.regular_savings) +
      this.sumTotalOtherRecords(formModel.other_annual_expenses);
  }

  sumTotalOtherRecords(records: OtherFinanceRecord[]): number {
    return records
      .map(record => this.parseCurrencyFieldValue(record.value as string))
      .reduce((a, b) => a + b, 0);
  }
}

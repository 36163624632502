import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-avatar',
  templateUrl: './card-avatar.component.html',
  styleUrls: ['./card-avatar.component.scss']
})
export class CardAvatarComponent implements OnInit {
  @Input()
  set info(data: any) {
    if (data) {
      this._info = JSON.parse(JSON.stringify(data));
    }
    this.reInit();
  }
  get info(): any {
    return this._info;
  }
  @Input() name = '';

  initials = '';
  hasPicture = false;

  private _info: any;

  constructor() {
  }

  ngOnInit() { }

  reInit(): void {
    if (this.info && this.info['full_name']) {
      const matches = this.info['full_name'].match(/\b(\w)/g);
      this.initials = matches ? matches.join('') : '';
      this.info.picture = this.getPicture();
      this.hasPicture = this.info.picture;
    }

    if (this.name) {
      const matches = this.name.match(/\b(\w)/g);
      this.initials = matches ? matches.join('') : '';
    }
  }

  getPicture(): string | null {
    if (this.info && typeof this.info.picture === 'string') {
      return this.info.picture;
    }

    if (this.info && this.info.picture) {
      return this.info.picture.square;
    }

    return null;
  }
}

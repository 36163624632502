import { Component } from '@angular/core';
import { BaseComponent } from '../../core/base/base-component';

@Component({
  selector: 'base-component',
  template: '<span>base-component</span>'
})
export class BaseDynamicComponent extends BaseComponent {
  constructor() {
    super();
  }
}

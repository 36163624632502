import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services';
import { Router } from '@angular/router';

@Component({
  selector: 'auth-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	isSignoutVisible: boolean = true;

  constructor(
  	private authService: AuthService,
  	private router: Router
  ) { }

  ngOnInit() {
  	if (this.router.url.includes('/auth')) {
  		this.isSignoutVisible = false;
  	}
  }

  signOut() {
    this.authService.logout();
  }

}

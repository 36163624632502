import { _t } from 'app/modules/core/other/translate-marker';

export interface IFacebookDialogConfig {
  header: string;
  description: string;
  leftButtonText: string;
  rightButtonText: string;
  rightButtonValue: boolean;
}

export class FacebookDialogConfig {
  public static get SIGN_IN(): IFacebookDialogConfig {
    return {
      header: _t('security.signIn'),
      description: _t('security.needYourEmail'),
      leftButtonText: _t('security.signinWithEmail'),
      rightButtonText: _t('security.addEmail'),
      rightButtonValue: true
    }
  }

  public static get SIGN_IN_NO_EMAIL(): IFacebookDialogConfig {
    return {
      header: _t('security.signIn'),
      description: _t('security.weFrom'),
      leftButtonText: _t('common.cancel'),
      rightButtonText: _t('security.signinWithEmail'),
      rightButtonValue: false
    }
  }

  public static get SIGN_UP(): IFacebookDialogConfig {
    return {
      header: _t('security.signUp'),
      description: _t('security.weNeedEmail'),
      leftButtonText: _t('security.signupWithEmail'),
      rightButtonText: _t('security.addEmail'),
      rightButtonValue: true
    }
  }

  public static get SIGN_UP_NO_EMAIL(): IFacebookDialogConfig {
    return {
      header: _t('security.signUp'),
      description: _t('security.weGetYouEmail'),
      leftButtonText: _t('common.cancel'),
      rightButtonText: _t('security.signupWithEmail'),
      rightButtonValue: false
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef  } from '@angular/material';
import { Activity } from '../../models/activity.model';
import { DatePipe } from '@angular/common';
import { DialogOption } from '../../../shared/dialog-option-card/dialog-option-card.component';
import { FormChoicesService } from '../../../core/services/form-helpers/form-choises.service';
import { FormDatesService } from '../../../core/services/form-helpers/form-dates.service';
import { ViewFieldData } from '../../../core/models/view-field-data.model';
import { OpportunityService } from '../../../api/opportunity.service';
import { BaseComponent } from '../../../core/base/base-component';
import { RouteBoundDialog } from '../../../core/route-bound-dialog/route-bound-dialog.class';
import { RouteBoundDialogService } from '../../../core/route-bound-dialog/route-bound-dialog.service';
import { _t } from 'app/modules/core/other/translate-marker';

class ActivityViewFieldData extends ViewFieldData {
  image: string;
  link: string;

  constructor(label: string, value: string, image?: string, link?: string) {
    super(label, value);
    this.image = image;
    this.link = link;
  }
}

@Component({
  selector: 'app-activity-view-dialog',
  templateUrl: './activity-view-dialog.component.html',
  styleUrls: ['./activity-view-dialog.component.scss'],
  providers: [FormDatesService, OpportunityService]
})
export class ActivityViewDialogComponent extends RouteBoundDialog implements OnInit {

  activity: Activity;
  activityType: DialogOption;
  activityViewRowsData: ActivityViewFieldData[][] = [];

  constructor(
    public dialogRef: MatDialogRef<ActivityViewDialogComponent>,
    private datePipe: DatePipe,
    private formChoicesService: FormChoicesService,
    private formDatesService: FormDatesService,
    private opportunityService: OpportunityService,
    public routeBoundDialogService: RouteBoundDialogService
  ) {
    super();
  }

  ngOnInit() {
    this.prepareActivityViewRowsData();
    this.retrieveOpportunity();
  }

  /*
  * Retrieve opportunuty for activity
  */
  retrieveOpportunity(): void {
    const opportunity = this.activity.opportunity;

    if (opportunity && typeof opportunity === 'string') {
      this.subs = this.opportunityService.getOpportunity(opportunity)
        .subscribe(data => {
          this.activity.opportunity = data;
          this.prepareActivityViewRowsData();
        })
    }
  }

  /**
   * Prepare data for *ngFor directive in view
   */
  prepareActivityViewRowsData(): void {
    this.activityViewRowsData = [
      [ new ActivityViewFieldData(
        _t('common.activities.contact'),
        this.activity.contact.financial_information.full_name,
        this.activity.contact.financial_information.picture,
        `/contacts/${this.activity.contact.slug}`
      ) ],
      [ new ActivityViewFieldData(
        _t('common.opportunity'),
        this.activity.opportunity ? this.activity.opportunity.name : '',
        null,
        this.activity.opportunity ? `/opportunities/details/${this.activity.opportunity.slug}` : ''
      ) ],
      [ new ActivityViewFieldData('Subject', this.activity.subject) ],
      [new ActivityViewFieldData(
        _t('common.activities.startDateTime'), this.datePipe.transform(this.activity.from_datetime, 'd MMMM y - h:mm a')
      )],
      [new ActivityViewFieldData(
        _t('common.activities.endDateTime'), this.datePipe.transform(this.activity.to_datetime, 'd MMMM y - h:mm a')
      )],
    ];

    // Add specific fields
    switch (this.activityType.name) {
      case 'appointment':
        this.activityViewRowsData.push([new ActivityViewFieldData(
          _t('common.activities.where'), this.activity.location
        )]);
        break;

      case 'task':
        this.activityViewRowsData.push([new ActivityViewFieldData(
          _t('contacts.priorities.priority'),
          this.formChoicesService.getFormChoiceValue(
            this.activity.priority, this.formChoicesService.ACTIVITY_PRIORITY_TYPES
          )
        )]);
        break;
      }

      // Add rest common fields
      this.activityViewRowsData = this.activityViewRowsData.concat([
        [ new ActivityViewFieldData(_t('common.activities.status'),
          this.formChoicesService.getFormChoiceValue(
            this.activity.status, this.formChoicesService.ACTIVITY_STATUSES_TYPES)
        ) ],
        [ new ActivityViewFieldData(_t('contacts.personal.notes'), this.activity.notes) ]
      ]);
  }

}

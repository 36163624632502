import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, concat, distinctUntilChanged } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AutocompleteContact, ContactService } from '../../api/contact.service';
import { Contact } from '../../contacts/models/contact.model';
import { FormChoicesService, FormChoiceType } from '../../core/services/form-helpers/form-choises.service';
import { FormDatesService } from '../../core/services/form-helpers/form-dates.service';
import { Opportunity } from '../opportunity-list/opportunity';
import { OpportunityService } from '../../api/opportunity.service';
import { RouteBoundDialogService } from '../../core/route-bound-dialog/route-bound-dialog.service';
import { RouteBoundDialog } from '../../core/route-bound-dialog/route-bound-dialog.class';
import { ValidationService } from '../../shared/validation/validation.service';
import { CurrencyLocalPipe } from '../../shared/pipes/currency-local/currency-local.pipe';
import { _t } from 'app/modules/core/other/translate-marker';

@Component({
  templateUrl: './dialog-opportunity.component.html',
  styleUrls: ['./dialog-opportunity.component.scss']
})
export class DialogOpportunityComponent extends RouteBoundDialog implements OnInit {

  action: string;
  contactOptions: AutocompleteContact[] = [];
  contactsAreLoading = false;
  filteredContactOptions: Observable<AutocompleteContact[]>;
  fieldEditable: any = {
    contact: true,
  }
  form: FormGroup;
  isLoading: boolean;
  opportunityStatuses: FormChoiceType[] = [];

  constructor(
    private currencyLocalPipe: CurrencyLocalPipe,
    private contactService: ContactService,
    private formBuilder: FormBuilder,
    private formDatesService: FormDatesService,
    private opportunityService: OpportunityService,
    private formChoicesService: FormChoicesService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<DialogOpportunityComponent>,
    @Inject(MAT_DIALOG_DATA) public opportunity: Opportunity,
    public routeBoundDialogService: RouteBoundDialogService
  ) {
    super();
  }

  get closingDatetimePlaceholder(): string {
    return this.translateService.instant('contacts.opportunities.closingDate') + (this.closingDatetimeIsRequired(this.form.value.status) ? ' *' : '')
  }

  ngOnInit() {
    this.disableContactField();
    this.getContactFieldOptions(this.opportunity.contact || '');
    this.prepareDialog();
    this.opportunityStatuses = this.formChoicesService.OPPORTUNITY_STATUSES_TYPES;
    this.initOpporunityForm();
    this.contactHandler();
  }

  getContactFieldOptions(contact: any): void {
    if (!contact || !contact.slug) {
      this.contactsAreLoading = true;
      this.contactService.getAutocompleteContacts(contact)
        .subscribe(contacts => {
          this.contactOptions = contacts;
          this.filteredContactOptions = of(this.contactOptions);
        }, () => {},
        () => this.contactsAreLoading = false
      );
    }
  }

  prepareDialog(): void {
    if (!(this.opportunity && this.opportunity.slug)) {
      this.action = _t('common.add');
    } else {
      this.action = _t('common.edit');
    }

    if (hasContactWithSlugAndFinInfo(this.opportunity)) {
      this.opportunity.contact.full_name = this.opportunity.contact.financial_information.full_name;
    }

    function hasContactWithSlugAndFinInfo(opportunity: Opportunity): boolean {
      return opportunity &&
        opportunity.contact &&
        opportunity.contact.slug &&
        opportunity.contact.financial_information;
    }
  }

  /**
   * Search contacts and update Opportunity control state
   * on Contact controls changes
   */
  contactHandler(): void {
    this.form.controls.contact.valueChanges
      .pipe(
        debounceTime(400), // Pause for 400ms
        concat(),
        distinctUntilChanged() // Only if the value has changed
      )
      .subscribe((value: string & AutocompleteContact) => {
        if (typeof value !== 'string') {
          value = '' as any;
        }
        this.getContactFieldOptions(value);
      });
  }

  /**
   * Clear value for contact form control on blur event
   * if contact wasn't chosen from list
   */
  contactControlBlur(): void {
    if (!this.form.value.contact || !this.form.value.contact['slug']) {
      this.form.controls.contact.setValue('');
    }
  }

  /**
   * Function is needed for autocomplete showing name from object vaue
   */
  getContactDisplayFn(): Function {
    return (val): string => {
      return val ? val.full_name : '';
    };
  }

  onSubmit(event: Event): void {
    event.preventDefault();
    this.form['submitted'] = true;

    if (!this.form.pristine && this.form.valid && !this.isLoading) {
      this.isLoading = true;
      const body = this.prepareOpportunityBody(this.form.value);

      if (this.opportunity && this.opportunity.slug) {
        this.sendUpdateOpportunity(body);
      } else {
        this.sendCreateOpportunity(body);
      }
    }
  }

  /**
   * Update some fields and remove null fields (required by API)
   * @param formValue - Opportunity data object from form group
   */
  prepareOpportunityBody(formValue: any): Opportunity {
    const body = JSON.parse(JSON.stringify(formValue));

    body.contact = body.contact ? body.contact.slug : this.opportunity.contact.slug;
    body.amount = this.currencyLocalPipe.getValue(body.amount);
    body.commission = this.currencyLocalPipe.getValue(body.commission);
    body.closing_datetime = this.formDatesService.formatDateToInternational(body.closing_datetime, true);

    // Remove fields with null values (required by API)
    if (!this.opportunity.slug) {
      for (const key in body) {
        if (body.hasOwnProperty(key) && body[key] === null) {
          delete body[key];
        }
      }
    }
    return body;
  }

  /**
   * Handler for Opportunity creation and updating
   */
  onOpportunitySent(opportunity: Opportunity): void {
    this.dialogRef.close({
      opportunity: this.replaceSlugWithContact(opportunity)
    });
    this.form.reset();
    this.isLoading = false;
  }

  onSubmitError(): void {
    this.isLoading = false;
  }

  /**
   * Store opportunity form in service and open new contact form dialog
   * @param event - click event
   */
  openAddContactDialog(event: Event): void {
    event.stopPropagation();
    this.opportunityService.opportunityForm = this.form; // store current opportunity form
    this.dialogRef.close({
      notSubmittedOpportunity: this.opportunity,
      contact: new Contact()
    });
  }

  sendCreateOpportunity(body: Opportunity): void {
    this.opportunityService.createOpportunity(body)
      .subscribe((opportunity: Opportunity) => {
        this.onOpportunitySent(opportunity);
      }, err => this.onSubmitError());
  }

  sendUpdateOpportunity(body: Opportunity): void {
    this.opportunityService.updateOpportunity(this.opportunity.slug, body)
      .subscribe((opportunity: Opportunity) => {
        this.onOpportunitySent(opportunity);
      }, err => this.onSubmitError());
  }

  /**
   * Apply created earlier form and stored in service
   * Commonly used if dialog was closed to open referred new Contact dialog form
   */
  private applyStoredForm(): void {
    // use form stored in service if dialog was closed temporary
    this.form = this.opportunityService.opportunityForm;
    this.opportunityService.opportunityForm = null;
    if (this.opportunity.contact) {
      this.form.controls.contact.setValue(this.opportunity.contact);
    }
  }

  private buildOpporunityForm(): void {
    this.form = this.formBuilder.group({
      name: [this.opportunity.name || '', [Validators.required]],
      contact: [this.opportunity.contact || '', [Validators.required]],
      source: [this.opportunity.source || '', [Validators.required]],
      closing_datetime: [this.opportunity.closing_datetime || '', [ValidationService.dateTypeValidator]],
      amount: [this.opportunity.amount || 0, [
        Validators.required,
        ValidationService.positiveNumberValidator,
        ValidationService.currencyMaxValue
      ]],
      commission: [this.opportunity.commission || 0, [
        Validators.required,
        ValidationService.positiveNumberValidator,
        ValidationService.currencyMaxValue
      ]],
      probability: this.opportunity.probability || 50,
      status: [this.opportunity.status || 'new', [Validators.required]],
      notes: this.opportunity.notes || ''
    });
  }

  private closingDatetimeIsRequired(statusValue: string): boolean {
    return statusValue === 'submitted' || statusValue === 'incepted';
  }

  private disableContactField(): void {
    if (this.opportunity.slug) {
      this.fieldEditable.contact = false;
    }
  }

  private initOpporunityForm(): void {
    if (this.opportunityService.opportunityForm) {
      this.applyStoredForm();
    } else {
      this.buildOpporunityForm();
    }

    this.updateClosingDatetimeValidators(this.form.value.status);
    this.form.controls.status.valueChanges.subscribe(value => {
      this.updateClosingDatetimeValidators(value);
    })

    this.form['submitted'] = false;
  }

  private replaceSlugWithContact(opportunity: Opportunity): Opportunity {
    const contact = JSON.parse(JSON.stringify(this.form.value.contact));
    contact.slug = opportunity.contact;
    opportunity.contact = contact;
    return opportunity;
  }

  private updateClosingDatetimeValidators(statusValue: string): void {
    const validators: any[] = [ ValidationService.dateTypeValidator ];

    if (this.closingDatetimeIsRequired(statusValue)) {
      validators.push(Validators.required);
    }

    const control = this.form.controls.closing_datetime;
    control.setValidators(validators);
    control.updateValueAndValidity();
  }
}

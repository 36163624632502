import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
import 'hammerjs';

import { TranslateModule, TranslateLoader, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ToastrModule } from 'ngx-toastr';
import { MarkdownModule } from 'ngx-markdown';
import { TextMaskModule } from 'angular2-text-mask';

import { CoreModule } from './modules/core/core.module';
import { SharedModule } from './modules/shared/shared.module';
import { ApiModule } from './modules/api/api.module';
import { AppRoutes } from './app-routing.module';

import { AppComponent } from './app.component';
import { TermsConditionsComponent } from './components/terms-and-condition';
import { PrivacyPolicyComponent } from './components/privacy-policy';
import { UpgradePageComponent } from './components/upgrade-page/upgrade-page.component';

import { DYNAMIC_COMPONENTS } from './modules/marketplace/dynamic/dynamic-components';
import { DynamicComponentLoaderModule } from './modules/marketplace/dynamic-component-loader/dynamic-component-loader.module';
import { WindowRef } from './modules/core/services/window/window.service';

@NgModule({
  imports: [
    BrowserModule,
    MarkdownModule.forRoot(),
    RouterModule.forRoot(AppRoutes),
    BrowserAnimationsModule,

    CoreModule,
    SharedModule,
    ApiModule,

    TextMaskModule,
    ToastrModule.forRoot(),
    SharedModule.forRoot(),
    DynamicComponentLoaderModule.forRoot(DYNAMIC_COMPONENTS),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    AppComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    UpgradePageComponent,
  ],
  providers: [
    WindowRef
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

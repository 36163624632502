import { SharedModule } from './../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material';
import { FacebookDialogComponent } from './facebook/facebook-dialog/facebook-dialog.component';
import { FacebookService } from './facebook/facebook.service';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    SharedModule
  ],
  providers: [FacebookService],
  declarations: [FacebookDialogComponent],
  entryComponents: [FacebookDialogComponent]
})
export class SocialModule { }

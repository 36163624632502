import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter, map } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';

import { AuthService, AccountDetailsResponse } from './modules/auth/services/auth.service';
import { IntercomService } from './modules/shared/intercom/intercom.service';
import { LOCALIZATION_CONFIG } from './configs/localization.config';
import { LocalStorageLanguageKey } from './modules/api/language.interceptor';
import { TranslateService } from '@ngx-translate/core';
import { ViralLoopsService } from './modules/shared/viral-loops/services/viral-loops.service';
import { environment } from '../environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // TODO: uncomment when need to get Viral Loop back
  // Needed for loading script of Viral Loops
  // @ViewChild('wid?getContainer') widgetContainer: ElementRef;
  // scriptElement: HTMLScriptElement;

  public version = environment.VERSION;
  public locationPath: string;
  isMenuToggled = false;
  isPublicPage = false;
  isAuthOnboardingPage = true;
  is6fpRegistrationPage = false;
  isProfileBuilderPage = false;
  isAnalyticsPage = false;

  constructor(
    private _router: Router,
    private cdref: ChangeDetectorRef,
    private intercom: IntercomService,
    private storage: LocalStorageService,
    private translate: TranslateService,
    public authService: AuthService,
    public location: Location,
    private viralLoopsService: ViralLoopsService,
  ) {
    this.initLanguageChangeSubscription();
    this.location = location;
    this.bootIntercom();
    this.initializeOneSignal();
    // TODO: uncomment when need to get Viral Loop back
    // this.viralLoopsService.init(environment.viralLoops);
  }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];

    this._router.events.subscribe((event: Event) => {
      if (this.locationPath !== this.location.path()) {
        this.locationPath = this.location.path();
        this.is6fpRegistrationPage = this.locationPath.includes('/onboarding/') || this.locationPath.includes('/6fp-registration/');
        this.isAuthOnboardingPage = this.locationPath.includes('/auth/') || this.locationPath.includes('/onboarding/') || this.locationPath.includes('/6fp-registration/') || this.locationPath.includes('/profile-onboarding/');
        this.isProfileBuilderPage = this.locationPath.includes('/profile/builder/');
        this.isAnalyticsPage = this.locationPath.includes('/profile/builder/settings');
        this.isPublicPage =
          (this.locationPath.indexOf('/profile/') === 0 &&
            this.locationPath.indexOf('/profile/builder') !== 0) ||
          this.locationPath.indexOf('/register') === 0 ||
          this.locationPath.indexOf('/privacy-policy') === 0 ||
          this.locationPath.indexOf('/terms-and-conditions') === 0 ||
          this.locationPath.indexOf('/auth/forgot-password') === 0 ||
          this.locationPath.indexOf('/auth/password/reset') === 0 ||
          this.locationPath.indexOf('/auth/password/activate') === 0 ||
          this.locationPath.indexOf('/financial-freedom-calculator') === 0 ||
          this.locationPath.includes('/upgrade');
      }

      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);

        // google analytics code
        gtag('config', 'UA-99118108-4',
          {
            'page_path': event.urlAfterRedirects
          }
        );
        gtag('set', {'user_id': this.getSlug() }); // Set the user ID using signed-in user_id.
      }
    });

    // TODO: uncomment when need to get Viral Loop back
    // this.scriptElement = this.viralLoopsService.getAltruisticScript();
    // this.widgetContainer.nativeElement.appendChild(this.scriptElement);
  }

  // Note: disabled for iOS testing
  initializeOneSignal() {
    const OneSignal = window['OneSignal'] || [];
    OneSignal.push(function() {
      OneSignal.init({
        appId: environment.oneSignalId,
      });
    });
  }

  bootIntercom(): void {
    const userData = this.authService.retrieveStoredUserData();
    if (userData && userData.email) {
      this.intercom.boot(userData.email);
    }
  }

  toggleLeftMenu(event) {
    this.isMenuToggled = event;
    this.cdref.detectChanges();
  }

  public getSlug(): string {
    if (this.isLoggedIn) {
      return this.authService.returnSlug();
    }
  }

  public get isLoggedIn(): boolean {
    return this.authService.isLoggedIn();
  }

  private initLanguageChangeSubscription(): void {
    const defaultLanguage = LOCALIZATION_CONFIG.SUPPORTED_LANGUAGES.find(l => l.isDefault);

    if (!this.storage.retrieve(LocalStorageLanguageKey)) {
      this.storage.store(LocalStorageLanguageKey, defaultLanguage.code);
    }

    const languageCode = this.storage.retrieve(LocalStorageLanguageKey) || defaultLanguage.code;

    this.translate.setDefaultLang(languageCode);
    this.translate.use(languageCode);

    this.authService.accountDetailsBehaviorSubject.pipe(
      filter(Boolean),
      map((account: AccountDetailsResponse) => account.language),
      filter(Boolean),
    )
      .subscribe((language: string) => {
        this.translate.use(language);
        this.storage.store(LocalStorageLanguageKey, language);
      });
  }
}

import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../core/base/base-component';
import { EventHandlerService } from '../../../core/services/event-handler';
import { SearchFiltersObserverService } from '../search-filters-observer.service';
import { SearchFilters, FilterTag } from '../filters.model';
import { CurrencyLocalPipe } from '../../pipes/currency-local/currency-local.pipe';
import { FilterGroupFormDialogService } from './form-dialog-filter-group/filter-group-form-dialog.service';

@Component({
	selector: 'app-segment-filters',
	templateUrl: './segment-filters.component.html',
	styleUrls: ['./segment-filters.component.scss'],
	providers: [FilterGroupFormDialogService]
})
export class SegmentFiltersComponent extends BaseComponent implements OnInit {

	private searchEmitter = EventHandlerService.get('search_emitter');

	isFiltersActive: boolean = false;
	filtersApplied: SearchFilters = new SearchFilters();
	filterTags: Array<FilterTag> = [];

	constructor(
		private filtersObserver: SearchFiltersObserverService,
		private currencyLocalPipe: CurrencyLocalPipe,
		private filterGroupFormDialogService: FilterGroupFormDialogService
	) {
		super();
	}

	ngOnInit() {
		this.subscribeFilterChanges();
	}

	subscribeFilterChanges(): void {
		this.subs = this.searchEmitter.subscribe(params => {

			if (params.advanced_filters && params.advanced_filters.isFilterApplied) {
				this.isFiltersActive = true;
				this.filtersApplied = Object.assign({}, params.advanced_filters);
				this.generateFilterTags();
			} else {
				this.isFiltersActive = false;
			}
		});
	}

	generateFilterTags() {
		this.filterTags = [];

		// contact type
		let contactFilter: FilterTag = {
			filterCode: 'contact_type',
			displayValue: '',
			valueCodes: []
		};
		if (this.filtersApplied.contact_type.client) {
			contactFilter.displayValue += 'Client, ';
			contactFilter.valueCodes.push('client');
		}
		if (this.filtersApplied.contact_type.lead) {
			contactFilter.displayValue += 'Lead, ';
			contactFilter.valueCodes.push('lead');
		}
		if (this.filtersApplied.contact_type.prospect) {
			contactFilter.displayValue += 'Prospect, ';
			contactFilter.valueCodes.push('prospect');
		}
		if (contactFilter.displayValue.length > 0) {
			contactFilter.displayValue = contactFilter.displayValue.substr(0, contactFilter.displayValue.length - 2);
			this.filterTags.push(contactFilter);
		}

		// gender
		let genderFilter: FilterTag = {
			filterCode: 'gender',
			displayValue: '',
			valueCodes: []
		};
		if (this.filtersApplied.gender.male) {
			genderFilter.displayValue += 'Male, ';
			genderFilter.valueCodes.push('M');
		}
		if (this.filtersApplied.gender.female) {
			genderFilter.displayValue += 'Female, ';
			genderFilter.valueCodes.push('F');
		}
		if (genderFilter.displayValue.length > 0) {
			genderFilter.displayValue = genderFilter.displayValue.substr(0, genderFilter.displayValue.length - 2);
			this.filterTags.push(genderFilter);
		}

		// marital status
		let maritalStatusFilter: FilterTag = {
			filterCode: 'marital_status',
			displayValue: '',
			valueCodes: []
		};
		if (this.filtersApplied.marital_status.single) {
			maritalStatusFilter.displayValue += 'Single, ';
			maritalStatusFilter.valueCodes.push('sin');
		}
		if (this.filtersApplied.marital_status.married) {
			maritalStatusFilter.displayValue += 'Married, ';
			maritalStatusFilter.valueCodes.push('mar');
		}
		if (maritalStatusFilter.displayValue.length > 0) {
			maritalStatusFilter.displayValue = maritalStatusFilter.displayValue.substr(0, maritalStatusFilter.displayValue.length - 2);
			this.filterTags.push(maritalStatusFilter);
		}

		// rating
		let ratingFilter: FilterTag = {
			filterCode: 'rating',
			displayValue: '',
			valueCodes: []
		};
		if (this.filtersApplied.rating > 0) {
			ratingFilter.displayValue = `Rating: ${this.filtersApplied.rating}`;
			ratingFilter.valueCodes.push(this.filtersApplied.rating);
			this.filterTags.push(ratingFilter);
		}

		// age range
		let ageRangeFilter: FilterTag = {
			filterCode: 'age',
			displayValue: '',
			valueCodes: []
		};
		if (this.filtersApplied.age[0] > 0 || this.filtersApplied.age[1] < 100) {
			ageRangeFilter.displayValue = `${this.filtersApplied.age[0]} - ${this.filtersApplied.age[1]} yo`;
			ageRangeFilter.valueCodes = this.filtersApplied.age.slice();
			this.filterTags.push(ageRangeFilter);
		}

		// income range
		let incomeRangeFilter: FilterTag = {
			filterCode: 'income',
			displayValue: '',
			valueCodes: []
		};
		if (this.filtersApplied.income[0] > 0 || this.filtersApplied.income[1] < 500000) {
			incomeRangeFilter.displayValue = `${this.currencyLocalPipe.transform(this.filtersApplied.income[0], '1.0-0')} - ${this.currencyLocalPipe.transform(this.filtersApplied.income[1], '1.0-0')}`;
			incomeRangeFilter.valueCodes = this.filtersApplied.income.slice();
			this.filterTags.push(incomeRangeFilter);
		}

		// geography
		let geographyFilter: FilterTag = {
			filterCode: 'geography',
			displayValue: '',
			valueCodes: []
		};
		if (this.filtersApplied.geography) {
			this.filtersApplied.geography.map((country: any) => {
				geographyFilter.displayValue += `${country.name}, `;
				geographyFilter.valueCodes.push(country.code)
			});
			if (geographyFilter.displayValue.length > 0) {
				geographyFilter.displayValue = geographyFilter.displayValue.substr(0, geographyFilter.displayValue.length - 2);
				this.filterTags.push(geographyFilter);
			}
		}

		// - Required for later once backend is ready
		// search query
		let searchFilter: FilterTag = {
		 filterCode: 'search',
		 displayValue: '',
		 valueCodes: []
		};
		if (this.filtersApplied.searchText && this.filtersApplied.searchText !== '') {
		 searchFilter.valueCodes.push(this.filtersApplied.searchText);
		 this.filterTags.push(searchFilter);
		}
	}

	clearFilters() {
		let filterInputs = new SearchFilters();
		this.filtersObserver.emit('advanced_filters', filterInputs, 'blur');
	}

	removeFilter(index: number) {
		// remove filter
		const emptyFilters = new SearchFilters();
		const filterType = this.filterTags[index].filterCode;
		this.filtersApplied[filterType] = emptyFilters[filterType];

		// clear segmentID
		this.filtersApplied.segmentId = null;

		this.filtersObserver.emit('advanced_filters', this.filtersApplied, 'blur');
	}

	openSaveFilterGroupForm() {
		this.subs = this.filterGroupFormDialogService
			.openModal(this.filterTags)
			.subscribe((filterGroup) => {
				return true;
			});
	}

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  ngOnInit() {

  }
}

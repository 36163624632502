import { Injectable } from '@angular/core';
import { AccountDetailsResponse } from 'app/modules/auth/services';
import { VlConfig } from '../models/vl-config.model';
import { VlTemplate } from '../models/vl-template.model';

declare const campaign;

@Injectable({
  providedIn: 'root'
})
export class ViralLoopsService {
  private widgetLoadedOnce = false;
  private _campaign: any;
  private config: VlConfig;
  private scriptContents = {
    [VlTemplate.Altruistic]: {
      default: campaignId => `!function(){var a=window.VL=window.VL||{};return a.instances=a.instances||{},a.invoked?void(window.console&&console.error&&console.error('VL snippet loaded twice.')):(a.invoked=!0,void(a.load=function(b,c,d){var e={};e.publicToken=b,e.config=c||{};var f=document.createElement('script');f.type='text/javascript',f.id='vrlps-js',f.async=!0,f.src='https://app.viral-loops.com/client/vl/vl.min.js';var g=document.getElementsByTagName('script')[0];return g.parentNode.insertBefore(f,g),f.onload=function(){a.setup(e),a.instances[b]=e,d&&'function'==typeof d&&d(null,e)},e.identify=e.identify||function(a,b){e.afterLoad={identify:{userData:a,cb:b}}},e.pendingEvents=[],e.track=e.track||function(a,b){e.pendingEvents.push({event:a,cb:b})},e}))}();var campaign = VL.load('${campaignId}');`
    }
  }

  get campaign(): any {
    if (!this._campaign) {
      this._campaign = campaign;
    }

    return this._campaign;
  }

  constructor() {}

  init(config: VlConfig) {
    this.config = config;
  }

  getAltruisticScript(): HTMLScriptElement {
    this.configCheck();
    return this.createScript(this.scriptContents[VlTemplate.Altruistic].default(this.config.campaignIds.altruistic));
  }

  identify(account: AccountDetailsResponse, isNewUser: boolean): void {
    if (this.campaign) {
      this.campaign.identify({
        firstname: account.first_name,
        lastname: account.last_name,
        email: account.email,
        createdAt: Math.floor((new Date(account.created || new Date())).getTime() / 1000), // Creation date of your user in UNIX timestamp format. Leave empty for new users.
        isNew: isNewUser
      }, (err, details) => {});
    }
  }

  trackConversion(): void {
    if (this.campaign) {
      this.campaign.track('conversion', (err, data) => {});
    }
  }

  loadWidgets(): void {
    if (this.campaign) {
      if (this.widgetLoadedOnce) {
        this.campaign.widgets.reload();
      } else {
        this.campaign.widgets.load();
        this.widgetLoadedOnce = true;
      }
    }
  }

  logout(): void {
    if (this.campaign && this.campaign.logout) {
      this.campaign.logout();
    }
  }

  private createScript(contents): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerText = contents;
    return script;
  }

  /**
   * Checks if the service has been configured
   */
  private configCheck() {
    if (!this.config) {
      throw new Error('Viral Loops Service has not been configured');
    }
  }
}

import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BaseComponent } from '../../core/base/base-component';
import { ApiService } from '../../api/api.service';
import { _t } from 'app/modules/core/other/translate-marker';
import { PlanUpgradeService } from '../services/plan-upgrade.service';

export interface IPromoCodeResponse {
  metadata: any;
  amount_off: number | null;
  percentage_off: number | null;
}

export class PromoCodeConfig {
  promocode = '';
  promocodeMessage = '';
  discount = 0;
  percentageDiscount = 0;
  amountDiscount = 0;
  discountPercent = 0;
  promoCodeApplying = false
}

@Component({
  selector: 'app-payment-coupon',
  templateUrl: './payment-coupon.component.html',
  styleUrls: ['./payment-coupon.component.scss']
})
export class PaymentCouponComponent extends BaseComponent implements OnInit {

  @Input() currentPlanId: string;
  @Input() isAnnualy: boolean;
  @Input() totalPrice: number;
  @Output() promoCodeConfigEvent = new EventEmitter();

  INVALID_PROMOCODE_MESSAGE = _t('shared.invalidCoupon');

  promoCodeConfig = new PromoCodeConfig();

  constructor(
    private api: ApiService,
    private planUpgradeService: PlanUpgradeService
  ) {
    super();
  }

  ngOnInit() {
    this.prefillPromoCode();
  }

  prefillPromoCode() {
    if (this.planUpgradeService.coupon) {
      setTimeout(() => {
        this.promoCodeConfig.promocode = this.planUpgradeService.coupon;
        this.applyPromocode(this.currentPlanId);        
      }, 500)
    }
  }

  couponToUpperCase() {
    this.promoCodeConfig.promocode = this.promoCodeConfig.promocode.toUpperCase();
  }

  applyPromocode(currentPlanId: string) {
    currentPlanId = currentPlanId || this.currentPlanId;

    this.promoCodeConfig.promoCodeApplying = true;

    this.subs = this.api.subscription.promocodeCheckValid(this.promoCodeConfig.promocode, currentPlanId)
      .pipe(finalize(() => this.promoCodeConfig.promoCodeApplying = false))
      .subscribe((promoCode: IPromoCodeResponse) => {
        const promocodeValid = promoCode.percentage_off || promoCode.amount_off;

        if (!promocodeValid) {
          this.promoCodeConfig.promocodeMessage = this.INVALID_PROMOCODE_MESSAGE;
        }

        this.promoCodeCalc(promoCode);
        this.promoCodeConfigEvent.emit(this.promoCodeConfig);
      }, (err) => {
        this.promoCodeConfig = new PromoCodeConfig();
        this.promoCodeConfig.promocodeMessage = err.error.error.message || this.INVALID_PROMOCODE_MESSAGE;
        this.promoCodeConfigEvent.emit(this.promoCodeConfig);
      });
  }

  removePromocode(): void {
    this.promoCodeConfig = new PromoCodeConfig();
    this.promoCodeConfigEvent.emit(this.promoCodeConfig);
  }

  private promoCodeCalc(promoCode: IPromoCodeResponse): void {
    const totalPeriodPrice: number = this.isAnnualy ? this.totalPrice * 12 : this.totalPrice;

    this.promoCodeConfig.promocodeMessage = promoCode.metadata.description;
    this.promoCodeConfig.discountPercent = (promoCode.percentage_off) ? promoCode.percentage_off : 0;
    this.promoCodeConfig.percentageDiscount = (promoCode.percentage_off) ? (totalPeriodPrice * promoCode.percentage_off / 100) : 0;
    this.promoCodeConfig.percentageDiscount = Math.round(this.promoCodeConfig.percentageDiscount * 100) / 100;
    this.promoCodeConfig.amountDiscount = (promoCode.amount_off) ? promoCode.amount_off : 0;
    this.promoCodeConfig.discount = +(this.promoCodeConfig.percentageDiscount + this.promoCodeConfig.amountDiscount);
  }
}

import { ICountryData } from '../../../configs/localization.config';

export interface ContactType {
	client: boolean;
	lead: boolean;
	prospect: boolean;
}

export interface Age {
	min: number;
	max: number;
}

export interface Income {
	min: number;
	max: number;
}

export interface Gender {
	male: boolean;
	female: boolean;
}

export interface MaritalStatus {
	single: boolean;
	married: boolean;
}

export interface PolicyCount {
	min: number;
	max: number;
}

export class SearchFilters {
	isFilterApplied: boolean = false;
	contact_type: ContactType = {
		client: false,
		lead: false,
		prospect: false
	};
	rating: number = 0;
	age: number[] = [0, 100];
	income: number[] = [0, 500000];
	gender: Gender = {
		male: false,
		female: false
	};
	marital_status: MaritalStatus = {
		single: false,
		married: false
	};
	geography: Array<ICountryData> = [];
	segmentId: string = null;
	searchText: string = null;
}

export interface FilterTag {
	filterCode: string;
	displayValue: string;
	valueCodes: Array<any>;
}
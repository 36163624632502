import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import { finalize, filter } from 'rxjs/operators';
import {ApiService} from '../../api/api.service';
import { DialogService } from '../dialog/dialog.service';
import {IntercomService} from '../intercom/intercom.service';
import { API_CONFIG } from '../../../configs';
import { RouteBoundDialog } from '../../core/route-bound-dialog/route-bound-dialog.class';
import { RouteBoundDialogService } from '../../core/route-bound-dialog/route-bound-dialog.service';
import { _t } from 'app/modules/core/other/translate-marker';

@Component({
    templateUrl: './cancel-subscription-dialog.component.html',
    styleUrls: ['./cancel-subscription-dialog.component.scss']
})
export class CancelSubscriptionDialogComponent extends RouteBoundDialog {

    keepUpdating = true;

    constructor(
        public dialogRef: MatDialogRef<CancelSubscriptionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private api: ApiService,
        public snackbar: MatSnackBar,
        public dialog: MatDialog,
        private dialogService: DialogService,
        private intercom: IntercomService,
        public routeBoundDialogService: RouteBoundDialogService
    ) {
        super();
    }

    onCancelSubsciption(): void {
        this.api.post(API_CONFIG.payment.cancelSubsciption, {

        })
            .pipe(finalize(() => {
                this.close();
            }))
            .subscribe((data) => {
                //noinspection TypeScriptValidateTypes
                this.dialogService.confirm(
                    _t('security.subscriptionCancelled'),
                    _t('security.subscriptionCancelledMsg')
                );
            }, (err) => {
                //noinspection TypeScriptValidateTypes
                this.dialogService.confirm(
                    _t('security.weAreSorry'),
                    _t('security.weCouldSubscription'),
                    _t('security.contactSupport')
                )
                .pipe(filter(Boolean))
                .subscribe(() => {
                    this.intercom.openChat();
                });
            });
    }

    close () {
        this.dialogRef.close();
    }
}

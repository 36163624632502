import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { API_CONFIG } from '../../configs';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url[0] === '/') {
      let url = `${environment.apiUrl}${req.url}`;

      if (url.includes(API_CONFIG.prefixAuth + '/')) {
        url = url.replace(API_CONFIG.prefixVersion, '');
      }

      if (url.includes('/i18n')) {
        url = req.url;
      }

      const Req = req.clone({url});
      return next.handle(Req);
    } else {
      return next.handle(req);
    }
  }
}

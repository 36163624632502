import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { BaseComponent } from '../../../core/base/base-component';
import { card, cvc, expiration } from 'creditcards/index.js';
import { StripeService } from '../../../shared/stripe/stripe.service';
import { ToastrService } from 'ngx-toastr';

export interface CardData {
  cardNumber: string;
  expMonth: number;
  expYear: number;
  cvc: number;
  cardholder: string;
}

@Component({
  selector: 'app-card-details-form',
  templateUrl: './card-details-form.component.html',
  styleUrls: ['./card-details-form.component.scss']
})
export class CardDetailsFormComponent extends BaseComponent implements OnInit {
  expMonths = new Array(12);
  expYears = new Array(20);
  currentYear = (new Date()).getFullYear();
  cardForm: FormGroup;

  cardMask = [];

  private maskedCard = ['•', '•', '•', '•', ' ', '•', '•', '•', '•', ' ', '•', '•', '•', '•', ' ', /\d/, /\d/, /\d/, /\d/];
  private unmaskedCard = [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  private _cardData: CardData;


  @Output() formChanged: EventEmitter<FormGroup> = new EventEmitter(null);
  @Output() allowCheck: EventEmitter<boolean> = new EventEmitter(null);
  @Input() showErrors: boolean;
  @Input() set cardData(data: CardData) {
    if (this.cardForm && data) {
      this.cardForm.reset(data);
      this._cardData = data;
      this.cardMask = this.maskedCard;
    }
  };

  get checkTouchedWithErrors(): boolean {
    if (!this.cardForm) {
      return false;
    } else {
      let control: AbstractControl;
      return Object.keys(this.cardForm.controls).some(key => {
        control = this.cardForm.get(key);
        return control.invalid && (control.touched || this.showErrors);
      });
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private stripe: StripeService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.cardForm = this.createForm();
    this.cardMask = this.unmaskedCard;

    this.subs = this.cardForm.valueChanges.subscribe(() => {
      this.formChanged.emit(this.cardForm);
    });

  }

  addLeadingZero(val: number): string {
    if (isNaN(+val)) {
        return '';
    }

    return val > 9 ? val.toString() : `0${val}`;
  }

  createForm(): FormGroup {
    const formGroup = {
      'cardNumber': new FormControl('', [
        Validators.required,
        (control: AbstractControl) => {
          if (!control.value) {
            return { wrongFormat: true  };
          }

          return card.isValid(control.value.toString().replace(/\s/g, '')) ? null : { wrongFormat: true  };
        }
      ]),
      'expMonth': new FormControl('', [
        Validators.required,
        control => expiration.month.isValid(control.value) ? null : { wrongFormat: true  }
      ]),
      'expYear': new FormControl('', [
        Validators.required,
        control => expiration.year.isValid(control.value) ? null : { wrongFormat: true  }
      ]),
      'cvc': new FormControl('', [
        Validators.required,
        control => cvc.isValid(control.value) ? null : { wrongFormat: true  }
      ]),
      'cardholder': new FormControl('', [
        Validators.required,
        control => (/^[a-zA-Z\s]{1,99}/).test(control.value) ? null : { wrongFormat: true  }
      ])
    };

    return this.formBuilder.group(formGroup);
  }

  onFocus() {
    if (this._cardData) {
      this.cardForm.reset({});
      this._cardData = null;
      this.allowCheck.emit(true);
      this.cardMask = this.unmaskedCard;
    }
  }
}

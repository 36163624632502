import { IContactsIntrojsStepFakeData } from '../intro-js-models';
import { IContactListItem } from '../../../../contacts/contact-list/list.interface';
import { _t } from 'app/modules/core/other/translate-marker';

const ContactSlug = 'introjs-fake-contact-id';

const ContactsList = <IContactListItem[]>[
  {
    financial_information: {
      full_name: 'Alana Cheah',
      preferred_name: '',
      email: 'alanacheah@gmail.com',
      picture: { square: 'assets/images/card_avatar1.png' },
      phone_number: '+65 8252 4144'
    },
    rating: 5,
    contact_type: _t('contacts.client'),
    slug: '96e58b08-83ad-458a-a299-1f94ba5b0a2a'
  },
  {
    financial_information: {
      full_name: 'Catherine Neo',
      preferred_name: '',
      email: 'catherine.neo@synchestra.com',
      picture: { square: 'assets/images/card_avatar2.png' },
      phone_number: '+65 8252 4144'
  },
    rating: 5,
    contact_type: _t('contacts.client'),
    slug: '96e58b08-83ad-458a-a299-1f94ba5b0a2a'
  },
  {
    financial_information: {
      full_name: 'Jonathan Chin',
      preferred_name: '',
      email: 'jonathan.chin@synchestra.com',
      picture: { square: 'assets/images/card_avatar.png' },
      phone_number: '+65 8252 4144'
    },
    rating: 4,
    contact_type: _t('contacts.client'),
    slug: '96e58b08-83ad-458a-a299-1f94ba5b0a2a'
  },
  {
    financial_information: {
      full_name: 'Hazel Lim',
      preferred_name: '',
      email: 'hazel.lim@synchestra.com',
      picture: { square: 'assets/images/card_avatar3.jpg' },
      phone_number: '+65 8252 4144'
    },
    rating: 4,
    contact_type: _t('contacts.client'),
    slug: '96e58b08-83ad-458a-a299-1f94ba5b0a2a'
  },
  {
    financial_information: {
      full_name: 'Jonathan King',
      preferred_name: '',
      email: 'jonathan.king@synchestra.com',
      picture: { square: 'assets/images/card_avatar4.png' },
      phone_number: '+65 8252 4144'
    },
    rating: 4,
    contact_type: _t('contacts.client'),
    slug: '96e58b08-83ad-458a-a299-1f94ba5b0a2a'
  },
  {
    financial_information: {
      full_name: 'Stephen Lee',
      preferred_name: '',
      email: 'stephen.lee@synchestra.com',
      picture: { square: 'assets/images/card_avatar6.png' },
      phone_number: '+65 8252 4144'
    },
    rating: 3,
    contact_type: _t('contacts.lead'),
    slug: '96e58b08-83ad-458a-a299-1f94ba5b0a2a'
  },
  {
    financial_information: {
      full_name: 'Caroline Chao',
      preferred_name: '',
      email: 'caroline.chao@synchestra.com',
      picture: { square: 'assets/images/card_avatar8.png' },
      phone_number: '+65 8252 4144'
    },
    rating: 3,
    contact_type: _t('contacts.client'),
    slug: '96e58b08-83ad-458a-a299-1f94ba5b0a2a'
  },
  {
    financial_information: {
      full_name: 'Adam Nin',
      preferred_name: '',
      email: 'adam.nin@synchestra.com',
      picture: { square: 'assets/images/card_avatar7.png' },
      phone_number: '+65 8252 4144'
    },
    rating: 3,
    contact_type: _t('contacts.lead'),
    slug: '96e58b08-83ad-458a-a299-1f94ba5b0a2a'
  }
];

export const CONTACTS_STEPS_FAKE_DATA: IContactsIntrojsStepFakeData[] = [
  { number: 0, fakeData: { contacts: ContactsList } },
  { number: 1, fakeData: {} },
  {
    number: 2, fakeData: {
      contactId: ContactSlug,
      contact: <any>{
        slug: ContactSlug,
        financial_consultant: 'cd8b8929-8561-4948-8934-08219b2af5ef',
        financial_information: {
          slug: 'f4808344-551c-4898-ba48-9e6d80c4e44b',
          contact_methods: [],
          dependents: [],
          family_histories: [],
          priorities: [],
          picture: { square: 'assets/images/card_avatar3.jpg' },
          full_name: 'Alana Cheah',
          title: null,
          email: 'phaneendra@synchestra.com',
          phone_number: '+65 7688 5783',
          other_savings: [],
          other_loan: [],
          other_annual_income: [],
          other_annual_expenses: []
        },
        documents: [],
        created: '2018-08-16T15:49:19.545401Z',
        contact_type: _t('contacts.lead'),
        rating: 3,
        consumer: null
      }
    }
  },
  { number: 3, fakeData: { contacts: ContactsList } }
]

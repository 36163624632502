import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FacebookDialogConfig, IFacebookDialogConfig } from './facebook-dialog.config';

@Component({
  selector: 'app-facebook-dialog',
  templateUrl: './facebook-dialog.component.html',
  styleUrls: ['./facebook-dialog.component.scss']
})
export class FacebookDialogComponent implements OnInit {

  dialogData: IFacebookDialogConfig;

  constructor(
    public dialogRef: MatDialogRef<FacebookDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { serviceMode: string, fb: any }
  ) { }

  ngOnInit() {
    this.dialogData = FacebookDialogConfig[this.data.serviceMode];
  }

  rightButtonClickHandler(): void {
    this.data.fb.login(this.data.serviceMode);
    this.dialogRef.close();
  }
}

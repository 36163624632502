import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PlansSet, PlanDetails } from '../subscription-plans/subscription-table/subscription-table.component';
import { Card } from 'app/modules/api/payment.service';

export interface PaymentFormDialogMatData {
  plan: PlansSet,
  card: Card
  subscription: PlanDetails;
}

@Component({
  selector: 'app-payment-form-dialog',
  templateUrl: './payment-form-dialog.component.html',
  styleUrls: ['./payment-form-dialog.component.scss']
})
export class PaymentFormDialogComponent {
  formMode: 'SIGN_UP_MODE' | 'UPGRADE_MODE';

  constructor(
    public dialogRef: MatDialogRef<PaymentFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentFormDialogMatData
  ) {
    this.formMode = data && data.subscription ? 'UPGRADE_MODE' : 'SIGN_UP_MODE' ;
  }

  closeHanlder(data: boolean | any) {
    this.dialogRef.close(data);
  }
}

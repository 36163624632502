import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

import { Observable, of } from 'rxjs';
import { startWith, map, debounceTime } from 'rxjs/operators';

// Services
import { ApiService } from '../../api';
import { ContactService } from '../../api/contact.service';
import { ValidationService } from '../validation/validation.service';
import { ICountry, CountryListService } from '../../core/services/country-list/country-list.service';
import { FormChoicesService, FormChoiceType } from '../../core/services/form-helpers/form-choises.service';

import { Contact } from '../../contacts/models/contact.model';
import { RouteBoundDialogService } from '../../core/route-bound-dialog/route-bound-dialog.service';
import { RouteBoundDialog } from '../../core/route-bound-dialog/route-bound-dialog.class';

@Component({
  selector: 'app-contact-form-dialog',
  templateUrl: './contact-form-dialog.component.html',
  styleUrls: ['./contact-form-dialog.component.scss']
})
export class ContactFormDialogComponent extends RouteBoundDialog implements OnInit {

  contact: Contact;
  contactTypes: Array<FormChoiceType>;
  filteredCountries: Observable<ICountry[]>;
  form: FormGroup;
  imageSrc = '';
  isLoading: boolean;
  titles: Array<FormChoiceType>;

  private countriesTypes: ICountry[];
  private image: FormData;

  constructor(
    private apiService: ApiService,
    private contactService: ContactService,
    private countryListService: CountryListService,
    public dialogRef: MatDialogRef<ContactFormDialogComponent>,
    private formBuilder: FormBuilder,
    private formChoicesService: FormChoicesService,
    public routeBoundDialogService: RouteBoundDialogService
  ) {
    super();
  }

  ngOnInit() {
    this.buildContactForm(this.contact);
    this.prepareContactForm();
  }

  closeDialog(data: Contact | false = false): void {
    this.dialogRef.close(data);
  }

  /**
   * Function is needed for autocomplete showing name from object vaue
   */
  getCountryDisplayFn(): Function {
    return (val): string => {
      if (val) {
        return val.name ? val.name : this.countriesTypes.find(country => country.code === val).name;
      } else {
        return '';
      }
    };
  }

  /**
   * Store picture in component
   * @param formData - picture loaded into browser
   */
  onImageAdd(formData: FormData) {
    this.image = formData;
  }

  /**
   * Is invoked when rating was changed
   * @param newValue - rate value
   */
  onRatingChange(newValue: number): void {
    this.form.controls.rating.setValue(newValue);
  }

  onSubmit(): void {
    this.form['submitted'] = true;

    if ((!this.form.pristine || this.image) && this.form.valid && !this.isLoading) {
      this.loading(true);
      this.sendCreateContact(this.form.value);
    }
  }

  /**
   * Build model driven form
   */
  private buildContactForm(contact: Contact): void {
    this.form = this.formBuilder.group({
      contact_type: [contact.contact_type, [Validators.required]],
      rating: [contact.rating, [Validators.required]],
      financial_information: this.formBuilder.group({
        title: [contact.financial_information.title],
        full_name: [contact.financial_information.full_name, [
          Validators.required,
          Validators.maxLength(100)
        ]],
        phone_number: [contact.financial_information.phone_number, [
          ValidationService.requiredPhoneOrEmail,
          ValidationService.phoneValidator,
          Validators.maxLength(16)
        ]],
        email: [contact.financial_information.email, [
          ValidationService.requiredPhoneOrEmail,
          ValidationService.emailValidator,
          Validators.maxLength(255)
        ]],
        country: [contact.financial_information.country, [
          Validators.required,
          Validators.maxLength(100)
        ]]
      })
    });

    this.form['submitted'] = false;
    this.validatePhoneOrEmail();

    this.filteredCountries = this.form.get('financial_information.country').valueChanges
      .pipe(
        startWith(null),
        map(val => val ? this.filterCountries(val) : this.countriesTypes.slice())
      );
  }

  /**
   * Filter countries by passed autocomplete value
   */
  private filterCountries(val: string & ICountry): ICountry[] {
    if (typeof val === 'string') {
      return this.countriesTypes.filter(country =>
        country.name.toLowerCase().indexOf(val.toLowerCase()) === 0);
    }
    return this.countriesTypes;
  }

  /**
   * Prepopulate country by IP for new contact object
   * @param contact
   */
  private populateDefaultCountry(contact: Contact): Observable<Contact> {
    if (!contact.financial_information.slug) {
      return this.apiService.getLocationData().pipe(map(data => {
        if (data && data['country']) {
          contact.financial_information.country = data['country'];
        }
        return contact;
      }));
    }
    return of(contact);
  }

  /**
   * Prepare dropdown options, selected default options
   */
  private prepareContactForm(): void {
    this.contactTypes = this.formChoicesService.CONTACT_TYPES;
    this.countriesTypes = this.countryListService.getCountryList();
    this.titles = this.formChoicesService.TITLE_TYPES;

    this.subs = this.populateDefaultCountry(this.contact)
      .subscribe(contact => {
        this.form.get('financial_information.country')
          .setValue(this.contact.financial_information.country);
    });
  }

  /**
   * Send new image if it was changed and close dialog
   * @param contact - updated contact
   */
  private sendContactImage(contact: Contact): void {
    if (this.image) {
      // Send image
      this.contactService.sendContactImage(contact.financial_information.slug, this.image)
      .subscribe(updatedFinInfo => {
        contact.financial_information.picture = updatedFinInfo.picture;
        this.closeDialog(contact);
        this.loading(false);
      });
    } else {
      this.closeDialog(contact);
      this.loading(false);
    }
  }

  /**
   * Send new contact to API
   * @param body - contact from value
   */
  private sendCreateContact(body: Contact): void {
    this.contactService.sendNewContact(body)
      .subscribe((contact: Contact) => {
        this.sendContactImage(contact);
      }, err => this.loading(false)
    );
  }

  /**
   * Show/hide preloader, change isLoading value
   * @param isLoading - loading in process or not
   */
  private loading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  /**
   * Subscribe on contact form changes to apply phone and email validation
   */
  private validatePhoneOrEmail(): void {
    this.form.get('financial_information.email').updateValueAndValidity();
    this.form.get('financial_information.phone_number').updateValueAndValidity();

    if (this.form) {
      this.form.valueChanges
        .pipe(debounceTime(400))
        .subscribe((data: Contact) => {
          this.form.get('financial_information.email').updateValueAndValidity();
          this.form.get('financial_information.phone_number').updateValueAndValidity();
      });
    }
  }
}

import { Directive, Input, ElementRef, HostListener, OnInit, Renderer } from '@angular/core';
import { MeasurementPipe } from './measurement.pipe';

@Directive({selector: '[measurementFormatter]'})
export class MeasurementFormatterDirective implements OnInit {

  @Input() measurementFormatter: string;
  private el: any;

  constructor(
      private elementRef: ElementRef,
      private measurementPipe: MeasurementPipe,
      private renderer: Renderer
  ) {
    this.el = this.elementRef.nativeElement;
  }

  ngOnInit() {
    this.el.value = this.el.value || this.el.value === 0 ?
      this.measurementPipe.transform(this.el.value, this.measurementFormatter) :
      this.el.value;
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    this.el.value = value ? this.measurementPipe.transform(value, this.measurementFormatter) : null;

      // Dispatches event to update model driven form
      const event: Event = document.createEvent('Event');
      event.initEvent('input', true, true);
      Object.defineProperty(event, 'target', {value: this.elementRef.nativeElement, enumerable: true});
      this.renderer.invokeElementMethod(this.elementRef.nativeElement, 'dispatchEvent', [event]);
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    this.el.value = value ? this.measurementPipe.transform(value, this.measurementFormatter) : value;
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-referral-share-popup',
  templateUrl: './referral-share-popup.component.html',
  styleUrls: ['./referral-share-popup.component.scss']
})
export class ReferralSharePopupComponent implements OnInit {
  animatedPopup: boolean = false;
  popupOpened: boolean = false;
  widgetLoadingStarted: boolean = false;

  constructor() {}

  ngOnInit() {
  }

  togglePopup(opened: boolean): void {
    this.popupOpened = opened;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_CONFIG, PARAM, APP_CONFIG } from '../../configs';

export interface SyncSubscription {
  card?: {
    token: string
  };
  coupon: string;
  plan: string;
}

interface SubscriptionPlansResponse {
  count: number;
  next: number;
  previous: number;
  results: SubscriptionPlan[];
}

export interface SubscriptionPlan {
  id: number;
  stripe_id: string;
  amount: number;
  currency: string;
  interval: string;
  interval_count: number;
  name: string;
  statement_descriptor: string;
  trial_period_days: number;
  metadata: any;
  stripe_account: string;
}

@Injectable()
export class SubscriptionService {

  constructor(private _http: HttpClient) {
  }

  create(data: SyncSubscription) {
    return this._http.post(API_CONFIG.payment.createSubsciption, data);
  }

  currentSubscription() {
    return this._http.get(API_CONFIG.payment.getSubscription)
      .pipe(map((res: any) => res.results ? res.results[0] : {}));
  }

  promocodeCheck(promocode: string) {
    return this._http.get(API_CONFIG.payment.checkPromocode.replace(PARAM, promocode));
  }

  promocodeCheckValid(promocode: string, plan: string) {
    return this._http.get(API_CONFIG.payment.checkPromocodeForPlan
      .replace(PARAM, promocode)
      .replace(PARAM, plan)
    );
  }

  getSubscriptionPlans(): Observable<any> {
    return this._http.get(API_CONFIG.payment.plansList)
      .pipe(map((response: SubscriptionPlansResponse) => response.results))
  }

  getCurrentUsersPlan(): Observable<string> {
    return this._http.get(API_CONFIG.planGroup.get)
    .pipe(map(({plan_group}: {plan_group: string}) => plan_group));
  }

  /**
   * Return Subscription plans (month and year) to upgrade based on current plan
   * @param planName - 'Standard', 'Close', 'Attract Plus' etc.
   */
  getUpgradeSubscriptionPlans(planName: string):
  Observable<{month: SubscriptionPlan, year: SubscriptionPlan}> {
    return this.getSubscriptionPlans().pipe(map((plans: SubscriptionPlan[]) => {
      return {
        month: plans.find((plan: SubscriptionPlan) =>
                plan.name === planName && plan.interval === 'month'),
        year: plans.find((plan: SubscriptionPlan) =>
                plan.name === planName && plan.interval === 'year'),
      };
    }));
  }

  updateSubscriptionPlan(payload: { plan: string, coupon?: string }) {
    return this._http.put(API_CONFIG.payment.updateSubscription, payload);
  }

  getSubscriptionDiscount(stripe_id: string): Observable<any> {
    return this._http.get(API_CONFIG.payment.discount.replace(PARAM, stripe_id));
  }

  getSubscriptionPlansDetails(): Observable<any> {
    return this._http.get(API_CONFIG.payment.subscriptionPlansDetails);
  }
}

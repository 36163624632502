import { Injectable, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
declare const require;
const merge = require('lodash.merge');

import { Contact } from '../models/contact.model';
import { DialogService } from 'app/modules/shared/dialog/dialog.service';
import { ITabWrapper } from './details.component';
import { _t } from 'app/modules/core/other/translate-marker';

@Injectable()
export class ContactDetailsHelperService {
  addEventEmitter: EventEmitter<any> = new EventEmitter(null);
  contact: Contact;
  contactFormValueBehaviorSubject: BehaviorSubject<Contact> = new BehaviorSubject(null);
  hasUnsavedFormChanges: boolean = false;
  selectedTabIndex: number;
  selectedIndexChanged = new Subject<number>();
  tabs: ITabWrapper[] = [];

  get data(): {
    addEventEmitter: EventEmitter<any>,
    contact: Contact
  } {
    return {
      addEventEmitter: this.addEventEmitter,
      contact: this.contact
    };
  }

  set contactFormValue(value: Contact) {
    this.contactFormValueBehaviorSubject.next(merge(this.contactFormValueBehaviorSubject.value, value));
    this.hasUnsavedFormChanges = true;
  }

  constructor(private dialogService: DialogService) {}

  checkSelectedIndex(route: ActivatedRoute): void {
    this.tabs.every((tab: ITabWrapper, index: number) => {
      if (tab.route === route.snapshot.routeConfig.path) {
        this.selectedTabIndex = index;
        return false;
      }
      return true;
    });

    this.selectedIndexChanged.next(this.selectedTabIndex);
  }

  /**
   * Show confirm cancelation dialog if form data was changed (Personal or Medical tab)
   */
  openCancelDialog() {
    return this.dialogService
      .confirm(
        _t('contacts.contactForm.youHaveUnsavedChanges'),
        _t('contacts.contactForm.navigateWayFrom'),
        _t('common.yesDiscard'),
        _t('common.noContinue')
      ).pipe(
        first(),
        tap((res: boolean) => {
          if (res) {
            this.hasUnsavedFormChanges = false;
          }
        })
      );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DialogObserverService } from '../../core/services/dialog-observer/dialog-observer.service';
import { EmptyStatesContentConfig, IEmptyStateContent } from './empty-states-content.config';

@Component({
  selector: 'app-empty-state-card',
  templateUrl: './empty-state-card.component.html',
  styleUrls: ['./empty-state-card.component.scss']
})
export class EmptyStateCardComponent implements OnInit {

  data: IEmptyStateContent;

  @Input() content: string;
  @Input() extraData: any;

  constructor(
    private dialogObserver: DialogObserverService
  ) { }

  ngOnInit() {
    this.data = EmptyStatesContentConfig[this.content] || {};
  }

  public clickHandler(event, action): void {
    event.preventDefault();
    this.dialogObserver.emit(action, { [action.split('_')[1]]: this.extraData });
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RouteBoundDialog } from '../../../core/route-bound-dialog/route-bound-dialog.class';
import { RouteBoundDialogService } from '../../../core/route-bound-dialog/route-bound-dialog.service';

@Component({
  selector: 'app-dialog-contacts-filters',
  templateUrl: './dialog-contacts-filters.component.html',
  styleUrls: ['./dialog-contacts-filters.component.scss']
})
export class DialogContactsFiltersComponent extends RouteBoundDialog implements OnInit {

  private selectedRatings = [];
  private selectedFilters: any;

  // TODO: Optimize initial params, to support autogenerated initial data, based on incoming params
  private initialData = { rating: [], lead: false, prospect: false, client: false };

  constructor(
    public dialogRef: MatDialogRef<DialogContactsFiltersComponent>,
    public routeBoundDialogService: RouteBoundDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

  ngOnInit() {
    this.selectedFilters = { ...this.data.searchParams };
    this.selectedRatings = [ ...this.data.searchParams.rating ];
  }

  public onSubmit(): void {
    this.dialogRef.close(this.selectedFilters);
  }

  public resetAll(): void {
    this.dialogRef.close(this.initialData);
  }

  public toggleType(id: string): void {
    this.selectedFilters[id] = !this.selectedFilters[id];
  }

  public checkSelectedStatus(id: string): boolean {
    return this.selectedFilters[id];
  }

  public toggleRating(id: number): void {
    if (!this.isRatingSelected(id)) {
      this.selectedRatings.push(id);
    } else {
      this.selectedRatings.splice(this.selectedRatings.indexOf(id), 1);
    }
    this.selectedFilters.rating = this.selectedRatings;
  }

  public isRatingSelected(id: number): boolean {
    return this.selectedRatings.indexOf(id) !== -1;
  }
}

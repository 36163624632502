import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Observable } from 'rxjs';
import { ConfirmDialog } from './dialog.component';
import { DialogCompleteComponent } from './dialog-complete/dialog-complete.component';
import { DIALOG_COMPLETE_CONFIG } from './dialog-complete/dialog-complete.config';

@Injectable()
export class DialogService {

  constructor(
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    private config: MatDialogConfig
  ) {
  }

  showSnackBar(message: string, action?: string, extraClasses?: string[]) {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    config.panelClass = extraClasses || null;

    return this.snackBar.open(message, action, config);
  }

  custom(component, data?, config? ) {
    if (data) {
      this.config.data = data;
    }
    this.config = Object.assign({}, this.config, config);

    return this.dialog.open(component, this.config).afterClosed();
  }

  confirm(title: string, message: string, confirmBtnText?: string, cancelBtnText?: string): Observable<boolean> {

    let dialogRef: MatDialogRef<ConfirmDialog>;

    dialogRef = this.dialog.open(ConfirmDialog);
    dialogRef.componentInstance.confirmBtnText = confirmBtnText;
    dialogRef.componentInstance.cancelBtnText = cancelBtnText;
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;

    return dialogRef.afterClosed();
  }

  complete(config: string, title :string): Observable<any> {
    let dialogRef: MatDialogRef<DialogCompleteComponent>;
    dialogRef = this.dialog.open(DialogCompleteComponent);
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.config = DIALOG_COMPLETE_CONFIG[config];
    return dialogRef.afterClosed();
  }

}

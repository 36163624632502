import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ImageUploadDialogComponent } from './image-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';

@Injectable()
export class ImageUploadDialogService {

  constructor(private dialog: MatDialog) { }

  openModal(loadedImage, isEdit): Observable<string> {
    let dialogRef: MatDialogRef<ImageUploadDialogComponent>;

    dialogRef = this.dialog.open(ImageUploadDialogComponent);
    if (loadedImage) {
      dialogRef.componentInstance.loadedImage = loadedImage;
    }
    if (isEdit) {
      dialogRef.componentInstance.isEdit = isEdit;
    }

    return dialogRef.afterClosed();
  }

}

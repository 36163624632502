
export class RiskProfile {
  current_situation: string = null;
  future_financial_need: string = null;
  investment_drop_over: string = null;
  investment_needs: string = null;
  investment_preference: string = null;
  portfolio_preferred: string = null;
  retirement_years: string = null;
  riskiest_asset: string = null;

  constructor() {};
}
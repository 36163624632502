import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'ac-star',
  template: `<span class="star"
      [class.inactive]="!active"
      (click)="handleRate()"
      [ngClass]="{ 'clickable': clickable }">
        <i class="icon-ico-stargold"></i>
    </span>`,
  styles: [`
    .star {
      color: #F1F2F2;
      font-size: 20px;
      transition: color .3s ease-in-out;
    }
    .star.clickable {
      cursor: pointer;
    }
    .star {
      margin-right: 3px;
      color: #F58320;
    }
    .star.inactive {
      color: #DAE1E3;
    }
    .star i {
      margin-right: 3px;
      color: inherit;
    }
  `]
})
export class AcStar {
  @Input() active: boolean;
  @Input() clickable: boolean;
  @Input() position: number;
  @Output() rate = new EventEmitter();

  handleRate() {
    this.rate.emit(this.position);
  }
}

import { Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';

@Injectable()
export class ResolutionHelperService {

  private mobile: boolean;
  private tablet: boolean;
  private desktop: boolean;
  private ultraWide: boolean;

  private small  = 640;
  private medium = 1024;
  private large  = 1440;

  constructor(private eventManager: EventManager) {
    this.defineScreenResolution();
    this.eventManager.addGlobalEventListener('window', 'resize', this.defineScreenResolution.bind(this));
  }

  defineScreenResolution(): void {
    this.mobile = false;
    this.tablet = false;
    this.desktop = false;
    this.ultraWide = false;

    switch (true) {
      case (window.innerWidth < this.small) : this.mobile = true; break;
      case (this.small < window.innerWidth && window.innerWidth < this.medium) : this.tablet = true; break;
      case (window.innerWidth > this.medium) : this.desktop = true; break;
      case (window.innerWidth > this.large) : this.ultraWide = true; break;
    }
  };

  public get isMobile(): boolean {
    return this.mobile;
  }
  public get isTablet(): boolean {
    return this.tablet;
  }
  public get isDesktop(): boolean {
    return this.desktop;
  }
  public get isUltraWide(): boolean {
    return this.ultraWide;
  }
}

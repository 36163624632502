import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

// Services
import { DialogService } from '../dialog/dialog.service';
import { PaymentService, Card } from '../../api/payment.service';

// Components
import { BaseComponent } from '../../core/base/base-component';
import { PaymentFormDialogComponent, PaymentFormDialogMatData } from '../payment-form-dialog/payment-form-dialog.component';

// Other
import { PlanDetails, PlansSet } from './subscription-table/subscription-table.component';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent extends BaseComponent {

  @Input() buttonText: string;
  @Input() showFaq: boolean;
  @Input() currentSubscription: any;

  card: Card;
  isLoading = false;
  planGroup: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private paymentService: PaymentService
  ) {
    super();
    this.subs = this.paymentService.getDefaultCard()
      .subscribe( card => this.card = card );
  }

  currentSubscriptionHandler(data: PlanDetails): void {
    this.currentSubscription = data;
  }

  onPlanSelected(plan: PlansSet): void {
    const dialogData: PaymentFormDialogMatData = {
      plan: plan,
      card: this.card,
      subscription: this.currentSubscription
    };

    this.subs = this.dialogService.custom(
        PaymentFormDialogComponent,
        dialogData,
        { panelClass: 'dialog-upgrade-plan' }
      )
      .pipe(filter(Boolean))
      .subscribe((res: any) => {
        if (res && res.card) {
          this.card = res.card;
        }
        if (res && res.planGroup) {
          this.planGroup = res.planGroup;
        }
        if (this.route.snapshot.params['targetRoute']) {
          this.router.navigate([this.route.snapshot.params['targetRoute']]);
        } else if (this.route.snapshot.queryParams['targetRoute']) {
          this.router.navigate([this.route.snapshot.queryParams['targetRoute']]);
        }
      });
  }
}

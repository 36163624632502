import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivityDialogsService } from '../../../activities/activity-list/activity-dialogs.service';
import { OpportunityDialogService } from '../../../opportunities/opportunity-dialog.service';
import { ContactFormDialogService } from 'app/modules/shared/contact-form-dialog/contact-form-dialog.service';

@Injectable()
export class DialogObserverService {

  private dialogSource = new Subject<any>();

  public dialogRequest$ = this.dialogSource.asObservable();

  constructor(
    public activityDialogService: ActivityDialogsService,
    public contactDialogService: ContactFormDialogService,
    public opportunityDialogService: OpportunityDialogService
  ) {
    this.activityDialogService.initSubscription(this);
    this.contactDialogService.initSubscription(this);
    this.opportunityDialogService.initSubscription(this);
  }

  public emit(type: string, item?: any): void {
    this.dialogSource.next({ type: type, item: item });
  }
}

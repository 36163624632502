import { Document } from './document.model';
import { FinancialInformation } from './financial-information.model';

export class Contact {
  slug = '';
  financial_consultant = '';
  financial_information: FinancialInformation;
  created: Date;
  documents?: Document[] = [];
  modified: Date;
  contact_type = 'lead';
  consumer = '';
  rating = 3;

  constructor(financialInformation: FinancialInformation = new FinancialInformation()) {
    this.financial_information = financialInformation;
  };
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'replaceUnderscore' })
export class ReplaceUnderscore implements PipeTransform {
    transform(value: string, replaceBy: string = ' ') {
        if (value) {
            return value.replace(/_/g, replaceBy);
        }
        return value;
    }
}

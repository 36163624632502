import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../../core/base/base-component';
import { SearchFiltersObserverService } from '../search-filters-observer.service';

@Component({
  selector: 'app-search-filter-list',
  templateUrl: './search-filter-list.component.html',
  styleUrls: ['./search-filter-list.component.scss']
})
export class SearchFilterListComponent extends BaseComponent implements OnInit {

  isOpen = false;
  selectedItems = [];

  @Input('title') title: string;
  @Input('property') property: string;
  @Input('items') items = [];

  constructor(private observer: SearchFiltersObserverService) {
    super();
    this.mainObserver();
  }

  mainObserver(): void {
    this.subs = this.observer.filtersSource$.subscribe(data => {
      if (data.type === 'all' && data.value && this.title !== 'all') {
        this.isOpen = false;
        return this.selectedItems = [];
      }
      if (data.type !== this.property && data.action === 'blur') {
        this.isOpen = false;
      }
    });
  }

  public ngOnInit() {
    this.property = this.property ? this.property : this.title;
  }

  public toggleWindow(): void {
    this.isOpen = !this.isOpen;
  }

  public selectedItemToggler(id: string): void {
    if (!this.checkSelectedItem(id)) {
      this.selectedItems.push(id);
    } else {
      this.selectedItems.splice(this.selectedItems.indexOf(id), 1);
    }
    this.observer.emit(this.property, this.selectedItems, 'blur');
  }

  public checkSelectedItem(id: string): boolean {
    return this.selectedItems.indexOf(id) !== -1;
  }

  public hasSelectedItems(): number {
    return this.selectedItems.length;
  }
}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DialogService } from '../dialog/dialog.service';
import { RouteBoundDialog } from '../../core/route-bound-dialog/route-bound-dialog.class';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ShareBoxService } from './share-box.service';

@Component({
  selector: 'app-share-box',
  templateUrl: './share-box.component.html',
  styleUrls: ['./share-box.component.scss'],
  providers: [ShareBoxService]
})
export class ShareBoxComponent extends RouteBoundDialog implements OnInit {
  facebookEncodeUrl: string;
  referralCode: string = null;
  referralCodeUrl: string = null;
  sharedText: string;
  twitterUrl: string;
  whatsappUrl: SafeUrl;
  inputBoxCode: string;
  copyCode: string;

  constructor(
    public dialog: DialogService,
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private shareBoxService: ShareBoxService,
    private sanitizer:DomSanitizer
  ) {
    super();
  }

  ngOnInit() {
    this.getAppInviteLink();
  }

  getAppInviteLink() {
    this.subs = this.shareBoxService.getInviteLink()
      .subscribe((response) => {
        this.referralCode = response.code;
        this.referralCodeUrl = response.url;
        this.initializeSocialShare();
      },
        (error) => { },
      );
  }

  initializeSocialShare() {
    this.sharedText = this.translateService.instant('contacts.contactList.inviteMessage');
    this.sharedText = this.sharedText.replace("XXXXXX", this.referralCode);

    this.facebookEncodeUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.referralCodeUrl + '?channel=facebook')}&quote=${this.sharedText}`;
    this.twitterUrl = `https://twitter.com/intent/tweet?text=${this.sharedText}&url=${encodeURIComponent(this.referralCodeUrl + '?channel=twitter')}`;
    this.whatsappUrl = this.sanitizer.bypassSecurityTrustUrl(`whatsapp://send?text=${this.sharedText} ${encodeURIComponent(this.referralCodeUrl + '?channel=whatsapp')}`);
    this.inputBoxCode = `${this.referralCodeUrl}?channel=direct`;
    this.copyCode = `${this.sharedText} ${this.referralCodeUrl}?channel=direct`;
  }

  showSuccessNotification(): void {
    this.snacks = this.dialog.showSnackBar('Copied to clipboard');
  }
}

import { Directive, Input, OnInit, ElementRef } from '@angular/core';

// TODO: can be removed from all use cases as MatConfig startet supporting of 'autoFocus: false'
/**
 *  Workaround to avoid know material libarary issue
 *  https://github.com/angular/material2/issues/2879
 *
 *  * for mat-select and probably another
 *  components should be added this directive and tabindex="-1" initially in template
 */

@Directive({selector: '[avoidMatDialogAutoFocus]'})
export class AvoidMatDialogAutoFocusDirective implements OnInit {

  @Input() avoidMatDialogAutoFocus: string;

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.changeTabindex('-1');

    setTimeout(() => {
      this.changeTabindex('0');
    }, 1000);
  }

  changeTabindex(value: string): void {
    if (this.avoidMatDialogAutoFocus) {
      const tabbableElement = this.elementRef.nativeElement.querySelector(this.avoidMatDialogAutoFocus);
      tabbableElement.setAttribute('tabindex', value);
    } else {
      this.elementRef.nativeElement.setAttribute('tabindex', value);
    }
  }
}

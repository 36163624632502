import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RouteBoundDialog } from '../../../core/route-bound-dialog/route-bound-dialog.class';
import { RouteBoundDialogService } from '../../../core/route-bound-dialog/route-bound-dialog.service';

@Component({
  templateUrl: './dialog-opportunity-filters.component.html',
  styleUrls: ['./dialog-opportunity-filters.component.scss']
})
export class DialogOpportunityFiltersComponent extends RouteBoundDialog implements OnInit {

  RangeForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DialogOpportunityFiltersComponent>,
    public routeBoundDialogService: RouteBoundDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

  ngOnInit() {
    this.RangeForm = this.fb.group({
      min: [this.data.min || '', Validators.min(0)],
      max: [this.data.max || '', [Validators.min(1), Validators.max(999999)]]
    });
  }

  prepareSubmit() {
    const form = this.RangeForm.value;
    return {
      min: form.min || '',
      max: form.max || '',
      status: this.data.selectedStatuses
    };
  }

  onSubmit() {
    this.dialogRef.close(this.prepareSubmit());
  }

  resetAll() {
    this.dialogRef.close({ min: '', max: '', status: [] });
  }

  toggleStatus(id: string): void {
    if (!this.checkSelectedStatus(id)) {
      this.data.selectedStatuses.push(id);
    } else {
      this.data.selectedStatuses.splice(this.data.selectedStatuses.indexOf(id), 1);
    }
  }

  checkSelectedStatus(id: string): boolean {
    return this.data.selectedStatuses.indexOf(id) !== -1;
  }
}

export const PLAN_OPTIONS = [
    {
        heading : '',
        text : 'premiumPlanDetails.options.clientReferral',
    },
    {
        heading : '',
        text : 'premiumPlanDetails.options.valueGained',
    },
    {
        heading : '',
        text : 'premiumPlanDetails.options.upgrading',
    },
    {
        heading : '',
        text : 'premiumPlanDetails.options.financeProfessional',
    },
    {
        heading : '',
        text : 'premiumPlanDetails.options.success',
    },
    {
        heading : 'Others:',
        text : 'premiumPlanDetails.options.other',
    }
]
import { Injectable } from '@angular/core';
import { _t } from 'app/modules/core/other/translate-marker';

// TODO: Should be moved to Interfaces folder
export interface IEmptyStateContent {
  ico ?: string;
  titles: Array<string>;
  extraTitles?: Array<string>;
  button?: string;
  action?: string;
}

@Injectable()
export class EmptyStatesContentConfig {

  static get EMPTY_ACTIVITIES_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-empty-activities',
      titles: [_t('emptyState.noActivities'), _t('emptyState.clickToCreateActivity'), _t('emptyState.yourOwnCustomActivity')],
      button: _t('emptyState.addFirstActivity'),
      action: 'add_activity'
    };
  }

  static get EMPTY_ACTIVITIES_NOT_FOUND_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-empty-activities',
      titles: [_t('emptyState.noActivitiesFound')],
    };
  }

  static get EMPTY_DASHBOARD_ACTIVITIES_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-empty-activities',
      titles: [_t('emptyState.currentlyHave'), _t('emptyState.clickCreateOwn')],
      button: _t('emptyState.addNewActivity'),
      action: 'add_activity'
    };
  }

  static get EMPTY_OPPORTUNITIES_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-no-opportunities',
      titles: [_t('emptyState.noOpportunityAdded'), _t('emptyState.clickToCreateOpp'),  _t('emptyState.yourOwnCustom')],
      button: _t('emptyState.addFirstOportunity'),
      action: 'add_opportunity'
    };
  }

  static get EMPTY_OPPORTUNITIES_NOT_FOUND_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-no-opportunities',
      titles: [_t('emptyState.noOpporFound')],
    };
  }

  static get EMPTY_BILLING_HISTORY_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-emptybilling-history',
      titles: [_t('emptyState.yourBillingHistory'), _t('emptyState.youSeeUpdates'), _t('emptyState.choosenPlan')],
    };
  }

  static get EMPTY_CONTACT_PRODUCTS_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-no-products',
      titles: [_t('emptyState.noProductsAdded'), _t('emptyState.clickToCreateNewProduct'),  _t('emptyState.yourCustomProduct')],
      button: _t('emptyState.addFirstProduct'),
      action: 'add_contact_product'
    };
  }

  static get EMPTY_CONTACT_DEPENDENTS_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-no-dependents',
      titles: [_t('emptyState.noDependentsAdded'), _t('emptyState.clickToCreateNewDependent'),  _t('emptyState.yourCustomDependent')],
      button: _t('emptyState.addFirstDependent'),
      action: 'add_contact_dependent'
    };
  }

  static get EMPTY_CONTACT_FAMILY_MEMBERS_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-no-familymembers',
      titles: [_t('emptyState.noFamilyMembers'), _t('emptyState.clickToCreateNewFamilyMember'),  _t('emptyState.yourCustomFamilyMembers')],
      button: _t('emptyState.addFirstFamilyMember'),
      action: 'add_contact_familymember'
    };
  }

  static get EMPTY_CONTACT_DOCUMENTS_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-no-documents',
      titles: [_t('emptyState.noDocumentsAddedYet'), _t('emptyState.clickAddDocument'),  _t('emptyState.yourCustomProduct')],
      button: _t('emptyState.addFirstDocument'),
      action: 'add_contact_document'
    };
  }

  static get EMPTY_CONTACT_RISK_PROFILE(): IEmptyStateContent {
    return {
      ico: 'ico-activity-task',
      titles: [_t('emptyState.estimateRiskProfile'), _t('emptyState.beforePassed')],
      button: _t('emptyState.testYourRisk'),
      action: 'go_to_risk_profile_form'
    };
  }

  static get EMPTY_CONTACT_FINANCE(): IEmptyStateContent {
    return {
      ico: 'ico-activity-task',
      titles: [_t('emptyState.estimateNetworthCashflow')],
      button: _t('emptyState.fillFinancialBrief'),
      action: 'go_to_finance',
    };
  }

  static get EMPTY_CONTACT_LIST_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-empty-contacts',
      titles: [_t('contacts.noContactsAddedYet'), _t('contacts.clickImportContacts')],
      extraTitles: [_t('contacts.createManually')],
      button: _t('contacts.createNewContact'),
      action: 'add_contact'
    };
  }

  static get EMPTY_CONTACT_NOT_FOUND_CONTENT(): IEmptyStateContent {
    return {
      ico: 'ico-empty-contacts',
      titles: [_t('emptyState.noContactsFound')],
    };
  }
}

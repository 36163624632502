import { DynamicComponentManifest } from '../dynamic-component-loader/dynamic-component-loader.module';
import { MARKETPLACE_APPS_CONFIG } from '../../../configs/marketplace-apps.config';


// This array defines which "componentId" maps to which lazy-loaded module.
export const MARKETPLACE_DYNAMIC_COMPONENTS: DynamicComponentManifest[] = [
  {
    componentId: MARKETPLACE_APPS_CONFIG.contactDetailsApps.insuranceSummary,
    path: 'dynamic-insurance-summary',
    loadChildren: 'app/modules/marketplace/dynamic/insurance-summary/insurance-summary.module#InsuranceSummaryModule'
  },
  {
    componentId: MARKETPLACE_APPS_CONFIG.contactDetailsApps.snapAndSync,
    path: 'dynamic-snap-and-sync',
    loadChildren: 'app/modules/marketplace/dynamic/snap-and-sync/snap-and-sync.module#SnapAndSyncModule'
  }
];

import { Component, Input, OnInit } from '@angular/core';
import { mergeMap, filter } from 'rxjs/operators';
import { DialogService } from '../../dialog/dialog.service';
import { SubscriptionService } from '../../../api/subscription.service';
import { _t } from 'app/modules/core/other/translate-marker';

@Component({
  selector: 'app-subscription-current',
  templateUrl: './subscription-current.component.html',
  styleUrls: ['./subscription-current.component.scss']
})
export class SubscriptionCurrentComponent implements OnInit {

  private _currentSubscription: any;
  discount: any;

  @Input() set currentSubscription(value: any) {
    this._currentSubscription = value;
    if (value) {
      this.subscriptionService.getSubscriptionDiscount(value.stripe_id)
        .subscribe(discount => {
          this.discount = discount;
        })
    }
  }

  get currentSubscription(): any {
    return this._currentSubscription;
  }

  constructor(
    private dialogService: DialogService,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() { }

  reactivateSubscriptionDialog(): void {
    this.dialogService.confirm(
      _t('shared.reactivateSubscription'),
      _t('shared.subscriptionReactivate'),
      _t('common.confirm')
    )
      .pipe(
        filter(Boolean),
        filter(() => this.currentSubscription),
        mergeMap(() => this.subscriptionService.updateSubscriptionPlan({ plan: this.currentSubscription.plan.stripe_id })),
        mergeMap(() => this.subscriptionService.currentSubscription())
      )
      .subscribe(data => {
        this.currentSubscription = data;
      })
  }

  updateCurrentPlan(): void {
    const target = this.currentSubscription.plan.stripe_id.split('_');
    const mPos = target.indexOf('monthly');
    const yPos = target.indexOf('yearly');
    mPos !== -1 ? target[mPos] = 'yearly' : target[yPos] = 'monthly';

    this.subscriptionService.updateSubscriptionPlan(target.join('_'))
      .pipe(mergeMap(() => this.subscriptionService.currentSubscription()))
      .subscribe(data => {
        this.currentSubscription = data;
      })
  }
}

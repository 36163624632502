import { Component, OnInit } from '@angular/core';
import { WalletService, Transaction } from '../../api/wallet.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-sync-wallet',
  templateUrl: './sync-gem-wallet.component.html',
  styleUrls: ['./sync-gem-wallet.component.scss'],
  providers: [ WalletService]

})
export class SyncGemWalletComponent implements OnInit {
  wallet: { coin: number };
  walletCoin=0;
  constructor(
    private walletService: WalletService,
    private router: Router,
    private localStorage: LocalStorageService,
    ) { }

  ngOnInit() {
    this.getWallet();

  }
  private getWallet(): void {
    if(this.localStorage.retrieve('wallet')) {
      let number = this.localStorage.retrieve('wallet').split(',').join('');
      this.walletCoin = parseInt(number, 10);
    }
    else {
      this.walletService.getWallet().subscribe(data => {
        this.walletCoin=data.coin
        this.localStorage.store('wallet',this.walletCoin.toLocaleString());
       })
    }
    
  }

  onClick():void {
    this.router.navigate(['/account-details/wallet'])
  }
}

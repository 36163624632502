import { DynamicComponentManifest } from '../dynamic-component-loader/dynamic-component-loader.module';
import { MARKETPLACE_DYNAMIC_COMPONENTS } from './marketplace-dynamic-components';

// This array defines which "componentId" maps to which lazy-loaded module.
export const DYNAMIC_COMPONENTS: DynamicComponentManifest[] = [
  {
    componentId: 'standalone-component',
    path: 'dynamic-standalone-component', // some globally-unique identifier, used internally by the router
    loadChildren: 'app/modules/marketplace/dynamic/standalone-component/standalone-component.module#StandaloneComponentModule',
  },

  ...MARKETPLACE_DYNAMIC_COMPONENTS,
];


// Example of dynamically created StandaloneComponent component
// from lazy loaded module requested from code is below

// import { Component, ViewChild, ViewContainerRef } from '@angular/core';
// import { DynamicComponentLoader } from '../dynamic-component-loader/dynamic-component-loader.service';
// import { BaseDynamicComponent } from '../dynamic-component-loader/base-dynamic-component';

// @Component({
//   selector: 'app-example',
//   template: `
//     <button ((click)='loadAndCreateElement()')>
//       Lazy load and create element
//     </button>
//     <div (#testOutlet='')></div>
//   `,
//   styleUrls: ['./404.component.scss']
// })
// export class Page404Component implements OnInit {
//   @ViewChild('testOutlet', { read: ViewContainerRef }) testOutlet: ViewContainerRef;

//   constructor(private dynamicComponentLoader: DynamicComponentLoader) { }

//   ngOnInit() { }

//   loadAndCreateElement(): void {
//     this.dynamicComponentLoader
//       .getComponentFactory<BaseDynamicComponent>(`standalone-component`)
//       .subscribe(componentFactory => {
//         this.testOutlet.createComponent(componentFactory);
//       }, error => {
//         console.warn(error);
//       });
//   }
// }

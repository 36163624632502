import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from '../../configs';


@Injectable()
export class AuthService {
  constructor(private _http: HttpClient) {
  }

  changePassword(data) {
    return this._http.post(API_CONFIG.authWithToken.password, data);
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { _t } from 'app/modules/core/other/translate-marker';

@Component({
  selector: 'app-avatar-uploader',
  templateUrl: './avatar-uploader.component.html',
  styleUrls: ['./avatar-uploader.component.scss']
})
export class AvatarUploaderComponent {
  @Input() formFieldName: string = 'image';
  @Input() label: string = _t('contacts.personal.profilePicture');
  @Input() imageSrc: string = '';
  @Output() formDataUpdated: EventEmitter<FormData> = new EventEmitter(null);

  dragging: boolean = false;
  loaded: boolean = false;
  imageLoaded: boolean = false;

  handleDragEnter() {
    this.dragging = true;
  }

  handleDragLeave() {
    this.dragging = false;
  }

  handleDrop(e) {
    e.preventDefault();
    this.dragging = false;
    this.handleInputChange(e);
  }

  handleImageLoad() {
    this.imageLoaded = true;
  }

  handleInputChange(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      // TODO: handle 'invalid format' error
      return;
    }

    this.loaded = false;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

    const formData = new FormData();
    formData.append(this.formFieldName, file);
    this.formDataUpdated.next(formData);
  }

  private _handleReaderLoaded(e) {
    const reader = e.target;
    this.imageSrc = reader.result;
    this.loaded = true;
  }

  removeLoadedImage(fileUpload1: HTMLInputElement, fileUpload2: HTMLInputElement) {
    const formData = new FormData();
    formData.append(this.formFieldName, '');
    this.formDataUpdated.next(formData);
    this.imageSrc = '';
    this.loaded = false;
    this.imageLoaded = false;
    fileUpload1.value = '';
    fileUpload2.value = '';
  }
}

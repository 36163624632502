import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatSnackBarRef, SimpleSnackBar } from '@angular/material';

type Snack = MatSnackBarRef<SimpleSnackBar>;

export class BaseComponent implements OnDestroy {
  private _subscriptions: Subscription[] = [];
  private _snacks: Snack[] = [];

  get subs(): Subscription | Subscription[] {
    return this._subscriptions;
  }

  set subs(sub: Subscription | Subscription[]) {
    if (sub && (sub instanceof Subscription)) {
      this._subscriptions.push(sub);
    } else if (sub instanceof Array) {
      this._subscriptions = this._subscriptions.concat(sub);
    }
  }

  get snacks(): Snack | Snack[] {
    return this._snacks;
  }

  set snacks(snack: Snack | Snack[]) {
    if (snack && (snack instanceof MatSnackBarRef)) {
      this._snacks.push(snack);
    } else if (snack instanceof Array) {
      this._snacks = this._snacks.concat(snack);
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
    this.dismissAllSnacks();
  }

  protected unsubscribe(sub: Subscription) {
    const idx = this._subscriptions.indexOf(sub);

    if (idx >= 0) {
      this._subscriptions[idx].unsubscribe();
      this._subscriptions.splice(idx, 1);
    }
  }

  protected unsubscribeAll() {
    for (const sub of this._subscriptions) {
      if (sub) {
        sub.unsubscribe();
      }
    }
    this._subscriptions = [];
  }

  protected dismissSnack(snack: Snack) {
    const idx = this._snacks.indexOf(snack);

    if (idx >= 0) {
      this._snacks[idx].dismiss();
      this._snacks.splice(idx, 1);
    }
  }

  protected dismissAllSnacks() {
    for (const snack of this._snacks) {
      if (snack) {
        snack.dismiss();
      }
    }
    this._snacks = [];
  }
}

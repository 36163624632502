declare var $;
export const intlTelInputConfig = {
  autoHideDialCode: false,
  autoPlaceholder: false,
  nationalMode: false,
  numberType: 'MOBILE',
  initialCountry: 'auto',
  geoIpLookup: function(callback) {    $.get('https://ipinfo.io', function() {}, 'jsonp').always(function(resp) {      const countryCode = (resp && resp.country) ? resp.country : '';      callback(countryCode);    });  },
  preferredCountries: [ 'my', 'sg', 'ph' ]
};

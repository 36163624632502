import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject } from 'rxjs';
import { mergeMap, filter, concat } from 'rxjs/operators';
import { AuthService } from '../../auth/services';
import { BaseComponent } from '../../core/base/base-component';
import { IntercomService } from '../intercom/intercom.service';
import { Title } from '@angular/platform-browser';
import { UserProfileService } from './user-profile.service';
import { _t } from 'app/modules/core/other/translate-marker';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [UserProfileService]
})
export class UserProfileComponent extends BaseComponent implements OnInit {
  isOpen = false;
  initials = '';
  photo = '';
  fullName = '';
  @Input() loggedIn: boolean;

  initState = new Subject();
  constructor(
    private authService: AuthService,
    private _intercom: IntercomService,
    private router: Router,
    public titleService: Title,
  ) {
    super();
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event: any) {
    if (event.srcElement && event.srcElement.scrollingElement.scrollTop > 55) {
      this.isOpen = false;
    }
  }

  ngOnInit() {
    this.subscribeOnRouteChanging();
    this.initState.next();
  }

  logout() {
    this.unsubscribeAll();
    this.authService.logout();
    this._intercom.shutDown();
    this.titleService.setTitle('Synchestra');
  }

  /**
   * Emit getting user info on route changing
   */
  subscribeOnRouteChanging(): void {
    this.subs = this.initState
      .pipe(
        concat(this.router.events.pipe(filter(event => event instanceof NavigationEnd))),
        mergeMap(() => this.authService.retrieveAccountDetails()),
        filter(Boolean)
      )
      .subscribe(data => {
        this.photo = data.picture;
        this.fullName = data.first_name + ' ' + data.last_name;

        if (this.fullName) {
          const names: string[] = this.fullName.split(' ');
          this.initials = names[0][0] + (names[1][0] || '');
        }

        // Temporary thing to update all users names in Intercom
        // TODO: remove it when there will not be undefineds
        this.updateIntercom(data);
      });
  }

  toggleState() {
    this.isOpen = !this.isOpen;
  }

  /**
   * Send user data to Intercom
   * @param data - user data
   */
  updateIntercom(data: {email: string, first_name: string, last_name: string, phone: string}): void {
    this._intercom.update({
      email: data.email,
      name: `${data.first_name} ${data.last_name}`,
      phone: data.phone
    });
  }
}

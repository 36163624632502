import { Component } from '@angular/core';
import { ResolutionHelperService } from './../../core/services/resolution/resolution.service';
import { GuideTourId } from './../../core/services/intro.js/contacts/contacts-steps';

@Component({
  selector: 'app-toolbar-actions-button',
  templateUrl: './toolbar-actions-button.component.html',
  styleUrls: ['./toolbar-actions-button.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ToolbarActionsButtonComponent {
  isOpen = false;
  guideTourId: string = GuideTourId;

  get isMobile(): boolean {
    return this.resolutionService.isMobile;
  }

  constructor(private resolutionService: ResolutionHelperService) {}

  toggleState() {
    this.isOpen = !this.isOpen;
  }
}

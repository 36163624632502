import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  @Input() showSyncGemWallet = true;
  _isAnalytics: boolean;

  @Input() isAnalytics: boolean = false;


  constructor() {
  }
}
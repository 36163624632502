import { MARKETPLACE_APPS_CONFIG } from '../../../../../configs/marketplace-apps.config';
import { _t } from 'app/modules/core/other/translate-marker';

const appId = MARKETPLACE_APPS_CONFIG.contactDetailsApps.insuranceSummary;

export const INSURANCE_SUMMARY_STEPS = [
  {
    element: `#${appId}-step-0`,
    intro: _t('contacts.introjs.startByAdding'),
    position: 'right',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-0-tooltip`
  },
  {
    element: `#${appId}-step-1`,
    intro: _t('contacts.introjs.addProspectPolicies'),
    position: 'top',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-1-tooltip`
  },
  {
    element: `#${appId}-step-2`,
    intro: _t('contacts.introjs.summaryGenerated'),
    position: 'top',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-2-tooltip`
  },
  {
    element: `#${appId}-step-3`,
    intro: _t('contacts.introjs.clickOnDownload'),
    position: 'top',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-3-tooltip`
  }
]

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataItem } from '../dashboard/models/data-item.model';
import { Opportunity } from '../opportunities/opportunity-list/opportunity';
import { OpportunityPieItem } from '../dashboard/models/opportunity-pie-item.model';
import { OpportunityResponse } from '../opportunities/opportunity-list/opportunityResponse.interface';
import { API_CONFIG, PARAM } from '../../configs';

@Injectable()
export class OpportunityService {

  private _opportunityForm: FormGroup = null;
  private limit = 12;

  constructor(private apiService: ApiService) {}

  get opportunityForm(): FormGroup {
    return this._opportunityForm;
  }

  set opportunityForm(form: FormGroup) {
    if (form && (form instanceof FormGroup)) {
      this._opportunityForm = form;
    } else {
      this._opportunityForm = null;
    }
  }

  getItems(params): Observable<OpportunityResponse> {

    const url = API_CONFIG.opportunity.list
      .replace(PARAM, params.status_group)
      .replace(PARAM, this.limit.toString())
      .replace(PARAM, params.page)
      .replace(PARAM, params.query)
      .replace(PARAM, params.order_direction)
      .replace(PARAM, params.order_by)
      .replace(PARAM, params.status.toString())
      .replace(PARAM, params.min)
      .replace(PARAM, params.max);

    return this.apiService.get(url);
  }

  createOpportunity(body: Opportunity): Observable<any> {
    return this.apiService.post(API_CONFIG.opportunity.create, body);
  }

  updateOpportunity(slug: string, body: Opportunity): Observable<any> {
    return this.apiService.put(API_CONFIG.opportunity.update.replace(PARAM, slug), body);
  }

  getOpportunity(opportunityId: string): Observable<Opportunity> {
    return this.apiService.get(API_CONFIG.opportunity.get.replace(PARAM, opportunityId));
  }

  deleteOpportunity(opportunityId: string): Observable<any> {
    return this.apiService.delete(API_CONFIG.opportunity.delete.replace(PARAM, opportunityId));
  }

  getOpportuntitiesByContact(contactId: string, statusGroup: string = ''): Observable<Opportunity[]> {
    return this.apiService.get(API_CONFIG.opportunity.byContact
      .replace(PARAM, contactId)
      .replace(PARAM, statusGroup)
    )
      .pipe(map(
        (response: {count: number, next: number, previous: number, results: Opportunity[] }) =>
          response.results
      ));
  }

  getOpportunityPie(startDate: string, endDate: string): Observable<OpportunityPieItem[]> {
    return this.apiService.get(API_CONFIG.opportunity.pie
      .replace(PARAM, startDate)
      .replace(PARAM, endDate)
    );
  }

  getClosingAndCommissions(startDate: string, endDate: string, step: string): Observable<DataItem[]> {
    return this.apiService.get(API_CONFIG.opportunity.closingAndCommissions
      .replace(PARAM, startDate)
      .replace(PARAM, endDate)
      .replace(PARAM, step)
    );
  }
}

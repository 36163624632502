import { _t } from 'app/modules/core/other/translate-marker';

export interface ICompleteDialogConfig {
  title: string;
  message?: string;
  getFreeMessage?: string;
  getFreeInstruction?: string;
  underActionButtonTitle?: string;
  underActionButtonValue?: boolean;
  closeActionTitle: string;
  closeActionValue: boolean;
}

export class DIALOG_COMPLETE_CONFIG {
  public static get SNAP_AND_SYNC(): ICompleteDialogConfig {
    return {
      title: _t('security.syncing'),
      message: _t('security.snapProcessed'),
      getFreeMessage: _t('security.getFreeSnaps'),
      getFreeInstruction: _t('security.findOutHow'),
      underActionButtonTitle: _t('security.viewSnaps'),
      underActionButtonValue: true,
      closeActionTitle: _t('security.finish'),
      closeActionValue: false
    }
  }
  public static get WALLET(): ICompleteDialogConfig {
    return {
      title: _t('contacts.success'),
      closeActionTitle: _t('common.complete'),
      closeActionValue: true
    }
  }
  public static get CLAIMED(): ICompleteDialogConfig {
    return {
      title: _t('contacts.success'),
      message: _t('dashboard.rewardClaimedSuccess'),
      closeActionTitle: _t('security.finish'),
      closeActionValue: true
    }
  }
}

import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { intlTelInputConfig } from './intl-tel-input.config';
import 'intl-tel-input/build/js/intlTelInput.js';
declare var $;

@Directive({
  selector: '[telInput]',
})
export class IntlTelInputDirective implements AfterViewInit {

  @Input() telInput: any;
  @Output() changeCountryEvent = new EventEmitter();

  constructor(
    private el: ElementRef
  ) {}

  ngAfterViewInit() {
    $(this.el.nativeElement).intlTelInput(intlTelInputConfig);
    $(this.el.nativeElement).on('countrychange', (e, countryData) => {
      this.changeCountryEvent.emit((countryData.iso2 || '').toUpperCase());
    });
  }
}

import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { filter, tap, mergeMap, switchMap } from 'rxjs/operators';
import { AuthService } from 'app/modules/auth/services';
import { BaseComponent } from 'app/modules/core/base/base-component';
import { DialogService } from 'app/modules/shared/dialog/dialog.service';
import { ImageUploadDialogService } from './image-dialog/image-dialog.service';
import { Picture } from 'app/modules/core/models/picture';
import { _t } from 'app/modules/core/other/translate-marker';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent extends BaseComponent {

  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() picture: string;

  photoIsLoading: boolean = false;
  userInfo: any;

  constructor(
    private authService: AuthService,
    private dialog: DialogService,
    private imageUploadDialogService: ImageUploadDialogService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private translateService: TranslateService,
  ) {
    super();
  }

  fileChangeListener($event) {
    const file: File = $event.target.files[0];

    if (!file) {
      return;
    }

    if (!file.type.includes('image')) {
      this.toastr.error(this.translateService.instant('contacts.opportunities.wrongFiletype'), '', { timeOut: 7000, closeButton: true });
    } else {
      this.openImageDialog($event, false);
    }
  }

  getAccountDetails() {
    this.subs = this.authService.retrieveAccountDetails()
      .subscribe((response) => {
        this.userInfo = response;
      },
        (error) => { },
      );
  }

  deletePhoto(): void {
    this.getAccountDetails();
    this.subs = this.dialog.confirm(
      _t('contacts.opportunities.deleteProfilePhoto'),
      _t('contacts.opportunities.deleteProfilePhotoMsg'),
      _t('common.confirm')
    )
    .pipe(
      filter(Boolean),
      tap(() => this.photoIsLoading = true),
      mergeMap(() => this.authService.updateFinancialConsultantPhoto({ photo: null, photo_name: 'photo.png' }, this.userInfo.slug))
    )
    .subscribe(() => {
        this.toastr.success(this.translateService.instant('contacts.opportunities.photoSuccessfullyDeleted'), '', { timeOut: 7000, closeButton: true });
        this.picture = null;
      },
      (err) => {
        this.subs = this.translateService.get('common.someErrorsOccur').subscribe((res: string) => {
          this.toastr.error(res, '', { timeOut: 7000, closeButton: true });
        })
      },
      () => this.photoIsLoading = false
    );
  }

  private openImageDialog(event: any, isEdit: boolean) {
    this.subs = this.imageUploadDialogService.openModal(event, isEdit)
      .pipe(
        filter((data: 'saved' | 'close') => data === 'saved'),
        switchMap(() => this.authService.retrieveAccountDetails(true))
      )
      .subscribe((response) => {
        if (response.picture) {
          this.picture = response.picture;
        }
      },
        (err) => err
      );
  }

  clickUpload() {
    this.fileInput.nativeElement.click();
  }
}

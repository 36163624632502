import { NgModule } from '@angular/core';
import { StripeService } from './stripe.service';

@NgModule({
  imports: [],
  providers: [
    StripeService
  ],
  declarations: []
})
export class StripeModule { }

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { filter, finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from 'app/modules/auth/services/auth.service';
import { EventHandlerService } from 'app/modules/core/services/event-handler/event-handler.service';
import { ImageObserverService } from '../image-observer.service';
import { RouteBoundDialog } from 'app/modules/core/route-bound-dialog/route-bound-dialog.class';
import { RouteBoundDialogService } from 'app/modules/core/route-bound-dialog/route-bound-dialog.service';
import { _t } from 'app/modules/core/other/translate-marker';

@Component({
  selector: 'image-upload-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageUploadDialogComponent extends RouteBoundDialog implements OnInit, AfterViewInit {

  errorMessage = '';
  imageBlob: Blob = null;
  imgName = '';
  isEdit = false;
  isSaving = false;
  loadedImage: any;
  width: number;
  userInfo: any;

  constructor(
    public dialogRef: MatDialogRef<ImageUploadDialogComponent>,
    private authService: AuthService,
    public observerService: ImageObserverService,
    public routeBoundDialogService: RouteBoundDialogService
  ) {
    super();
    this.width = window.innerWidth < 530 ? window.innerWidth - 30 : 480;
  }

  ngOnInit() {
    this.getAccountDetails();
    this.cropperImageSubscriber();
  }

  ngAfterViewInit() {
    this.fileChangeListener(this.loadedImage);
  }

  private cropperImageSubscriber(): void {
    this.subs = this.observerService.imageDataSource$
      .pipe(filter((source: any) => source && source.type === 'cropperImage'))
      .subscribe(source => {
        this.imageBlob = source.data;
      });
  }

  fileChangeListener(event) {
    const file: File = event.target.files[0];
    event.target.value = null;

    if (!file) {
      return;
    }

    if (!file.type.includes('image')) {
      this.errorMessage = _t('contacts.opportunities.wrongFiletype');
      return;
    }

    this.observerService.emit({ type: 'cropperFile', data: file });
    this.imgName = file.name;
  }

  getAccountDetails() {
    this.subs = this.authService.retrieveAccountDetails()
      .subscribe((response) => {
        this.userInfo = response;
      },
        (error) => { },
      );
  }

  onSubmit() {
    this.errorMessage = '';
    this.isSaving = true;

    this.subs = this.authService.updateFinancialConsultantPhoto({
      photo: this.imageBlob,
      photo_name: this.imgName
    }, this.userInfo.slug)
      .pipe(finalize(() => this.isSaving = false))
      .subscribe((data) => {
        this.dialogRef.close('saved');
      },
      () => {
        this.errorMessage = _t('contacts.opportunities.failedUploadImage');
      });
  }

  onClose() {
    this.dialogRef.close('close');
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { LocalStorageService } from 'ngx-webstorage';

import { API_CONFIG, PARAM } from '../../configs';

@Injectable()
export class PersonalLinkService {

  public get slug() {
    return this._storage.retrieve('userForm')['slug'];
  }

  constructor(
    private _http: HttpClient,
    private _storage: LocalStorageService
  ) { }

  public checkAvailability(queryParams) {
    const params = this._setHttpParams(queryParams);
    return this._http.get(API_CONFIG.profileBuilder.checkAvailability, { params });
  }

  public save(data) {
    return this._http.patch(API_CONFIG.profileBuilder.update.replace(PARAM, this.slug), data);
  }

  private _setHttpParams(queryParams) {
    let params = new HttpParams();
    for (const index in queryParams) {
      if (queryParams.hasOwnProperty(index)) {
        params = params.append(index, queryParams[index]);
      }
    }
    return params;
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ViewChild,
  ViewContainerRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

export interface ITab {
  label: string;
  content: any;
  data?: any;
  isDisabled?: boolean;
  isInvalid?: boolean;
  id?: string;
}

@Component({
  selector: 'tabs-component',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterViewInit {
  @Input() selectedTabIndex: number = null;
  @Input() tabs;
  @Output() tabSelected: EventEmitter<number> = new EventEmitter(null);
  @ViewChild('tabContent', { read: ViewContainerRef }) tabContent: ViewContainerRef;

  constructor(private cd: ChangeDetectorRef, private cfr: ComponentFactoryResolver) {}

  onTabChange(tabIndex: number) {
    const factory = this.cfr.resolveComponentFactory(this.tabs[tabIndex]['content']);
    this.tabContent.clear();
    const componentRef = this.tabContent.createComponent(factory);
    componentRef.instance['data'] = this.tabs[tabIndex].data;
    this.tabSelected.next(tabIndex);
  }

  ngAfterViewInit() {
    this.onTabChange(this.selectedTabIndex || 0);
    this.cd.detectChanges();
  }
}

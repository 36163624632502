import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/modules/core/base/base-component';
import { ViralLoopsService } from '../../services/viral-loops.service';

@Component({
  selector: 'altruistic-widgets',
  templateUrl: './altruistic-widgets.component.html',
  styleUrls: ['./altruistic-widgets.component.scss']
})
export class AltruisticWidgetsComponent extends BaseComponent implements OnInit {
  constructor(
    private viralLoopsService: ViralLoopsService,
  ) {
    super();
  }

  ngOnInit() {
    this.viralLoopsService.loadWidgets();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import * as differenceInMilliseconds from 'date-fns/difference_in_milliseconds';
import { _t } from 'app/modules/core/other/translate-marker';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'ageing'
})
export class AgeingPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {}

  transform(source: string, args?: any): string {

    let result = '';
    const date = new Date();
    const sourceDate = new Date(source);

    const dateDiff = new Date(differenceInMilliseconds(date, sourceDate));

    const yearsDiff = dateDiff.getFullYear() - 1970;
    const monthDiff = dateDiff.getMonth();
    const daysDiff = dateDiff.getDate() - 1;

    const daysSting = daysDiff   === 1 ? this.translateService.instant('common.day') : this.translateService.instant('common.days');
    const monthSting = monthDiff === 1 ? this.translateService.instant('shared.month') : this.translateService.instant('common.months');
    const yearSting = yearsDiff  === 1 ? this.translateService.instant('shared.year') : this.translateService.instant('common.years');

    if (yearsDiff === 0 && daysDiff === 0  && monthDiff === 0) return this.translateService.instant('common.new');
    if (yearsDiff > 0) return result += `${yearsDiff} ${yearSting} ${(monthDiff > 0) ? '+' : ''}` ;
    if (monthDiff > 0) return result += `${monthDiff} ${monthSting}  ${(daysDiff > 0) ? '+' : ''}`;
    if (daysDiff  > 0) return result += `${daysDiff} ${daysSting} `;

    return result;
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventHandlerService } from '../../core/services/event-handler';
import { DialogService } from '../dialog/dialog.service';
import { DialogOpportunityFiltersComponent } from './dialog-opportunity-filters/dialog-opportunity-filters.component';
import { SearchFiltersObserverService } from './search-filters-observer.service';
import { BaseComponent } from '../../core/base/base-component';
import { FormChoicesService } from '../../core/services/form-helpers/form-choises.service';
import { DialogActivityFiltersComponent } from './dialog-activity-filters/dialog-activity-filters.component';
import { DialogContactsFiltersComponent } from './dialog-contacts-filters/dialog-contacts-filters.component';
import { debounceTime, filter } from 'rxjs/operators';
import { _t } from 'app/modules/core/other/translate-marker';
import { SearchService } from './search.service';
import { SearchFilters } from './filters.model';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() activeTab: string;
  @Input() page = 'contacts';

  form: FormGroup;
  RangeForm: FormGroup;

  filterInputs: SearchFilters = new SearchFilters();
  ignoreSearch: boolean = false;

  searchParams: any;
  allButtonValue = true;
  selectedStatuses = [];
  activityTypes: any;
  opportunityStatuses: any;

  isOpen = false;
  isAllSelected = true;
  isStatusOpen = false;
  isStatusSelected = false;
  isAmountOpen = false;
  isAmountSelected = false;
  selectedFilter: any;

  initialFiltersData: any = {
    query: '',
    min: '',
    max: '',
    status: '',
    rating: [],
    lead: '',
    prospect: '',
    client: '',
    pending: '',
    postponed: '',
    cancelled: '',
    completed: '',
    activity_type: []
  };

  filterActions: any[] = [
    { id: '', text: _t('common.all') },
    { id: 'lead', text: _t('common.lead') },
    { id: 'prospect', text: _t('common.prospect') },
    { id: 'client', text: _t('common.client') }
  ];

  ratingRange: number[] = [1, 2, 3, 4, 5];
  clientTypes = [_t('common.lead'), _t('common.prospect'), _t('common.client')];
  searchEmitter = EventHandlerService.get('search_emitter');

  constructor(
    private dialogService: DialogService,
    private formBuilder: FormBuilder,
    private filtersObserver: SearchFiltersObserverService,
    private formChoises: FormChoicesService,
    private searchService: SearchService,
    private router: Router
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeTab && changes.activeTab.currentValue && !changes.activeTab.firstChange) {
      this.filtersObserver.emit('all', true, 'blur');
      this.updateOpportunityStatus();
    }
  }

  private filtersSubscriber(): void {
    this.subs = this.filtersObserver.filtersSource$
      .subscribe((data: any) => {
        if (data.type === 'all' && data.value === true) {
          return this.setInitialFilters();
        }

        if (data.type && data.type !== 'all') {
          this.searchParams[data.type] = data.value;
        }

        this.allButtonHighlightHandler();
        this.searchEmitter.emit(this.searchParams);
      });
  }

  private subscribeFilterChanges(): void {
    this.subs = this.searchEmitter.subscribe(params => {
      if(params.advanced_filters) {
        this.filterInputs = Object.assign({}, params.advanced_filters);
      }
    });
  }

  private searchFormSubscriber(): void {
    this.form.valueChanges.pipe(debounceTime(500))
      .subscribe(data => {
        // include search query in advanced_filters
        if (this.router.url.includes('contacts')) {
          if (this.ignoreSearch) {
            this.ignoreSearch = false;
            return;
          }
          this.filterInputs.searchText = data.query_input;
          this.filterInputs.isFilterApplied = true;
          this.filtersObserver.emit('advanced_filters', this.filterInputs, 'blur');
        } 
        // search query is not part of advanced_filters
        else {
          this.searchParams.query = data.query_input;
          this.allButtonHighlightHandler();
          this.searchEmitter.emit(this.searchParams);
        }
      });
  }

  private allButtonHighlightHandler(): void {
    let flag = true;

    for (const key in this.searchParams) {
      if (this.searchParams.hasOwnProperty(key) && key !== 'all') {
        if (this.hasFiledFilters(this.searchParams[key])) {
          flag = false; continue;
        }
      }
    }

    this.allButtonValue = flag;
  }

  private setInitialFilters(): void {
    this.allButtonValue = true;
    this.searchParams = { ...this.initialFiltersData };
    this.form.controls.query_input.setValue('');
  }


  private hasFiledFilters(val: any): boolean {
    return (typeof val === 'boolean' && val === true) || (typeof val === 'number' && val !== 0) || val.length;
  }

  public ngOnInit() {
    this.selectedFilter = this.filterActions[0];
    this.activityTypes = this.formChoises.ACTIVITY_TYPES;
    this.updateOpportunityStatus();

    this.form = this.formBuilder.group({
      query_input: ['']
    });
    this.RangeForm = this.formBuilder.group({
      min: ['', Validators.min(0)],
      max: ['', [Validators.min(1), Validators.max(999999)]]
    });

    this.searchParams = { ...this.initialFiltersData };
    this.filtersSubscriber();
    this.subscribeFilterChanges();
    this.searchFormSubscriber();

    this.subs = this.searchService.searchParam$.subscribe(res => {
      this.ignoreSearch = true;
      this.form.controls.query_input.setValue('');
    });
  }

  public toggleState() {
    this.isOpen = !this.isOpen;
  }

  public resetFilters(): void {
    this.resetRangeForm();
    this.selectedStatuses = [];
    this.searchParams = { amount: '', status: '', min: '', max: '' };
    this.searchEmitter.emit(this.searchParams);
  }

  public filterItemsByAmount(): void {
    const form = this.RangeForm.value;
    this.isAllSelected = false;
    this.searchParams.min = form.min || '';
    this.searchParams.max = form.max || '';
    this.toggleActions('amount');
    this.searchEmitter.emit(this.searchParams);
  }

  public openOpportunityFiltersDialog(): void {
    this.dialogService.custom(DialogOpportunityFiltersComponent,
      {
        opportunityStatuses: this.opportunityStatuses,
        min: this.searchParams.min,
        max: this.searchParams.max,
        selectedStatuses: this.selectedStatuses
      },
      {
        panelClass: 'dialog-opportunity-filters-mobile'
      })
      .pipe(filter(result => result))
      .subscribe(data => {
        this.searchParams = Object.assign({}, this.searchParams, data);
        this.searchEmitter.emit(this.searchParams);
      });
  }

  public openActivityFiltersDialog(): void {
    let activityTypes = {};

    if (this.activeTab === 'open') {
      activityTypes = [
        { id: 'pending', value: this.searchParams.pending },
        { id: 'postponed', value: this.searchParams.postponed }
      ];
    } else {
      activityTypes = [
        { id: 'cancelled', value: this.searchParams.cancelled },
        { id: 'completed', value: this.searchParams.completed }
      ];
    }

    this.dialogService.custom(DialogActivityFiltersComponent,
      {
        activityTypes: activityTypes
      },
      {
        panelClass: 'dialog-activity-filters-mobile'
      })
      .pipe(filter(result => result))
      .subscribe(data => {
        if (data === 'resetAll') {
          return this.filtersObserver.emit('all', true, 'blur');
        }
        this.searchParams = Object.assign({}, this.searchParams, data);
        this.searchEmitter.emit(this.searchParams);
      });
  }

  // TODO: Move all filters for every entity to separate components with dialogs and specific methods
  public openContactsFiltersDialog(): void {

    this.dialogService.custom(DialogContactsFiltersComponent,
      {
        clientTypes: this.clientTypes,
        ratingRange: this.ratingRange,
        searchParams: this.searchParams,
      },
      {
        panelClass: 'dialog-contacts-filters-mobile'
      })
      .pipe(filter(result => result))
      .subscribe(data => {
        if (data === 'resetAll') {
          return this.filtersObserver.emit('all', true, 'blur');
        }
        this.searchParams = Object.assign({}, this.searchParams, data);
        this.searchEmitter.emit(this.searchParams);
      });
  }

  // TODO: On refactoring apply created components for toggle buttons and status filter, create amount filter component
  private resetRangeForm(): void {
    this.RangeForm.reset();
  };

  public toggleActions(action): void {
    switch (action) {
      case 'all':
        this.isAllSelected = true;
        this.isAmountOpen = false;
        this.isAmountSelected = false;
        this.isStatusOpen = false;
        this.isStatusSelected = false;
        break;
      case 'amount':
        this.isStatusOpen = false;
        this.isAmountOpen = !this.isAmountOpen;
        this.highlightAmountButton();
        this.highlightStatusButton();
        break;
      case 'status':
        this.isAmountOpen = false;
        this.isStatusOpen = !this.isStatusOpen;
        this.highlightAmountButton();
        this.highlightStatusButton();
        break;
    }
  }

  public filterItemsByStatuses(id: string): void {
    if (!this.checkSelectedStatus(id)) {
      this.selectedStatuses.push(id);
    } else {
      this.selectedStatuses.splice(this.selectedStatuses.indexOf(id), 1);
    }
    this.searchParams.status = this.selectedStatuses;
    this.highlightAllButton();
    this.highlightStatusButton();
    this.searchEmitter.emit(this.searchParams);
  }

  public checkSelectedStatus(id: string): boolean {
    return this.selectedStatuses.indexOf(id) !== -1;
  }

  private highlightStatusButton(): void {
    this.isStatusSelected = this.searchParams.status.length !== 0;
  }

  private highlightAmountButton(): void {
    this.isAmountSelected = this.searchParams.min || this.searchParams.max;
  }

  private highlightAllButton(): void {
    this.isAllSelected = this.isSelectorsEmpty() ? true : false;
  }

  private isSelectorsEmpty(): boolean {
    return !((this.selectedStatuses.length !== 0) || this.searchParams.min || this.searchParams.max);
  }

  private updateOpportunityStatus(): void {
    this.opportunityStatuses = this.formChoises.OPPORTUNITY_STATUSES_TYPES.filter(status => status.group === this.activeTab);
  }

}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dialog-option-card',
  templateUrl: './dialog-option-card.component.html',
  styleUrls: ['./dialog-option-card.component.scss']
})
export class DialogOptionCardComponent implements OnInit {

  @Input() option: DialogOption;
  @Output() optionSelected: EventEmitter<DialogOption> = new EventEmitter(null);

  constructor() { }

  ngOnInit() {
  }

  getBackgroundImage(iconName: string): string {
    return `url('assets/images/icons/${iconName}')`;
  }
}

export class DialogOption {
  name: string;
  title: string;
  icon: string;

  constructor(name: string, title: string, icon: string) {
    this.name = name;
    this.title = title;
    this.icon = icon;
  }
}

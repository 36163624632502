import { Component, OnInit, ViewChild, HostListener, forwardRef, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorBase } from '../../core/base/control-value-accessor-base';
import { FormDatesService } from '../../core/services/form-helpers/form-dates.service';
import { isDate } from 'date-fns';
import { ValidationService } from '../../shared/validation/validation.service';
import { Validators } from '@angular/forms/src/validators';


@Component({
  selector: 'app-custom-date-picker',
  templateUrl: './custom-date-picker.component.html',
  styleUrls: ['./custom-date-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CustomDatePickerComponent),
    multi: true
  }]
})
export class CustomDatePickerComponent extends ControlValueAccessorBase implements OnInit {

  @Input() max: Date;
  @Input() min: Date;
  @Input() placeholder: string;
  @Input() showButtons: boolean;
  @Input() type = 'both';
  @ViewChild('container') container;

  dateFormat: string;
  dateMask: (string | RegExp)[];
  datePickerIsOpened = false;
  validationRegexp: RegExp;
  valueString: string;

  /**
   * Document click handler which closes dropdowns if click was out of them
   * @param event - click event object
   */
  @HostListener('document:click', ['$event'])
  outClickHandler(event: Event): void {
    if (!this.container.nativeElement.contains(event.target) && this.datePickerIsOpened) { // check click origin
      this.toggleDatepicker(false);
      this.onValueStringChange();
    }
  }

  constructor(
    private datePipe: DatePipe,
    private formDatesService: FormDatesService
  ) {
    super();
  }

  get valueStringIsValid(): boolean {
    return this.valueString && !!this.valueString.match(this.validationRegexp);
  }


  ngOnInit() {
    this.defineParams();
    setTimeout(() => {
      this.onValueStringChange();
    }, 0)
  }

  /**
   * Runs during control init
   * @param value - input value
   */
  writeValue(value: any): void {
    if (typeof this.value === 'string' && this.value) {
      this.value = new Date(this.value);
    } else {
      this.value = value;
    }

    this.setValueString();
  }

  /**
   * On Confirm buttons click
   */
  dateConfirmed(): void {
    this.toggleDatepicker(false);
    this.setValueString();
  }

  /**
   * On date time picker value change
   */
  dateSelected(): void {
    if (this.type === 'calendar') {
      this.toggleDatepicker(false);
      this.setValueString();
    }
  }

  /**
   * Define params which depends on date time picker type ('calendar', 'timer', 'both')
   */
  defineParams(): void {
    switch (this.type) {
      case 'both':
        this.dateFormat = 'dd/MM/y HH:mm';
        this.dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/];
        this.showButtons = true;
        this.validationRegexp = ValidationService.REGEX.DATE_TIME_REGEX;
        break;
      case 'calendar':
        this.dateFormat = 'dd/MM/y';
        this.dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        this.showButtons = false;
        this.validationRegexp = ValidationService.REGEX.DATE_REGEX;
        break;
      case 'timer':
        this.dateFormat = 'HH:mm';
        this.dateMask = [/\d/, /\d/, ':', /\d/, /\d/];
        this.showButtons = true;
        this.validationRegexp = ValidationService.REGEX.TIME_REGEX;
        break;
    }
  }

  /**
   * Clear control of typed string value is not invalid
   */
  onInputBlur(): void {
    // Set touched on blur
    this.onTouched();
  }

  onValueStringChange(): void {
    if (this.valueStringIsValid) {
      const d = +this.valueString.substring(0, 2);
      const m = +this.valueString.substring(3, 5) - 1;
      const y = +this.valueString.substring(6, 10);
      const h = +this.valueString.substring(11, 13);
      const min = +this.valueString.substring(14, 16);

      const datetime = new Date(y, m, d, h, min);

      // Update date value when new value is valid and not equal to current
      if (isDate(datetime) && (!isDate(this.value) || this.value.getTime() !== datetime.getTime())) {
        this.value = datetime;
        // needed for handling incorrect dates like 30/02/2015 etc.
        this.setValueString();
      }
    } else {
      if (this.value !== this.valueString) {
        this.value = this.valueString;
      }
    }
  }

  setValueString(): void {
    this.valueString = this.datePipe.transform(this.value, this.dateFormat);
  }

  toggleDatepicker(open: boolean): void {
    this.datePickerIsOpened = open;
  }
}

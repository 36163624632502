import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../../../../api/api.service';
import { API_CONFIG, PARAM } from '../../../../../configs';

@Injectable()
export class FilterGroupService {

  filterGroupsSub = new Subject<any>();
  filterGroupsSub$ = this.filterGroupsSub.asObservable();

  constructor(private apiService: ApiService) {}

  getFilterGroups(): Observable<any> {
    return this.apiService.get(API_CONFIG.contact.getSegments);
  }

  createFilterGroup(filterGroup): Observable<any> {
    return this.apiService.post(API_CONFIG.contact.createSegment, filterGroup);
  }

  updateFilterGroup(slug: string, filterGroup): Observable<any> {
    return this.apiService.patch(API_CONFIG.contact.updateSegment.replace(PARAM, slug), filterGroup);
  }

  notifyFilterGroupChange() {
    return this.filterGroupsSub.next();
  }

}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { DefaultPages, RoutesPermissions } from './permissions.config';

@Injectable()
export class PermissionsGuard implements CanActivate {

  previousUrl: string;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(e => {
      this.previousUrl = e['url'];
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const route = RoutesPermissions.find(item => item.fullPath === next.data.fullPath);

    if (route && this.userIsLogged) {

      if (this.userHasActiveSubscription) {

        // If user has permission to route
        if (route.permissions[this.authService.currentUser().plan_group] === 'allow') {

          // If user goes to root route '/' ('') guard navigates him to default route according the role
          if (next.data.fullPath === '') {
            this.router.navigate([DefaultPages[this.authService.currentUser().plan_group]]);
            return false;
          }

          return true;
        }

        // If user came from login page guard navigates him to default route according the role
        if (this.previousUrl === '/auth/login') {
          this.router.navigate([DefaultPages[this.authService.currentUser().plan_group]]);
          return false;
        }
      }

      // If user is navigating to 'upgrade' page guard passes params to show correct dynamic page title
      this.router.navigate(['/upgrade', {title: next.data.navTitle, targetRoute: next.data.fullPath}]);
      return false;
    }

    return true;
  }

  get userIsLogged(): boolean {
    return !!this.authService.currentUser();
  }

  get userHasActiveSubscription(): boolean {
    return this.authService.currentUser().has_active_subscription;
  }
}

import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SearchFiltersObserverService {

  private filterSource = new Subject<any>();

  public filtersSource$ = this.filterSource.asObservable();

  constructor() { }

  public emit(type: string, value: any, action?: string): void {
    this.filterSource.next({ type: type, value: value, action: action });
  }
}

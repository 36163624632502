import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG, PARAM } from '../../configs';
import { ApiService } from './api.service';

@Injectable()
export class ReferralService {

  constructor(private apiService: ApiService) {}

  getSwMilestone(): Observable<any> {
    return this.apiService.get(API_CONFIG.referralWidgets.getSWMilstone);
  }

  getSyncbizMilestone(): Observable<any> {
    return this.apiService.get(API_CONFIG.referralWidgets.getSyncbizMilstone);
  }

  claimSWReward(slug: string): Observable<any> {
    return this.apiService.post(API_CONFIG.referralWidgets.claimSWReward.replace(PARAM,slug), null);
  }

  claimSBReward(slug: string): Observable<any> {
    return this.apiService.post(API_CONFIG.referralWidgets.claimSBReward.replace(PARAM,slug), null);
  }

}

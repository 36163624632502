import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'countdown',
  pure: true
})

export class CountdownPipe implements PipeTransform {
  transform(textLength: number, maxValue: number): number {
    let maxLength: number = maxValue || 0;
    let length: number = textLength || 0;

    return length > 0 ? (maxLength - length) : length;
  }
}

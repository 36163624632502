import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ImageObserverService {

  private imageDataSource = new Subject<{
  	type: string;
  	data: any;
  }>();

  public imageDataSource$ = this.imageDataSource.asObservable();

  constructor() { }

  public emit(source: any): void {
    this.imageDataSource.next({ type: source.type, data: source.data });
  }
}

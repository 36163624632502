import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { filter, first } from 'rxjs/operators';
import { interval } from 'rxjs';
import { BaseComponent } from '../../core/base/base-component';
import { DialogObserverService } from '../../core/services/dialog-observer/dialog-observer.service';
import { _t } from 'app/modules/core/other/translate-marker';

interface Breadcrumbs {
  currentTitle: string;
  ancestors: Array<{
    path: string;
    title: string;
  }>;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent extends BaseComponent implements OnInit {
  breadcrumbs: Breadcrumbs;
  isUpgrade = false;
  pagesWithNewButton: string[] = [_t('common.activities.activities'), _t('contacts.contactsTitle'), _t('contacts.opportunities.opportunities')];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialogObserverService: DialogObserverService,
    private router: Router,
    private titleService: Title,
    private translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.updateBreadcrumbs();

    this.subs = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.updateBreadcrumbs();
    });
  }

  goTo(path: string): void {
    this.router.navigate(['/' + path]);
  }

  openNewForm(): void {
    const snapshot = this.activatedRoute.snapshot.data['fullPath'].substr(1);

    switch (snapshot) {
      case 'contacts':
        this.dialogObserverService.emit('add_contact');
        break;
      case 'opportunities':
        this.dialogObserverService.emit('add_opportunity');
        break;
      case 'activities':
        this.dialogObserverService.emit('add_activity');
        break;
    }
  }

  private handleUpgradePageBreadcrumbs(): void {
    if (this.breadcrumbs.currentTitle === _t('common.upgrade.upgrade') && this.activatedRoute.snapshot.params.title) {
      this.breadcrumbs.currentTitle = this.activatedRoute.snapshot.params.title;
      this.isUpgrade = true;
    }
  }

  private updateBreadcrumbs(): void {
    this.breadcrumbs = this.activatedRoute.snapshot.data.breadcrumbs;

    if (this.breadcrumbs) {
      this.handleUpgradePageBreadcrumbs();

      // Set page title when language json is loaded
      this.subs = interval(1000).pipe(
        filter(() => this.translateService.instant('security.synchestra') !== 'security.synchestra'),
        first()
      ).subscribe(() =>
        this.titleService.setTitle(this.translateService.instant('security.synchestra') + ' :: ' + this.translateService.instant(this.breadcrumbs.currentTitle))
      )
    }
  }
}

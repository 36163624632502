import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable()
export class PlanUpgradeService {
	private _planUpgradeParams: Params;
	private _coupon: string;
	private _planType: string;
	private _planDuration: string;

	get planUpgradeParams(): Params {
    return this._planUpgradeParams;
  }

  set planUpgradeParams(params: Params) {
    this._planUpgradeParams = params;
  }

  get coupon(): string {
    return this._coupon;
  }

  set coupon(coupon: string) {
    this._coupon = coupon;
  }

  get planType(): string {
    return this._planType;
  }

  set planType(planType: string) {
    this._planType = planType;
  }

  get planDuration(): string {
    return this._planDuration;
  }

  set planDuration(planDuration: string) {
    this._planDuration = planDuration;
  }
}
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "orderBy"
})
export class ArraySortPipe implements PipeTransform {
  transform(array: Array<string>, args: string): Array<string> {
    array.sort((a: any, b: any) => {

      if (a.new_rank < b.new_rank) {
        return -1;
      } else if (a.new_rank > b.new_rank) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatRadioChange } from '@angular/material';

import { PlansSet } from '../subscription-table/subscription-table.component';
import { SubscriptionPlan } from '../../../api/subscription.service';


@Component({
  selector: 'app-plan-interval-switcher',
  templateUrl: './plan-interval-switcher.component.html',
  styleUrls: ['./plan-interval-switcher.component.scss']
})
export class PlanIntervalSwitcherComponent implements OnInit {
   // Reason of using union type with undefined - https://github.com/angular/angular-cli/issues/2034
  @Input() set upgradePlan(plan: PlansSet | undefined) {
    if (plan) {
      this.plan = plan;
      this.selectedPlan = this.plan.year;
    }
  };
  @Output() planChanged: EventEmitter<string> = new EventEmitter(null);


  set selectedPlan(plan: SubscriptionPlan) {
    this._selectedPlan = plan;
    this.planChanged.emit(this._selectedPlan.stripe_id);
  }
  get selectedPlan(): SubscriptionPlan {
    return this._selectedPlan;
  }

  plan: PlansSet | undefined;

  private _selectedPlan: SubscriptionPlan;

  constructor() { }

  ngOnInit() { }

  onPlanChanged(plan: SubscriptionPlan): void {
    this.selectedPlan = plan;
  }

  calcDiscount(plan: PlansSet): string {
    return `${Math.round((plan.month.amount * 12 - plan.year.amount) / (plan.year.amount / 100))}%`;
  }
}

import { Component, ElementRef, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ValidationService } from './validation.service';

@Component({
  selector: 'validation-messages',
  template: `<div class="validation-messages" *ngIf="errorMessage !== null">{{errorMessage | translate}}</div>`
})
export class ValidationMessagesComponent {
  @Input() control: FormControl;
  @Input() form: FormGroup;

  constructor(private elementRef: ElementRef, private validationService: ValidationService) {
  }

  get errorMessage() {
    if (this.control.status === 'INVALID') {

      for (const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName) && (this.form['submitted'] || this.control.touched)) {
          this.elementRef.nativeElement.previousElementSibling.classList.add('validation-messages-error');

          let message: any;
          if (propertyName === 'formErrors') {
            message = this.validationService.handleFormErrorMessage(propertyName, this.control.errors[propertyName]);
          } else {
            message = this.validationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
          }
          return message;
        }
      }
    } else {
      this.elementRef.nativeElement.previousElementSibling.classList.remove('validation-messages-error');
    }

    return null;
  }

}

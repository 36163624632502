import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';


import { ToastrService } from 'ngx-toastr';
import { UserTokenService } from '../auth/services/user.token.service';
import { Router } from '@angular/router';
import { DialogService } from '../shared/dialog/dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private userTokenService: UserTokenService,
    private translateService: TranslateService,
    private router: Router,
    private dialog: DialogService,
  ) { }

  showError(errorText: string) {
    this.toastr.error(errorText, '', {
      timeOut: 7000,
      closeButton: true
    });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: Response) => {

          switch (true) {
            case error.url && error.url.indexOf('personalized_link') !== -1:
              throwError(error);
              break;

            case error.status === 401:
              this.userTokenService.remove();
              this.router.navigate(['/auth/login']);
              console.error('The authentication session expires or the user is not authorised');
              break;

            case error.status === 402:
              this.router.navigate(['/upgrade']);
              console.error('User don\'t have permissions for this action');
              break;

            case error.status === 403:
              this.showError(this.translateService.instant('common.httpErrors.pleaseVerify'));
              break;

            case error.status === 404:
              this.router.navigate(['404']);
              console.error('Requested resource not found');
              break;

            case error.status === 429:
              this.dialog.confirm(
                null,
                'We\'re experiencing high traffic from your location. Please try again in 1 minute.'
              );
              break;
          }

          let errorText: Response | string = '';
          try {
            if (error && error['_body']) {
              const body: Object = error.json();
              Object.keys(body).forEach((key: string) => {
                const errors: Array<string> = body[key];
                errors.forEach(err => this.showError(err));
                errorText += errors.join('\n');
              });
            } else if ( error &&
                        error['error'] &&
                        error['error']['error'] &&
                        error['error']['error']['message']) {
              // TODO: must be fixed on BE
              this.showError(error['error']['error']['message']);
            } else if ( error &&
                        error['error'] &&
                        Object.keys(error['error'])) {
              if (error['error'].hasOwnProperty('personalized_link')) {
                this.showError(this.translateService.instant('common.httpErrors.ensureThatPersonalized'));
              }
            }
          } catch (err) {
            errorText = error.statusText || <string>this.translateService.instant('common.httpErrors.serverError');
            this.showError(errorText);
          }

          if (!errorText) {
            errorText = error;
          }

          return throwError(errorText);
        })
      );
  }
}

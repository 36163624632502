import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

export interface Tab {
  label: string;
  value: any;
}

@Component({
  selector: 'app-tabs-toggler',
  templateUrl: './tabs-toggler.component.html',
  styleUrls: ['./tabs-toggler.component.scss']
})
export class TabsTogglerComponent implements AfterViewInit {
  @Input() tabs;
  @Output() tabSelected: EventEmitter<number> = new EventEmitter(null);

  constructor() { }

  onTabChange(tabIndex: number) {
    this.tabSelected.next(tabIndex);
  }

  ngAfterViewInit() {
    this.onTabChange(0);
  }
}

export interface ILanguageData {
  code: string;
  name: string;
  isDefault?: true;
}

export interface ICountryCurrency {
  code: string;
  name: string;
  symbol: string;
  format: string;
  countryCode: string;
  isDefault?: boolean;
}

export interface ICountryData {
  code: string;
  name: string;
  localeId: string;
  alpha3Code: string;
  flag: string;
}

export class LOCALIZATION_CONFIG {
  public static get SUPPORTED_COUNTRIES(): ICountryData[] {
    return [
      {
        code: 'SG',
        localeId: 'en-SG',
        name: 'Singapore',
        alpha3Code: 'SGP',
        flag: 'https://restcountries.eu/data/sgp.svg',
      },
      {
        code: 'MY',
        localeId: 'ms',
        name: 'Malaysia',
        alpha3Code: 'MYS',
        flag: 'https://restcountries.eu/data/mys.svg',
      },
      {
        code: 'PH',
        localeId: 'fil',
        name: 'Philippines',
        alpha3Code: 'PHL',
        flag: 'https://restcountries.eu/data/phl.svg',
      },
      {
        code: 'ID',
        localeId: 'en-SG',
        name: 'Indonesia',
        alpha3Code: 'IDN',
        flag: 'https://restcountries.eu/data/idn.svg',
      },
      {
        code: 'TH',
        localeId: 'th',
        name: 'Thailand',
        alpha3Code: 'THA',
        flag: 'https://restcountries.eu/data/tha.svg',
      },
      {
        code: 'HK',
        localeId: 'en-HK',
        name: 'Hong Kong',
        alpha3Code: 'HKG',
        flag: 'https://restcountries.eu/data/hkg.svg',
      },
      {
        code: 'CN',
        localeId: 'zh',
        name: 'China',
        alpha3Code: 'CHN',
        flag: 'https://restcountries.eu/data/chn.svg',
      },
      {
        code: 'MO',
        localeId: 'en-MO',
        name: 'Macao',
        alpha3Code: 'MAC',
        flag: 'https://restcountries.eu/data/mac.svg',
      },
      {
        code: 'TW',
        localeId: 'zh-Hant',
        name: 'Taiwan',
        alpha3Code: 'TWN',
        flag: 'https://restcountries.eu/data/twn.svg',
      },
      {
        code: 'AU',
        localeId: 'en-AU',
        name: 'Australia',
        alpha3Code: 'AUS',
        flag: 'https://restcountries.eu/data/aus.svg',
      }
    ]
  }

  public static get SUPPORTED_CURRENCIES(): ICountryCurrency[] {
    return [
      { code: 'SGD', name: 'Singapore Dollar',     symbol: 'S$',   countryCode: 'SG', format: '1.2-2'  },
      { code: 'MYR', name: 'Malaysian Ringgit',    symbol: 'RM',   countryCode: 'MY', format: '1.2-2'  },
      { code: 'PHP', name: 'Philippine Peso',      symbol: '₱',    countryCode: 'PH', format: '1.2-2'  },
      { code: 'IDR', name: 'Indonesian Rupiah',    symbol: 'Rp',   countryCode: 'ID', format: '1.2-2'  },
      { code: 'THB', name: 'Thai Baht',            symbol: '฿',    countryCode: 'TH', format: '1.2-2'  },
      { code: 'HKD', name: 'Hong Kong Dollar',     symbol: 'HK$',  countryCode: 'HK', format: '1.2-2'  },
      { code: 'TWD', name: 'New Taiwan Dollar',    symbol: 'NT$',  countryCode: 'TW', format: '1.2-2'  },
      { code: 'MOP', name: 'Macanese Pataca',      symbol: 'MOP$', countryCode: 'MO', format: '1.2-2'  },
      { code: 'CNY', name: 'Chinese Yuan',         symbol: '¥',    countryCode: 'CN', format: '1.2-2'  },
      { code: 'AUD', name: 'Australian Dollar',    symbol: 'AU$',  countryCode: 'AU', format: '1.2-2'  },
      { code: 'USD', name: 'United States Dollar', symbol: '$',    countryCode: 'US', format: '1.2-2', isDefault: true  }
    ]
  }

  public static get SUPPORTED_LANGUAGES(): ILanguageData[] {
    return [
      { code: 'en', name: 'English', isDefault: true },
      { code: 'ms', name: 'Bahasa Melayu' },
      { code: 'zh-hans', name: '简体中文' },
      { code: 'zh-hant', name: '繁體中文' },
    ]
  }

  public static GET_LOCALE_ID(code: string): string {
    return (this.SUPPORTED_COUNTRIES.filter(v => v.code === code)[0] || this.SUPPORTED_COUNTRIES[0]).localeId;
  }

  public static GET_CURRENCY_SYMBOL(code: string): string {
    return (this.SUPPORTED_CURRENCIES.filter(v => v.code === code)[0] || this.SUPPORTED_CURRENCIES[0]).symbol;
  }

  public static GET_CURRENCY_FORMAT(code: string): string {
    return (this.SUPPORTED_CURRENCIES.filter(v => v.code === code)[0] || this.SUPPORTED_CURRENCIES[0]).format;
  }

  public static get GET_CURRENCY_SYMBOLS(): string[] {
    return this.SUPPORTED_CURRENCIES.map(v => v.symbol);
  }

  public static GET_COUNTRY_DATA(code: string): ICountryData {
    return (this.SUPPORTED_COUNTRIES.filter(v => v.code === code)[0] || this.SUPPORTED_COUNTRIES[0]);
  }

  public static GET_COUNTRY_CURRENCY(countryCode: string): ICountryCurrency {
    return (this.SUPPORTED_CURRENCIES.filter(v => v.countryCode === countryCode)[0] || this.SUPPORTED_CURRENCIES[0]);
  }
}

import { Injectable } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { filter, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Contact } from '../../contacts/models/contact.model';
import { ContactFormDialogComponent } from './contact-form-dialog.component';
import { DialogObserverService } from 'app/modules/core/services/dialog-observer/dialog-observer.service';

@Injectable()
export class ContactFormDialogService {
  dialogObserver: DialogObserverService;

  constructor (private dialog: MatDialog, private router: Router) {}

  initSubscription(dialogObserver: DialogObserverService)  {
    if (!this.dialogObserver) {
      this.dialogObserver = dialogObserver;
      this.dialogRequestSubscriber();
    }
  }

  openContactFormDialog(contact: Contact): Observable<Contact | false> {

    let dialogRef: MatDialogRef<ContactFormDialogComponent>;
    dialogRef = this.dialog.open(ContactFormDialogComponent);

    // init dialog component properties
    dialogRef.componentInstance.contact = JSON.parse(JSON.stringify(contact));

    return dialogRef.afterClosed();
  }

  private dialogRequestSubscriber(): void {
    this.dialogObserver.dialogRequest$.pipe(
      filter(data => data.type === 'add_contact'),
      switchMap(() => this.openContactFormDialog(new Contact())),
      filter(Boolean)
    )
    .subscribe((contact: Contact) =>
      this.router.navigate(['contacts', contact.slug])
    );
  }
}

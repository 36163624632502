import { Component, OnInit, Inject} from '@angular/core';
import { DialogService } from './../../shared/dialog/dialog.service';
import { MatDialogRef } from '@angular/material';
import { BaseComponent } from 'app/modules/core/base/base-component';
@Component({
  selector: 'app-consultant-referral-dialog',
  templateUrl: './consultant-referral-dialog.component.html',
  styleUrls: ['./consultant-referral-dialog.component.scss']
})
export class ConsultantReferralDialogComponent extends BaseComponent implements OnInit {

  facebookEncodeUrl: string;
  referralLink: string;
  twitterUrl: string;
  whatsappUrl: string;
  linkedinUrl: string;
  inputBoxUrl: string;

  socialLinks: {class: string, href: string, display: boolean, backgroundColor: string}[];

  constructor( public dialogRef: MatDialogRef<ConsultantReferralDialogComponent>,
    public dialog: DialogService,) {
      super()
     }

  ngOnInit() {
    var utm_params = '&utm_source=syncbiz_web&utm_campaign=syncbiz_referral';
    this.referralLink = this.referralLink + utm_params;
    this.facebookEncodeUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.referralLink+"&utm_medium=facebook")}`;
    this.twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(this.referralLink + "&utm_medium=twitter")}`;
    this.whatsappUrl = `https://wa.me/?text=${encodeURIComponent(this.referralLink + "&utm_medium=whatsapp")}`;
    this.linkedinUrl = `http://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(this.referralLink + "&utm_medium=linkedin")}`;
    this.inputBoxUrl = this.referralLink + "&utm_medium=direct";

    this.socialLinks = [
      { class: 'icon-ico-facebook', href: this.facebookEncodeUrl, display: true, backgroundColor: '#4A6098' },
      { class: 'icon-ico-twitter', href: this.twitterUrl, display: true, backgroundColor: '#53A7E1' },
      { class: 'icon-ico-linkedin', href: this.linkedinUrl, display: true, backgroundColor: '#3D7DB3' },
      { class: 'icon-ico-whatsapp', href: this.whatsappUrl, display: true, backgroundColor: '#65D072' },
      { class: 'icon-ico-synb-email', href: `mailto:?subject=${this.referralLink + "&utm_medium=email"}`, display: true, backgroundColor: '#4D97B6' },
    ];
  }

  showSuccessNotification(): void {
    this.snacks = this.dialog.showSnackBar('Copied to clipboard');
  }
}

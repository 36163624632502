export enum StepDirection {
  backward = 'backward',
  forward = 'forward'
}

export interface IIntrojsStep {
  element: string;
  intro: string;
  position: string;
  disableInteraction: boolean;
  highlightClass: string;
  tooltipClass: string;
  custom?: {
    fixParents?: NodeListOf<Element>,
    matDialogOverlay?: Element
  };
};

export class IntroJSCurrentStep {
  constructor(
    public stepNumber: number,
    public direction: StepDirection
  ) {}
}

interface IntrojsStepFakeData {
  number: number;
  fakeData?: any;
}

export interface IInsuranceSummaryIntrojsStepFakeData extends IntrojsStepFakeData {}

export interface IContactsIntrojsStepFakeData extends IntrojsStepFakeData {}

export interface SasIntrojsStepFakeData extends IntrojsStepFakeData {
  headerIcon: string;
}

import { Dependent } from './dependent.model';
import { FamilyMember } from './family-member.model';
import { Priority } from './priority.model';
import { RiskProfile } from './risk-profile.model';

export class FinancialInformation {
  birth_date?: string | Date = null;
  claimed_against_insurer?: string = '';
  consumes_alcohol?: string = '';
  contact_methods?: Array<string> = [];
  country?: string;
  created?: Date = null;
  dependents?: Dependent[] = [];
  email?: string = null;
  employment_type?: string = null;
  family_histories?: FamilyMember[] = [];
  fixed_deposits?: string | number = null;
  full_name?: string = '';
  gender?: 'M' | 'F' = null;
  gross_annual_income?: number | string = null;
  had_extreme_flights?: string = '';
  has_doctor?: string = '';
  height?: string = '';
  hereditary_potential?: string = '';
  hospitalized?: string = '';
  household_expenses?: number | string;
  insurance_premiums?: number | string = null;
  investments?: number | string = null;
  marital_status?: string = null;
  modified?: Date = null;
  mortgage_loan?: number | string = null;
  motor_loan?: number | string = null;
  notes?: string = '';
  occupation?: string = '';
  education_loan?: number | string = null;
  other_savings?: any[] = [];
  other_loan?: any[] = [];
  other_annual_expenses?: any[] = [];
  other_annual_income?: any[] = [];
  pdpa_consent?: string = 'pending';
  pdpa_consent_status?: boolean = false;
  personal_expenses?: number | string = null;
  phone_number?: string = '';
  picture?: any;
  preferred_name?: string = '';
  previously_rejected?: string = '';
  priorities: Priority[] = [];
  regular_savings?: number | string = null;
  risk_profile: RiskProfile = new RiskProfile();
  savings?: number | string = null;
  serious_illness_diagnosed?: string = '';
  slug?: string = '';
  smoked_cigarettes?: string = '';
  title?: string = '';
  used_drugs?: string = '';
  weight?: string = '';
  weight_changed?: string = '';

  constructor() {};
}

import { Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

/* tslint:disable:component-selector*/

/**
 * Abstract class that includes implementation of the ControlValueAccessor interface for component's extends.
 *
 * For use ControlValueAccessorBase, as 'extends' class, it's necessary to write:
 * @Component({
 *  providers: [
 *      {
 *          provide: NG_VALUE_ACCESSOR,
 *          useExisting: forwardRef(() => *COMPONENT_CLASS*),
 *          multi: true
 *      }
 *  ],
 *  ...
 */
export abstract class ControlValueAccessorBase implements ControlValueAccessor  {
  @Input('value') public _value;

  public onChange: Function = () => {};
  public onTouched: Function = () => {};

  public get value(): any {
    return this._value;
  }

  public set value(value: any) {
    this._value = value;
    this.onChange(value);
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }
}

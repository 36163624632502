import { Headers, Request, RequestOptions, RequestOptionsArgs, Response, XHRBackend } from '@angular/http';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { PaymentService } from './payment.service';
import { PersonalLinkService } from './personal-link.service';
import { ProfileService } from './profile.service';
import { SubscriptionService } from './subscription.service';
import { LocationData } from './location-data.model';
import { environment } from '../../../environments/environment';

export class JsonGetOptions extends RequestOptions {
  constructor() {
    super({
      headers: new Headers({'Accept': 'application/json'})
    });
  }
}

export class JsonPostOptions extends JsonGetOptions {
  constructor() {
    super();
    this.headers.append('Accept', 'application/json');
    this.headers.append('Content-Type', 'application/json');
  }
}

export class FormHeadersOptions extends RequestOptions {
  constructor() {
    super();
    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }
}

@Injectable()
export class ApiService {
  constructor(
    public personalLInk: PersonalLinkService,
    public auth: AuthService,
    public payment: PaymentService,
    public subscription: SubscriptionService,
    public profile: ProfileService,
    private http: HttpClient
  ) { }

  getLocationData(): Observable<LocationData> {
    return <any>this.http.get(`https://ipinfo.io?token=${environment.ipinfoKey}`);
  }

  protected setHeaders(options: RequestOptionsArgs, url?: string): any {
    if (options.headers == null) {
      options.headers = new Headers();
      options = new JsonPostOptions();
    } else {
      options = new RequestOptions();
    }

    return options;
  }

  get(url: string): Observable<any> {
    return this.http.get(url, this.setHeaders(new JsonGetOptions(), url));
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(url, data, this.setHeaders(new JsonPostOptions(), url));
  }

  putFormData(url: string, data: any): Observable<any> {
    return this.http.put(url, data, this.setHeaders(new JsonPostOptions(), url));
  }

  put(url: string, data: any): Observable<any> {
    return this.http.put(url, data, this.setHeaders(new JsonPostOptions(), url));
  }

  delete(url: string): Observable<any> {
    return this.http.delete(url, this.setHeaders(new JsonPostOptions(), url));
  }

  patch(url: string, data: any): Observable<any> {
    return this.http.patch(url, data, this.setHeaders(new JsonPostOptions(), url));
  }

  patchFormData(url: string, data: any): Observable<any> {
    return this.http.patch(url, data, this.setHeaders(new JsonPostOptions(), url));
  }

  handleResponse(response: Response): Object {
    try {
      return response.text() ? response.json() : {};
    } catch (ex) {
        return {};
    }
  }
}


export const DefaultPages = {
  no_subscription: '/auth/need-activate',
  fc_free_plan: '/profile/builder/edit',
  fc_basic_plan: '/profile/builder/edit',
  fc_standard_plan: '/contacts',
}
class Permissions {
  no_subscription: string;
  fc_basic_plan: string;
  fc_standard_plan: string;

  constructor(no_subscription: string, fc_basic_plan: string, fc_standard_plan: string) {
    this.no_subscription  = no_subscription;
    this.fc_basic_plan = fc_basic_plan;
    this.fc_standard_plan = fc_standard_plan;
  }
}

class RoutePermissions {
  permissions: Permissions;
  fullPath: string;

  constructor(permissions: Permissions, fullPath: string) {
    this.permissions = permissions;
    this.fullPath = fullPath;
  }
}

// TODO: Check and remove if required upgrade when no_subscription
export const RoutesPermissions = [
  //                                  no_subscr | basic | standard | full route
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), ''),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/profile/builder'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/profile/builder/edit'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/profile/builder/settings'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/account-details'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/account-details/personal-details'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/account-details/password'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/account-details/plan'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/account-details/payment'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/account-details/billing-history'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/contacts'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/contacts/:id'),
  new RoutePermissions(new Permissions('upgrade', 'upgrade', 'allow'), '/dashboard'),
  new RoutePermissions(new Permissions('upgrade', 'upgrade', 'allow'), '/activities'),
  new RoutePermissions(new Permissions('upgrade', 'upgrade', 'allow'), '/opportunities'),
  new RoutePermissions(new Permissions('upgrade', 'upgrade', 'allow'), '/opportunities/details/:id'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/marketplace'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/marketplace/services'),
  new RoutePermissions(new Permissions('upgrade', 'allow', 'allow'), '/marketplace/applications')
];

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { RouteBoundDialog } from '../../../core/route-bound-dialog/route-bound-dialog.class';
import { RouteBoundDialogService } from '../../../core/route-bound-dialog/route-bound-dialog.service';

@Component({
  selector: 'app-dialog-activity-filters',
  templateUrl: './dialog-activity-filters.component.html',
  styleUrls: ['./dialog-activity-filters.component.scss']
})
export class DialogActivityFiltersComponent extends RouteBoundDialog implements OnInit {

  private result = {};
  public dialogData = [];

  constructor(
    public dialogRef: MatDialogRef<DialogActivityFiltersComponent>,
    public routeBoundDialogService: RouteBoundDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

  public ngOnInit() {
    this.dialogData = [...this.data.activityTypes];
  }

  private prepareSubmit(): any {
    this.dialogData.forEach(v => this.result[v.id] = v.value);
    return this.result;
  }

  public onSubmit(): void {
    this.dialogRef.close(this.prepareSubmit());
  }

  public resetAll(): void {
    this.dialogRef.close('resetAll');
  }

  public toggleStatus(index: number): void {
    this.dialogData[index].value = !this.dialogData[index].value;
  }

  public checkSelectedStatus(index: number): boolean {
    return this.dialogData[index].value;
  }
}

import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { PersonalLinkService } from './personal-link.service';
import { AuthInterceptor } from './auth.interceptor';
import { BaseUrlInterceptor } from './base-url.interceptor';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { LanguageInterceptor } from './language.interceptor';
import { PaymentService } from './payment.service';
import { SubscriptionService } from './subscription.service';
import { ProfileService } from './profile.service';

@NgModule({
  imports: [
    HttpClientModule,
  ],
  providers: [
    ApiService,
    PersonalLinkService,
    AuthService,
    PaymentService,
    SubscriptionService,
    ProfileService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
  ],
})
export class ApiModule {
}

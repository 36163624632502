import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { API_CONFIG, PARAM } from '../../configs';
import { ApiService } from './api.service';
import { ClientRatingPieItem } from '../dashboard/models/client-rating-pie-item.model';
import { Contact } from '../contacts/models/contact.model';
import { ContactFinanceService } from '../contacts/shared/contact-finance.service';
import { DataItem } from '../dashboard/models/data-item.model';
import { FormDatesService } from './../core/services/form-helpers/form-dates.service';
import { FinancialInformation } from '../contacts/models/financial-information.model';
import { ContactPieItem } from '../dashboard/models/contact-pie-item.model';

export interface AutocompleteContact {
  full_name: string;
  phone: string;
  slug: string;
  email: string;
}

@Injectable()
export class ContactService {

  constructor(
    private apiService: ApiService,
    private contactFormFinanceService: ContactFinanceService,
    private formDatesService: FormDatesService
  ) { }

  /**
   * Retreive from API current user's Contacts by passed query
   */
  getAutocompleteContacts(query?: string): Observable<AutocompleteContact[]> {
    return this.apiService.get(API_CONFIG.contact.autocompleteContacts.replace(PARAM, query))
      .pipe(map(
        (response: {count: number, next: number, previous: number, results: AutocompleteContact[] }) =>
        response.results
      ));
  }

  /**
   * Retrieve client ratings for pie chart
   * @param startDate - string representation of start date
   * @param endDate - string representation of end date
   */
  getClientsRatingsPie(startDate: string, endDate: string): Observable<ClientRatingPieItem[]> {
    return this.apiService.get(API_CONFIG.contact.clientsRatingsPie
      .replace(PARAM, startDate)
      .replace(PARAM, endDate)
    );
  }

   /**
   * Retrieve contact data for pie chart
   */
  getContactPie(): Observable<ContactPieItem> {
    return this.apiService.get(API_CONFIG.contact.contactPie);
  }

  /**
   * Retrieve number of contacts for pie chart
   * @param startDate - string representation of start date
   * @param endDate - string representation of end date
   * @param step - chart step
   */
  getNumberOfContacts(startDate: string, endDate: string, step: string): Observable<DataItem[]> {
    return this.apiService.get(API_CONFIG.contact.numberOfContacts
      .replace(PARAM, startDate)
      .replace(PARAM, endDate)
      .replace(PARAM, step)
    );
  }

  /**
   * Post new contact to API
   */
  sendNewContact(body: Contact): Observable<any> {
    return this.apiService.post(API_CONFIG.contact.create, body);
  }

  /**
   * Update contact image
   * @param financtionInformationId - contact financial information id (slug)
   * @param formData - form data with image
   */
  sendContactImage(financtionInformationId: string, formData: FormData): Observable<any> {
    return this.apiService.patchFormData(API_CONFIG.financialInformation.get
      .replace(PARAM, financtionInformationId), formData);
  }

  /**
   * Retreive from API current contact info
   * @param slug - contact id
   */
  getContact(slug: string): Observable<any> {
    return this.apiService.get(API_CONFIG.contact.get.replace(PARAM, slug))
      .pipe(map(response => {
        this.contactFormFinanceService.parseFinanceFormDataFromApi(response['financial_information']);
        return response;
      }));
  }

  /**
   * Update (Patch) on API new contact
   * @param slug - contact id
   */
  sendPartUpdateContact(slug: string, body: Contact | any): Observable<Contact> {
    let contactPicture: FormData;

    if (body && body.financial_information) {
      // Check was picture updated?
      if (body.financial_information.picture instanceof FormData) {
        contactPicture = body.financial_information.picture;
        body.financial_information.picture = null;
      } else {
        delete body.financial_information.picture;
      }

      // Preformat birthdate
      if (body.financial_information.birth_date) {
        body.financial_information.birth_date = this.formDatesService.formatDateToInternational(body.financial_information.birth_date);
      }
    }

    return this.apiService.patch(API_CONFIG.contact.update.replace(PARAM, slug), body)
      .pipe(
        map((contact: Contact) => {
          this.contactFormFinanceService.parseFinanceFormDataFromApi(contact.financial_information);

          return contact;
        }),
        switchMap((contact: Contact) => {
          // Update picture if it was updated and saved by user
          if (contactPicture) {
            return this.sendContactImage(contact.financial_information.slug, contactPicture).pipe(
              map((finInfo: FinancialInformation) => {
                contact.financial_information.picture = finInfo.picture;

                return contact;
              }),
            )
          } else {
            return of(contact)
          }
        })
      );
  }
}

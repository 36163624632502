import {Component, Input, OnInit} from '@angular/core';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-inline-error',
  templateUrl: './inline-error.component.html',
  styleUrls: ['./inline-error.component.scss']
})
export class InlineErrorComponent implements OnInit {
  @Input() error: string;

  constructor() {
  }

  ngOnInit() {
  }

}

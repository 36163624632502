import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LOCALIZATION_CONFIG } from 'app/configs/localization.config';
import { LocalStorageService } from 'ngx-webstorage';

export const LocalStorageLanguageKey = 'language';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(private storage: LocalStorageService) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const languageCode = this.storage.retrieve(LocalStorageLanguageKey) || LOCALIZATION_CONFIG.SUPPORTED_LANGUAGES.find(l => l.isDefault).code;
    req = req.clone({ headers: req.headers.set('Accept-Language', languageCode)});

    return next.handle(req);
  }
}

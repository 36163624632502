import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Activity } from '../activities/models/activity.model';
import { API_CONFIG, PARAM } from '../../configs';
import { ApiService } from './api.service';
import { DataItem } from '../dashboard/models/data-item.model';

@Injectable()
export class ActivityService {

  private _activityForm: FormGroup = null;
  private limit = 12;

  constructor(private apiService: ApiService) {}

  get activityForm(): FormGroup {
    return this._activityForm;
  }

  set activityForm(form: FormGroup) {
    if (form && (form instanceof FormGroup)) {
      this._activityForm = form;
    } else {
      this._activityForm = null;
    }
  }

  /**
   * Retreive from API current user's Activities
   */
  getActivities(params: any): Observable<Activity[]> {
    const statuses = [];

    if (params.pending) {
      statuses.push('pending');
    }
    if (params.postponed) {
      statuses.push('postponed');
    }
    if (params.cancelled) {
      statuses.push('cancelled');
    }
    if (params.completed) {
      statuses.push('completed');
    }

    const url = API_CONFIG.activity.list
      .replace(PARAM, params.status_group)
      .replace(PARAM, this.limit.toString())
      .replace(PARAM, params.page)
      .replace(PARAM, params.query)
      .replace(PARAM, params.order_direction)
      .replace(PARAM, params.order_by)
      .replace(PARAM, statuses.toString());

    return this.apiService.get(url);
  }

  getMomentum(startDate: string, endDate: string, step: string): Observable<DataItem[]> {
    return this.apiService.get(API_CONFIG.activity.momentum
      .replace(PARAM, startDate)
      .replace(PARAM, endDate)
      .replace(PARAM, step)
    );
  }

  getUpcomingActivities(utc_time: string): Observable<[any]> {
    return this.apiService.get(API_CONFIG.activity.upcoming.replace(PARAM, utc_time))
      .pipe(map(data => data.results));
  }

  getActivitiesRelatedToContact(slug: string): Observable<any> {
    return this.apiService.get(API_CONFIG.activity.relatedToContact.replace(PARAM, slug))
      .pipe(map(data => data.results));
  }

  getActivitiesRelatedToOpportunity(slug: string, opportunity_slug: string): Observable<any> {
    return this.apiService.get(API_CONFIG.activity.relatedToOpportunity
      .replace(PARAM, slug)
      .replace(PARAM, opportunity_slug)
    )
      .pipe(map(data => data.results));
  }

  /**
   * Create Activity
   */
  createActivity(body: Activity): Observable<any> {
    return this.apiService.post(API_CONFIG.activity.create, body);
  }

  /**
   * Update Activity
   */
  updateActivity(activitySlug: string, body: Activity): Observable<any> {
    return this.apiService.put(API_CONFIG.activity.update.replace(PARAM, activitySlug), body);
  }

  /**
   * Delete Activity
   */
  deleteActivity(activitySlug: string): Observable<any> {
    return this.apiService.delete(API_CONFIG.activity.delete.replace(PARAM, activitySlug));
  }

 /**
   * Get task list
   */
  getTaskList(): Observable<any> {
    return this.apiService.get(API_CONFIG.consultant.consultantTasks)
      .pipe(map(data => data.results));
  }

   /**
   * mark as completed
   */
  markComplete(taskSlug: string): Observable<any> {
   return this.apiService.post(API_CONFIG.consultant.markComplete.replace(PARAM, taskSlug), null);
  }

   /**
   * claim reward
   */
  claimReward(taskSlug: string): Observable<any> {
    return this.apiService.post(API_CONFIG.consultant.claimReward.replace(PARAM, taskSlug),null);
  }
  
}

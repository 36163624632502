import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import * as crypto from 'crypto-js';

// Intercom widget
declare global {
  interface Window {
    Intercom(command: string, options?: any): any;
  }
}

@Injectable()
export class IntercomService {

  constructor() {

  }

  public boot(email: string, disableWidget: boolean = false) {
    let bootUserInfo = {
      hide_default_launcher: disableWidget,
      app_id: environment.intercomAppId
    };

    // Add identity verification
    if (email) {
      bootUserInfo = Object.assign(bootUserInfo, {
        email,
        user_hash: crypto.enc.Hex.stringify(crypto.HmacSHA256(email, environment.intercomSecretKey))
      });
    }

    interval(600).pipe(take(1)).subscribe(() => {
      window.Intercom('boot', bootUserInfo);
    });
  }

  public update(data) {
    let updateInfo: any = {};

    updateInfo = Object.assign(updateInfo, data);

    // Add identity verification
    if (updateInfo.email) {
      updateInfo = Object.assign(updateInfo, {
        user_hash: crypto.enc.Hex.stringify(crypto.HmacSHA256(updateInfo.email, environment.intercomSecretKey))
      });
    }

    interval(600).pipe(take(1)).subscribe(() => {
      window.Intercom('update', updateInfo);
    });
  }

  public shutDown(disableWidget: boolean = false) {
    interval(600).pipe(take(1)).subscribe(() => {
      window.Intercom('shutdown');

      if (!disableWidget) {
        this.boot(null);
      }
    });
  }

  public openChat(): void {
    window['Intercom']('showNewMessage');
  }
}


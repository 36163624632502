import { MARKETPLACE_APPS_CONFIG } from '../../../../../configs/marketplace-apps.config';
import { _t } from 'app/modules/core/other/translate-marker';

const appId = MARKETPLACE_APPS_CONFIG.contactDetailsApps.snapAndSync;

export const SNAP_AND_SYNC_STEPS = [
  {
    element: `#${appId}-step-0`,
    intro: _t('contacts.introjs.getStartedSnapSync'),
    position: 'bottom',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-0-tooltip`,
    custom: {
      fixParents: null
    }
  },
  {
    element: `#${appId}-step-1`,
    intro: _t('contacts.introjs.addPolicyFirst'),
    position: 'bottom',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-1-tooltip`,
    custom: {
      fixParents: null,
      matDialogOverlay: null
    }
  },
  {
    element: `#${appId}-step-2`,
    intro: _t('contacts.introjs.uploadPhotos'),
    position: 'bottom',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-2-tooltip`,
    custom: {
      fixParents: null,
      matDialogOverlay: null
    }
  },
  {
    element: `#${appId}-step-3`,
    intro: _t('contacts.introjs.addAnotherPolicy'),
    position: 'top',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-3-tooltip`,
    custom: {
      fixParents: null,
      matDialogOverlay: null
    }
  },
  {
    element: `#${appId}-step-4`,
    intro: _t('contacts.introjs.confirmYourOrder'),
    position: 'top',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-4-tooltip`,
    custom: {
      fixParents: null,
      matDialogOverlay: null
    }
  },
  {
    element: `#${appId}-step-5`,
    intro: _t('contacts.introjs.checkStatuses'),
    position: 'top',
    disableInteraction: true,
    highlightClass: 'bg-white',
    tooltipClass: `${appId}-step-5-tooltip`,
    custom: {
      fixParents: null,
      matDialogOverlay: null
    }
  }
]

import { Component, Input, OnInit } from '@angular/core';
import { SearchFiltersObserverService } from '../search-filters-observer.service';
import { BaseComponent } from '../../../core/base/base-component';

@Component({
  selector: 'app-search-filter-toggle',
  templateUrl: './search-filter-toggle.component.html',
  styleUrls: ['./search-filter-toggle.component.scss']
})
export class SearchFilterToggleComponent extends BaseComponent implements OnInit {

  @Input() title: string;
  @Input() property: string;
  @Input() value: boolean;

  constructor(private observer: SearchFiltersObserverService) {
    super();
    this.subs = this.observer.filtersSource$.subscribe(data => {
      if (data.type === 'all' && data.value && this.title !== 'all') {
        return this.value = false;
      }
      if (data.type === 'all' && data.value) {
        this.value = true;
      }
    });
  }

  public ngOnInit() {
    this.property = this.property ? this.property : this.title;
  }

  public toggleValue(): void | boolean {
    if (this.title === 'all' && this.value === true) {
      return false;
    }
    this.value = !this.value;
    this.observer.emit(this.property, this.value, 'blur');
  }

  public isActive(): boolean {
    return this.value;
  }
}

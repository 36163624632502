import { Routes } from '@angular/router';

import { MarketplaceRoutes } from './modules/marketplace/routed/marketplace-routes';

import { AuthGuard } from './modules/auth/services/auth.guard.service'
import { PermissionsGuard } from './modules/auth/services/permissions/permissions.guard';

import { PrivacyPolicyComponent } from './components/privacy-policy';
import { UpgradePageComponent } from './components/upgrade-page/upgrade-page.component';
import { TermsConditionsComponent } from './components/terms-and-condition';
import { RouteBoundDialogGuard } from './modules/core/route-bound-dialog/route-bound-dialog.guard';
import { _t } from './modules/core/other/translate-marker';
import { PremiumPlanDetailsComponent } from './modules/shared/subscription-plans/premium-plan-details/premium-plan-detail.component';


export const MainNavRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: 'app/modules/dashboard/dashboard.module#DashboardModule',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    data: {
      navTitle: _t('dashboard.dashboard'),
      icon: 'dashboard'
    }
  },
  {
    path: 'contacts',
    loadChildren: 'app/modules/contacts/contact.module#ContactModule',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    data: {
      navTitle: _t('contacts.contactsTitle'),
      icon: 'contacts'
    }
  },
 /* {
    path: 'activities',
    loadChildren: 'app/modules/activities/activities.module#ActivitiesModule',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    data: {
      navTitle: _t('common.activities.activities')
    }
  },
   {
    path: 'opportunities',
    loadChildren: 'app/modules/opportunities/opportunity.module#OpportunityModule',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    data: {
      navTitle: _t('contacts.opportunities.opportunities')
    }
  },
  {
    path: 'marketplace',
    loadChildren: 'app/modules/marketplace/main/marketplace.module#MarketplaceModule',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    data: {
      navTitle: _t('common.marketplace'),
      icon: 'marketplace'
    }
  },*/
];

export const AppRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, PermissionsGuard, RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    pathMatch: 'full',
    redirectTo: 'contacts'
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard, PermissionsGuard, RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    loadChildren: 'app/modules/dashboard/dashboard.module#DashboardModule',
    data: {
      navTitle: _t('dashboard.dashboard'),
      fullPath: '/dashboard'
    }
  },
  {
    path: 'auth',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    loadChildren: 'app/modules/auth/auth.module#AuthModule'
  },
  {
    path: 'onboarding',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    loadChildren: 'app/modules/onboarding/onboarding.module#OnboardingModule'
  },
  {
    path: 'profile-onboarding',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    loadChildren: 'app/modules/profile-onboarding/profile-onboarding.module#ProfileOnboardingModule'
  },
  {
    path: '6fp-registration',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    loadChildren: 'app/modules/registration/registration.module#RegistrationModule'
  },
  ...MainNavRoutes,
  {
    path: 'profile',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    loadChildren: 'app/modules/profile/profile.module#ProfileModule'
  },
  {
    path: 'account-details',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    loadChildren: 'app/modules/account/account.module#AccountModule'
  },
  {
    path: 'terms-and-conditions',
    component: TermsConditionsComponent,
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    data: {
      title: _t('common.terms.termsAndConditions')
    }
  },
  {
    path: 'privacy-policy',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    component: PrivacyPolicyComponent
  },
  {
    path: 'upgrade',
    canActivate: [RouteBoundDialogGuard, AuthGuard],
    canActivateChild: [RouteBoundDialogGuard],
    component: UpgradePageComponent,
    data: {
      breadcrumbs: {
        currentTitle: _t('common.upgradeYourPlan')
      }
    }
  },
  {
    path: 'upgrade/premium-plan',
    canActivate: [RouteBoundDialogGuard, AuthGuard],
    canActivateChild: [RouteBoundDialogGuard],
    component: PremiumPlanDetailsComponent,
    data: {
      breadcrumbs: {
        currentTitle: _t('common.upgradeYourPlan')
      }
    }
  },
  {
    path: 'financial-freedom-calculator',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    loadChildren: './modules/financial-freedom-calculator/financial-freedom-calculator.module#FinancialFreedomCalculatorModule'
  },
  ...MarketplaceRoutes,
  {
    path: 'marketplace',
    loadChildren: 'app/modules/marketplace/main/marketplace.module#MarketplaceModule',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
  },
  {
    path: '**',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    redirectTo: '404'
  },
  {
    path: '404',
    canActivate: [RouteBoundDialogGuard],
    canActivateChild: [RouteBoundDialogGuard],
    loadChildren: './modules/404/404.module#Page404Module'
  }
];

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ContactDetailsObserverService {

  // This service created to handle requests related to contact details page
  // example: after closing dynamicaly compiled component insurance-summary we
  // pass close_insurance_summary event and catch it in contact/details.component.ts
  // to show common layout.

  private source = new Subject<any>();
  public source$ = this.source.asObservable();

  emit(source: { title: string, value: any }): void {
    this.source.next(source);
  }

}

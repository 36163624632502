import { Component, OnInit, Output, EventEmitter, ViewChild, HostListener, Input } from '@angular/core';
import { FormDatesService } from '../../core/services/form-helpers/form-dates.service';
import { _t } from 'app/modules/core/other/translate-marker';

export enum DateRangeOptions {
  Last7Days = 'security.last7Days',
  Last30Days = 'security.last30Days',
  Week = 'security.week',
  Month = 'payment.month',
  Quarter = 'security.quarter',
  Year = 'security.year',
  CustomRange = 'security.customRange'
}

@Component({
  selector: 'app-custom-date-range',
  templateUrl: './custom-date-range.component.html',
  styleUrls: ['./custom-date-range.component.scss'],
})
export class CustomDateRangeComponent implements OnInit {

  @Input() initialRangeOption: string = _t(DateRangeOptions.Month);
  @Input() rangeOptions: string[] = [
    _t(DateRangeOptions.Last7Days),
    _t(DateRangeOptions.Week),
    _t(DateRangeOptions.Month),
    _t(DateRangeOptions.Quarter),
    _t(DateRangeOptions.Year),
    _t(DateRangeOptions.CustomRange)
  ];
  @Input() dropdownArrowIconVisible: boolean = false;
  @Output() onDateRangeChange: EventEmitter<{start: string, end: string}> = new EventEmitter(null);
  @ViewChild('container') container;

  customDateRangeModel: Date[];
  datePickerIsOpened = false;
  dateRange: { startDate: Date, endDate: Date } = { startDate: null, endDate: null };
  selectedRangeOption: string;
  optionsIsOpened = false;

  /**
   * Document click handler which closes dropdowns if click was out of them
   * @param event - click event object
   */
  @HostListener('document:click', ['$event'])
  outClickHandler(event: Event): void {
    if (!this.container.nativeElement.contains(event.target)) { // check click origin
      this.closeDropdowns();
    }
  }

  constructor(private formDatesService: FormDatesService) { }

  ngOnInit() {
    this.selectRangeOption(null, this.initialRangeOption);
  }

  get arrowsIsVisible(): boolean {
    return [_t(DateRangeOptions.Week), _t(DateRangeOptions.Month), _t(DateRangeOptions.Quarter), _t(DateRangeOptions.Year)].indexOf(<any>this.selectedRangeOption) > -1;
  }

  closeDropdowns(): void {
    this.datePickerIsOpened = false;
    this.optionsIsOpened = false;
  }

  /**
   * Apply dates selected in datepicker
   */
  customRangeSelected(): void {
    if (this.customDateRangeModel && this.customDateRangeModel[0] && this.customDateRangeModel[1]) {
      this.dateRange.startDate = this.customDateRangeModel[0];
      this.dateRange.endDate = this.customDateRangeModel[1];
      this.datePickerIsOpened = false;
      this.emitDateRange();
      this.customDateRangeModel = null;
    }
  }

  emitDateRange(): void {
    this.onDateRangeChange.emit({
      start: this.formDatesService.formatDateToInternational(this.dateRange.startDate.toString()),
      end: this.formDatesService.formatDateToInternational(this.dateRange.endDate.toString())
    });
  }

  /**
   * Change selected period to the next or previous
   * @param event - click event object
   * @param direction - 'next' or 'prev' period
   */
  goToPeriod(event: Event, direction: string): void {
    event.stopPropagation();
    const d = this.dateRange.startDate;

    switch (direction) {
      case 'prev':

        switch (this.selectedRangeOption) {
          case _t(DateRangeOptions.Week):
            this.setWeekRange(new Date(d.getFullYear(), d.getMonth(), d.getDate() - 7));
            break;
          case _t(DateRangeOptions.Month):
            this.setMonthRange(new Date(d.getFullYear(), d.getMonth() - 1, d.getDate()));
            break;
          case _t(DateRangeOptions.Quarter):
            this.setQuarterRange(new Date(d.getFullYear(), d.getMonth() - 3, d.getDate()));
            break;
          case _t(DateRangeOptions.Year):
            this.setYearRange(new Date(d.getFullYear() - 1, d.getMonth(), d.getDate()));
            break;
        }
        break;

      case 'next':

        switch (this.selectedRangeOption) {
          case _t(DateRangeOptions.Week):
            this.setWeekRange(new Date(d.getFullYear(), d.getMonth(), d.getDate() + 7));
            break;
          case _t(DateRangeOptions.Month):
            this.setMonthRange(new Date(d.getFullYear(), d.getMonth() + 1, d.getDate()));
            break;
          case _t(DateRangeOptions.Quarter):
            this.setQuarterRange(new Date(d.getFullYear(), d.getMonth() + 3, d.getDate()));
            break;
          case _t(DateRangeOptions.Year):
            this.setYearRange(new Date(d.getFullYear() + 1, d.getMonth(), d.getDate()));
            break;
        }
        break;
    }

    this.emitDateRange();
  }

  openOptions(): void {
    this.datePickerIsOpened = false;
    this.customDateRangeModel = null;
    this.optionsIsOpened = !this.optionsIsOpened;
  }

  /**
   * Select range option handler
   * @param event - click event, need stopPropagation for out click handling
   * @param option - chosen option
   */
  selectRangeOption(event: Event, option: string): void {
    if (event) {
      event.stopPropagation();
    }

    switch (option) {
      case _t(DateRangeOptions.Last7Days) : this.setLastDays(new Date(), 7); break;
      case _t(DateRangeOptions.Last30Days) : this.setLastDays(new Date(), 30); break;
      case _t(DateRangeOptions.Week) : this.setWeekRange(new Date()); break;
      case _t(DateRangeOptions.Month) : this.setMonthRange(new Date()); break;
      case _t(DateRangeOptions.Quarter) : this.setQuarterRange(new Date()); break;
      case _t(DateRangeOptions.Year) : this.setYearRange(new Date()); break;
      case _t(DateRangeOptions.CustomRange) : this.datePickerIsOpened = true; break;
    }
    this.selectedRangeOption = option;
    this.optionsIsOpened = false;

    if (option !== _t(DateRangeOptions.CustomRange)) {
      this.emitDateRange();
    }
  }

  setLastDays(date: Date, daysCount: number): void {
    this.dateRange.startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - daysCount);
    this.dateRange.endDate = date;
  }

  setWeekRange(date: Date): void {
    this.dateRange.startDate = this.formDatesService.getMondayOfCurrentWeek(date);
    this.dateRange.endDate = this.formDatesService.getSundayOfCurrentWeek(date);
  }

  setMonthRange(date: Date): void {
    this.dateRange.startDate = this.formDatesService.getFirstDayOfCurrentMonth(date);
    this.dateRange.endDate = this.formDatesService.getLastDayOfCurrentMonth(date);
  }

  setQuarterRange(date: Date): void {
    this.dateRange.startDate = this.formDatesService.getFirstDayOfCurrentQuarter(date);
    this.dateRange.endDate = this.formDatesService.getLastDayOfCurrentQuarter(date);
  }

  setYearRange(date: Date): void {
    this.dateRange.startDate = this.formDatesService.getFirstDayOfCurrentYear(date);
    this.dateRange.endDate = this.formDatesService.getLastDayOfCurrentYear(date);
  }
}

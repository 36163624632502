import { NavigationStart, Router, Event } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { Injectable, RendererFactory2, Renderer2 } from '@angular/core';
import { MARKETPLACE_APPS_CONFIG } from '../../../../../configs/marketplace-apps.config';
import { IIntrojsStep, IntroJSCurrentStep } from '../intro-js-models';
import { INSURANCE_SUMMARY_STEPS } from './insurance-summary-steps';

declare const introJs;

@Injectable()
export class InsuranceSummaryIntrojsService {
  private appId: string = MARKETPLACE_APPS_CONFIG.contactDetailsApps.insuranceSummary;
  private introJsTour: any;
  private renderer: Renderer2;
  stepBehaviourSubject: Subject<IntroJSCurrentStep> = new Subject();
  private steps: IIntrojsStep[] = INSURANCE_SUMMARY_STEPS;
  private tourIsProcessingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get introJsInsuranceSummaryWasSeen(): boolean {
    return this.localStorageService.retrieve('introJsInsuranceSummaryWasSeen');
  }

  get tourIsProcessing(): boolean {
    return this.tourIsProcessingSubject.value;
  }

  set tourIsProcessing(isProcessing: boolean) {
    this.tourIsProcessingSubject.next(isProcessing);
  }

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private translateService: TranslateService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initTour(): void {
    this.tourIsProcessing = true;

    this.steps.forEach((step) => step.intro = this.translateService.instant(step.intro));

    this.introJsTour = introJs().setOptions({
      exitOnOverlayClick: false,
      keyboardNavigation: false,
      nextLabel: '',
      prevLabel: '',
      doneLabel: '',
      overlayOpacity: 0.4,
      showStepNumbers: false,
      steps: this.steps
    });

    this.localStorageService.store('introJsInsuranceSummaryWasSeen', true);

    this.configureExit();
    this.startTour();
  }

  startTour(): void {
    this.configureBeforeStartTour();
    this.introJsTour.start();
    this.toggleBodyScrolling(false);

    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        this.introJsTour.exit();
      }
    });
  }

  private configureBeforeStartTour(): void {
    const sideBar = document.querySelector('app-sidebar');
    this.renderer.setStyle(sideBar, 'z-index', '-1');

    const productsTable = document.querySelector('app-insurance-summary-products-table');
    this.renderer.setStyle(productsTable, 'overflow-x', 'hidden');

    const productRowNewPolicyIntrojs = document.querySelector('#product-row-new-policy-introjs');
    this.renderer.setStyle(productRowNewPolicyIntrojs, 'position', 'initial');
    this.renderer.setStyle(productRowNewPolicyIntrojs, 'width', 'calc(100% - 4px)');

    const productRowTotalIntrojs = document.querySelector('#product-row-total-introjs');
    this.renderer.setStyle(productRowTotalIntrojs, 'margin-top', '10px');

    const insuranceSummaryStep1 = document.querySelector(`#${this.appId}-step-1`);
    this.renderer.setStyle(insuranceSummaryStep1, 'overflow-x', 'hidden');

    const productRowTotalIntrojsWrap = document.querySelector(`#${this.appId}-step-2`);
    this.renderer.setStyle(productRowTotalIntrojsWrap, 'overflow-x', 'hidden');

    const fixParent = document.querySelector(`#${this.appId}-introjs-fixParent`);

    this.renderer.setStyle(fixParent, 'position', 'absolute');
    this.renderer.setStyle(fixParent, 'height', 'auto');

    const appRouterWrapper =  document.querySelector('#app-router-outlet-wrapper');
    this.renderer.setStyle(appRouterWrapper, 'marginLeft', '0');

    this.introJsTour.onafterchange(() => {
      setTimeout(() => {
        this.introJsTour.refresh();
      }, 10)
    });

    this.introJsTour.onbeforechange(targetElement => {
      this.stepBehaviourSubject.next(new IntroJSCurrentStep(this.introJsTour._currentStep, this.introJsTour._direction));
    });
  }

  private toggleBodyScrolling(enable: boolean): void {
    if (enable) {
      this.renderer.removeStyle(document.body, 'overflow'); // enable body scrolling
    } else {
      this.renderer.setStyle(document.body, 'overflow', 'hidden'); // disable body scrolling
    }
  }

  private configureExit(): void {
    this.introJsTour.onexit(() => {
      this.stepBehaviourSubject.next(new IntroJSCurrentStep(null, null));

      const sideBar = document.querySelector('app-sidebar');
      this.renderer.removeStyle(sideBar, 'z-index');

      const productsTable = document.querySelector('app-insurance-summary-products-table');
      if (productsTable) {
        this.renderer.removeStyle(productsTable, 'overflow-x');
      }

      const productRowNewPolicyIntrojs = document.querySelector('#product-row-new-policy-introjs');
      if (productRowNewPolicyIntrojs) {
        this.renderer.removeStyle(productRowNewPolicyIntrojs, 'position');
        this.renderer.removeStyle(productRowNewPolicyIntrojs, 'width');
      }

      const productRowTotalIntrojs = document.querySelector('#product-row-total-introjs');
      if (productRowTotalIntrojs) {
        this.renderer.removeStyle(productRowTotalIntrojs, 'margin-top');
      }

      const productRowTotalIntrojsWrap = document.querySelector(`#${this.appId}-step-2`);
      if (productRowTotalIntrojsWrap) {
        this.renderer.removeStyle(productRowTotalIntrojsWrap, 'overflow-x');
      }

      const insuranceSummaryStep1 = document.querySelector(`#${this.appId}-step-1`);
      if (insuranceSummaryStep1) {
        this.renderer.removeStyle(insuranceSummaryStep1, 'overflow-x');
      }

      const fixParent = document.querySelector(`#${this.appId}-introjs-fixParent`);
      if (fixParent) {
        this.renderer.removeStyle(fixParent, 'position');
        this.renderer.removeStyle(fixParent, 'height');
      }

      const appRouterWrapper =  document.querySelector('#app-router-outlet-wrapper');
      this.renderer.removeStyle(appRouterWrapper, 'marginLeft');

      this.toggleBodyScrolling(true);
    })
  }
}

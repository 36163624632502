import { Pipe, PipeTransform } from '@angular/core';
import { FormChoicesService } from '../../../core/services/form-helpers/form-choises.service';

@Pipe({
  name: 'priorityTitle'
})
export class PriorityTitlePipe implements PipeTransform {

  constructor(
    private formChoicesService: FormChoicesService
  ) { }

  transform(id: string): string {
    const result = this.formChoicesService.PRIORITY_TYPES_TYPES.find(i => i.id === id);
    return result ? result.text : '';
  }
}

import { Injectable } from '@angular/core';
import { _t } from '../../other/translate-marker';

export class FormChoiceType {
  id: string | boolean;
  text: string;

  constructor() {};
}

export interface PrioritySubCategoryFormChoiceType extends FormChoiceType {
  parentCategory: string;
}

export interface PriorityTypeFormChoiceType extends FormChoiceType {
  parentSubCategory: string;
}

export interface PoweredFormChoiceType extends FormChoiceType {
  power: number;
};

export interface InsurancePolicyStatusChoiceType extends FormChoiceType {
  labelColor: string;
};

export interface OpportunityStatusChoiceType extends FormChoiceType {
  group: string;
};

export interface PremiumPaymentFrequencyChoiceType extends FormChoiceType {
  numberInYear: number;
};

@Injectable()
export class FormChoicesService {

  public readonly TITLE_TYPES: Array<FormChoiceType> = [
    {id: 'mr', text: _t('contacts.personal.titleTypes.mr')},
    {id: 'ms', text: _t('contacts.personal.titleTypes.ms')},
    {id: 'mrs', text: _t('contacts.personal.titleTypes.mrs')},
    {id: 'mdm', text: _t('contacts.personal.titleTypes.mdm')},
    {id: 'dr', text: _t('contacts.personal.titleTypes.dr')}
  ];

  public readonly CONSENT_STATUSES_TYPES: Array<FormChoiceType> = [
    {id: true, text: _t('contacts.personal.consent.given')},
    {id: false, text: _t('contacts.personal.consent.notGiven')}
  ];

  public readonly CONTACT_TYPES: Array<FormChoiceType> = [
    {id: 'lead', text: _t('common.lead')},
    {id: 'prospect', text: _t('common.prospect')},
    {id: 'client', text: _t('common.client')}
  ];

  public readonly AVAILABLE_CONTACT_METHODS: Array<FormChoiceType> = [
    {id: 'ph', text: _t('contacts.personal.consent.contactMethods.phone')},
    {id: 't', text: _t('contacts.personal.consent.contactMethods.text')},
    {id: 'em', text: _t('contacts.personal.consent.contactMethods.email')},
    {id: 'fa', text: _t('contacts.personal.consent.contactMethods.fax')}
  ];

  public readonly BOOLEAN_TYPES: Array<FormChoiceType> = [
    {id: true, text: _t('common.yes')},
    {id: false, text: _t('common.no')}
  ];

  public readonly GENDER_TYPES: Array<FormChoiceType> = [
    {id: 'M', text: _t('contacts.personal.male')},
    {id: 'F', text: _t('contacts.personal.female')}
  ];

  public readonly DEPENDENT_RELATIONSHIPS_TYPES: Array<string> = [
    _t('common.relationshipTypes.mother'),
    _t('common.relationshipTypes.father'),
    _t('common.relationshipTypes.spouse'),
    _t('common.relationshipTypes.daughter'),
    _t('common.relationshipTypes.son'),
  ];

  public readonly FAMILY_MEMBER_RELATIONSHIPS_TYPES: Array<string> = [
    _t('common.relationshipTypes.mother'),
    _t('common.relationshipTypes.father'),
    _t('common.relationshipTypes.grandmother'),
    _t('common.relationshipTypes.grandfather'),
    _t('common.relationshipTypes.uncle'),
    _t('common.relationshipTypes.aunt'),
  ];

  public readonly EMPLOYMENT_TYPES: Array<FormChoiceType> = [
    {id: 'ft', text: _t('contacts.personal.occupation.fullTime')},
    {id: 'pt', text: _t('contacts.personal.occupation.partTime')},
    {id: 'retired', text: _t('contacts.personal.occupation.retired')},
    {id: 'self', text: _t('contacts.personal.occupation.selfEmployed')},
    {id: 'unemployed', text: _t('contacts.personal.occupation.unemployed')}
  ];

  public readonly MARITAL_STATUS_TYPES: Array<FormChoiceType> = [
    {id: 'sin', text: _t('contacts.personal.maritalStatus.single')},
    {id: 'mar', text: _t('contacts.personal.maritalStatus.married')},
    {id: 'div', text: _t('contacts.personal.maritalStatus.divorced')},
    {id: 'wid', text: _t('contacts.personal.maritalStatus.widowed')}
  ];

  public readonly PRIORITY_CATEGORIES_TYPES: Array<FormChoiceType> = [
    { id: 'risk_management', text: _t('contacts.priorities.categoryType.riskManagement')},
    { id: 'wealth_accumulation', text: _t('contacts.priorities.categoryType.wealthAccumulationPreservation')}
  ];

  public readonly PRIORITY_SUB_CATEGORIES_TYPES: Array<PrioritySubCategoryFormChoiceType> = [
    { id: 'protection', text: _t('contacts.priorities.categoryType.protection'), parentCategory: 'risk_management'},
    { id: 'health', text: _t('contacts.priorities.categoryType.health'), parentCategory: 'risk_management'},
    { id: 'retirement', text: _t('contacts.priorities.categoryType.retirement'), parentCategory: 'wealth_accumulation'},
    { id: 'education', text: _t('contacts.priorities.categoryType.education'), parentCategory: 'wealth_accumulation'},
    { id: 'savings', text: _t('contacts.priorities.categoryType.savingsInvestments'), parentCategory: 'wealth_accumulation'},
    { id: 'others', text: _t('common.others'), parentCategory: 'risk_management'}
  ];

  public readonly PRIORITY_TYPES_TYPES: Array<PriorityTypeFormChoiceType> = [
    { id: 'family_income', text: _t('contacts.priorities.categoryType.familyIncomeProtection'), parentSubCategory: 'protection'},
    { id: 'critical_illness', text: _t('contacts.priorities.categoryType.criticalIllnessProtection'), parentSubCategory: 'protection'},
    { id: 'disability_income', text: _t('contacts.priorities.categoryType.disabilityIncomeProtection'), parentSubCategory: 'protection'},
    { id: 'personal_accident', text: _t('contacts.priorities.categoryType.personalAccidentProtection'), parentSubCategory: 'protection'},
    { id: 'planning_childern_headstart', text: _t('contacts.priorities.categoryType.planningHeadstart'), parentSubCategory: 'protection'},
    { id: 'retirement_planning', text: _t('contacts.priorities.categoryType.retirementPlanning'), parentSubCategory: 'retirement'},
    { id: 'education_planning', text: _t('contacts.priorities.categoryType.educationPlanning'), parentSubCategory: 'education'},
    { id: 'hospitalisation_cost_protection', text: _t('contacts.priorities.categoryType.hospitalisationCost'), parentSubCategory: 'health'},
    { id: 'savings_goal', text: _t('contacts.priorities.categoryType.planningSpecificGoals'), parentSubCategory: 'savings'},
    { id: 'others_risk', text: _t('contacts.priorities.categoryType.othersRiskManagement'), parentSubCategory: 'others'},
    { id: 'others_wealth', text: _t('contacts.priorities.categoryType.othersWealthAccumulation'), parentSubCategory: 'savings'}
  ];

  public readonly PRIORITY_LEVELS_TYPES: Array<FormChoiceType> = [
    { id: 'low', text: _t('contacts.priorities.priorityLevels.low')},
    { id: 'medium', text: _t('contacts.priorities.priorityLevels.medium')},
    { id: 'high', text: _t('contacts.priorities.priorityLevels.high')}
  ];

  public readonly HOSPITAL_TYPES: PoweredFormChoiceType[] = [
    { id: 'private', text: _t('contacts.priorities.hospitalTypes.private'), power: 2 },
    { id: 'public', text: _t('contacts.priorities.hospitalTypes.public'), power: 1 },
    { id: 'none', text: _t('common.none'), power: 0 }
  ];

  public readonly HOSPITALISATION_WARD_TYPES: PoweredFormChoiceType[] = [
    { id: 'a', text: 'A', power: 4 },
    { id: 'b1', text: 'B1', power: 3 },
    { id: 'b2', text: 'B2', power: 2 },
    { id: 'c', text: 'C', power: 1 },
    { id: 'none', text: _t('common.none'), power: 0 }
  ];

  public readonly INSURANCE_POLICY_STATUSES_TYPES: InsurancePolicyStatusChoiceType[] = [
    { id: 'pending', text: _t('common.statusesTypes.pending'), labelColor: '#FFAB00'},
    { id: 'active', text: _t('common.statusesTypes.active'), labelColor: '#00C7B0'},
    { id: 'expired', text: _t('common.statusesTypes.expired'), labelColor: '#F56C62'},
    { id: 'terminated', text: _t('common.statusesTypes.terminated'), labelColor: '#F56C62'},
    { id: 'freelooked', text: _t('common.statusesTypes.freelooked'), labelColor: '#FFAB00'},
    { id: 'matured', text: _t('common.statusesTypes.matured'), labelColor: '#00C7B0'},
    { id: 'surrender', text: _t('common.statusesTypes.surrendered'), labelColor: '#00C7B0'},
    { id: 'suspended', text: _t('common.statusesTypes.suspended'), labelColor: '#FFAB00'},
    { id: 'lapsed', text: _t('common.statusesTypes.lapsed'), labelColor: '#F56C62'}
  ];

  public readonly PREMIUM_PAYMENT_FREQUENCIES_TYPES: PremiumPaymentFrequencyChoiceType[] = [
    { id: 'single', text: _t('common.frequencies.single'), numberInYear: null },
    { id: 'week', text: _t('common.frequencies.weekly'), numberInYear: 52 },
    { id: 'month', text: _t('common.frequencies.monthly'), numberInYear: 12 },
    { id: 'quarter', text: _t('common.frequencies.quarterly'), numberInYear: 4 },
    { id: 'half-yearly', text: _t('common.frequencies.half_yearly'), numberInYear: 2 },
    { id: 'year', text: _t('common.frequencies.yearly'), numberInYear: 1 }
  ];

  public readonly COVERAGE_TYPES_TYPES: FormChoiceType[] = [
    { id: 'personal', text: _t('contacts.personal.personalTitle')},
    { id: 'family', text: _t('common.family')}
  ];

  public readonly COVERAGE_REGIONS_TYPES: FormChoiceType[] = [
    { id: 'countries', text: _t('common.regions.specificCountries')},
    { id: 'world', text: _t('common.regions.worldwide')},
    { id: 'asia', text: _t('common.regions.asia')},
    { id: 'europe', text: _t('common.regions.europe')},
    { id: 'america', text: _t('common.regions.america')},
    { id: 'africa', text: _t('common.regions.africa')}
  ];

  public readonly RIDER_COVERAGE_TYPES: FormChoiceType[] = [
    { id: 'none', text: _t('common.none')},
    { id: 'full', text: _t('common.full')},
    { id: 'partial', text: _t('common.partial')}
  ];

  public readonly PAYOUT_FREQUENCY_TYPES: FormChoiceType[] = [
    { id: 'month', text: _t('common.frequencies.monthly')},
    { id: 'quarter', text: _t('common.frequencies.quarterly')},
    { id: 'year', text: _t('common.frequencies.yearly')}
  ];

  public readonly OCCUPATION_CLASS_TYPES: FormChoiceType[] = [
    { id: '1', text: _t('common.classes.class1')},
    { id: '2', text: _t('common.classes.class2')},
    { id: '3', text: _t('common.classes.class3')},
    { id: '4', text: _t('common.classes.class4')}
  ];

  public readonly MOSQUITO_PLAN_TYPES: FormChoiceType[] = [
    { id: 'individual', text: _t('common.individual')},
    { id: 'family', text: _t('common.family')},
    { id: 'children', text: _t('common.children')},
    { id: 'others', text: _t('common.others')}
  ];

  public readonly MOSQUITO_ALERT_LEVEL_TYPES: FormChoiceType[] = [
    { id: 'green', text: _t('contacts.green')},
    { id: 'yellow', text: _t('contacts.yellow')},
    { id: 'red', text: _t('contacts.red')}
  ];

  public readonly TRAVEL_PLAN_TYPES: FormChoiceType[] = [
    { id: 'individual', text: _t('common.individual')},
    { id: 'family', text: _t('common.family')}
  ];

  public readonly OVERSEAS_STUDY_PLAN_TYPES: FormChoiceType[] = [
    { id: 'individual', text: _t('common.individual')},
    { id: 'children', text: _t('common.children')},
    { id: 'others', text: _t('common.others')}
  ];

  public readonly MOTOR_COVERAGE_TYPES_TYPES: FormChoiceType[] = [
    { id: '3rdparty', text: _t('common.motorCoverage.thirdParty')},
    { id: 'compre', text: _t('common.motorCoverage.comprehensive')},
    { id: 'key_rep', text: _t('common.motorCoverage.keyReplacement')},
    { id: 'other', text: _t('common.other')}
  ];

  public readonly VEHICLE_CLASS_TYPES: FormChoiceType[] = [
    { id: 'car', text: _t('common.vehicle.car')},
    { id: 'moto', text: _t('common.vehicle.motorcycle')},
    { id: 'van', text: _t('common.vehicle.van')}
  ];

  public readonly PROPERTY_CONSTRUCTION_STATUS_TYPES: FormChoiceType[] = [
    { id: 'completed', text: _t('common.completed')},
    { id: 'under_construction', text: _t('common.underConstruction')},
    { id: 'not_started', text: _t('common.notYetStarted')}
  ];

  public readonly PROPERTY_OWNERSHIP_TYPES: FormChoiceType[] = [
    { id: 'rented', text: _t('common.rented')},
    { id: 'owned', text: _t('common.owned')}
  ];

  public readonly GOAL_TYPES: FormChoiceType[] = [
    { id: 'opportunity', text: _t('common.opportunity')},
    { id: 'activity', text: _t('common.activity')},
    { id: 'training', text: _t('common.training')},
    { id: 'amount', text: _t('common.amount')},
    { id: 'other', text: _t('common.other')}
  ];

  public readonly ACTIVITY_PRIORITY_TYPES: FormChoiceType[] = [
    { id: 'low', text: _t('contacts.priorities.priorityLevels.low')},
    { id: 'medium', text: _t('contacts.priorities.priorityLevels.medium')},
    { id: 'high', text: _t('contacts.priorities.priorityLevels.high')}
  ];

  public readonly ACTIVITY_TYPES: FormChoiceType[] = [
    { id: 'appointment', text: _t('common.appointment')},
    { id: 'call', text: _t('common.call')},
    { id: 'task', text: _t('common.task')},
  ];

  public readonly ACTIVITY_STATUSES_TYPES: FormChoiceType[] = [
    { id: 'pending', text: _t('common.statusesTypes.pending')},
    { id: 'completed', text: _t('common.completed')},
    { id: 'cancelled', text: _t('common.cancelled')},
    { id: 'postponed', text: _t('common.postponed')}
  ];

  public readonly OPPORTUNITY_STATUSES_TYPES: OpportunityStatusChoiceType[] = [
    {id: 'new', text: _t('common.new'), group: 'open'},
    {id: 'contacted', text: _t('common.opportunityStatuses.contacted'), group: 'open'},
    {id: 'needs', text: _t('common.opportunityStatuses.identifiedNeeds'), group: 'open'},
    {id: 'recommend', text: _t('common.opportunityStatuses.madeRecommendation'), group: 'open'},
    {id: 'accepted', text: _t('common.opportunityStatuses.acceptedRecommendation'), group: 'open'},
    {id: 'submitted', text: _t('common.opportunityStatuses.submitted'), group: 'closed'},
    {id: 'incepted', text: _t('common.opportunityStatuses.incepted'), group: 'closed'},
    {id: 'lost', text: _t('common.opportunityStatuses.lost'), group: 'closed'},
  ];

  public readonly SNAP_AND_SYNC_REQUEST_STATUSES: FormChoiceType[] = [
    {id: 'draft', text: _t('common.sasRequestStatuses.draft')},
    {id: 'in-process', text: _t('common.sasRequestStatuses.snapped')},
    {id: 'cancelled', text: _t('common.cancelled')},
    {id: 'completed', text: _t('common.sasRequestStatuses.synced')},
    {id: 'rejected', text: _t('common.sasRequestStatuses.rejected')}
  ];

  public static get MONTH_LIST(): any[] {
    return [
      {id: 'January', text: _t('common.monthList.january')},
      {id: 'February', text: _t('common.monthList.february')},
      {id: 'March', text: _t('common.monthList.march')},
      {id: 'April', text: _t('common.monthList.april')},
      {id: 'May', text: _t('common.monthList.may')},
      {id: 'June', text: _t('common.monthList.june')},
      {id: 'July', text: _t('common.monthList.july')},
      {id: 'August', text: _t('common.monthList.august')},
      {id: 'September', text: _t('common.monthList.september')},
      {id: 'October', text: _t('common.monthList.october')},
      {id: 'November', text: _t('common.monthList.november')},
      {id: 'December', text: _t('common.monthList.december')}
    ];
  }

  getFormChoiceValueFromList(id: string, list: string): string {
    const data = this[list];

    for (const key in data) {
      if (data[key].id === id) {
        return data[key].text;
      }
    }
  }

  getFormChoiceValue(id: string | boolean, choicesList: Array<FormChoiceType>): string {
    let selected = '';

    choicesList.map(item => {
      if (item.id === id) {
        selected = item.text;
      }
    });
    return selected;
  }

  getFormChoice(id: string | boolean, choicesList: Array<FormChoiceType>): any {
    const formChoise = choicesList.find(item => item.id === id);
    return formChoise ? formChoise : new FormChoiceType();
  }

}

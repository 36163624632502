import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upgrade-toolbar',
  templateUrl: './upgrade-toolbar.component.html',
  styleUrls: ['./upgrade-toolbar.component.scss']
})

export class UpgradeToolbarComponent {
  constructor(
        private router: Router

    ) {};

  goBackToEdit() {
    this.router.navigate(['/profile/builder/edit']);
  }
}

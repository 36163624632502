import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'classFileExtension'
})
export class ClassFileExtensionPipe implements PipeTransform {
  transform(value: string) {
    return value.split('.').pop().split('?').shift().toLowerCase();
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, Params } from '@angular/router';
import { AuthService } from './auth.service';
import { UserTokenService } from './user.token.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private router: Router, private authService: AuthService, private userTokenService: UserTokenService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url.split('?')[0];
    const queryParams = route.queryParams;

    return this.handleAuth(url, queryParams);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }

  private handleAuth(url: string, queryParams: Params): boolean {
    if (this.authService.isLoggedIn() && this.userTokenService.isValid()) {
      return true;
    }
    this.authService.redirectUrl = url;
    this.authService.redirectQueryParams = queryParams;

    this.router.navigate(['/auth/login']);
    return false;
  }
}

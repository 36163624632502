import { Component } from '@angular/core';
import { MatDialogRef  } from '@angular/material';
import { Activity } from '../../models/activity.model';
import { DialogOption } from '../../../shared/dialog-option-card/dialog-option-card.component';
import { RouteBoundDialog } from '../../../core/route-bound-dialog/route-bound-dialog.class';
import { RouteBoundDialogService } from '../../../core/route-bound-dialog/route-bound-dialog.service';
import { _t } from 'app/modules/core/other/translate-marker';

export const ActivityTypes: DialogOption[] = [
  new DialogOption(
    'appointment',
    _t('common.appointment'),
    'ico-activity-appointment.svg'
  ),
  new DialogOption(
    'call',
    _t('common.call'),
    'ico-activity-call.svg'
  ),
  new DialogOption(
    'task',
    _t('common.task'),
    'ico-activity-task.svg'
  )
];

@Component({
  selector: 'app-activity-type-dialog',
  templateUrl: './activity-type-dialog.component.html'
})
export class ActivityTypeDialogComponent extends RouteBoundDialog {

  activity: Activity;
  activityTypes: DialogOption[] = ActivityTypes;

  constructor(
    public dialogRef: MatDialogRef<ActivityTypeDialogComponent>,
    public routeBoundDialogService: RouteBoundDialogService
  ) {
    super();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_CONFIG } from '../../configs';
import { StripeService } from '../shared/stripe/stripe.service';

export interface Card {
  id: number;
  stripe_id: string;
  created_at: string;
  name: string;
  address_line_1: string;
  address_line_1_check: string;
  address_line_2: string;
  address_city: string;
  address_state: string;
  address_country: string;
  address_zip: string;
  address_zip_check: string;
  brand: string;
  country: string;
  cvc_check: string;
  dynamic_last4: string;
  tokenization_method: string;
  exp_month: number;
  exp_year: number;
  funding: string;
  last4: string;
  fingerprint: string;
  customer: number;
}

@Injectable()
export class PaymentService {
  constructor(
    private _http: HttpClient,
    private stripe: StripeService
  ) {}

  /**
   * Add card to custromer
   *
   * @param data
   * @returns {Observable<Object>}
   */
  public saveCardToken(data: { token: string }) {
    return this._http.post(API_CONFIG.payment.saveCard, data);
  }

  /**
   * Add card to stripe service
   *
   * @param data
   * @returns {Observable<Object>}
   */
   public saveCard(data: {cardholder: string, cardNumber: string, cvc: string, expMonth: string, expYear: string}) {
    return this.stripe.createCardToken({
      name: data.cardholder,
      number: data.cardNumber,
      cvc: data.cvc,
      exp_month: data.expMonth,
      exp_year: data.expYear
    });
  }

  /**
   * return Billing History
   *
   * @returns {Observable<Object>}
   */
  public getInvoices() {
    return this._http.get(API_CONFIG.payment.invoices);
  }

  public getCards() {
    return this._http.get(API_CONFIG.payment.defaultCard);
  }

  public getDefaultCard(): Observable<Card> {
    return this.getCards()
      .pipe(map(({results}: {results: Card[]}) => results[0]));
  }
}

import { Component } from '@angular/core';

import { BaseComponent } from '../../core/base/base-component';
import {LocalStorageService} from "ngx-webstorage";
import {Router} from "@angular/router";

@Component({
    selector: 'profile-not-found',
    templateUrl: './profile-not-found.component.html',
    styleUrls: ['./profile-not-found.component.scss']
})
export class ProfileNotFoundComponent extends BaseComponent {

    profileUrl: string;

    constructor(private _storage: LocalStorageService,
                private router: Router) {
        super();
        this.profileUrl = 'profile/' + this._storage.retrieve('user_id');
    }

    tryAgain (): void {
        this.router.navigate([this.profileUrl]);
    }
}

import { Component } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageLanguageKey } from './../../../api/language.interceptor';
import { LOCALIZATION_CONFIG, ILanguageData } from './../../../../configs/localization.config';


@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent {
  languages: ILanguageData[];

  get currentLanguageCode() {
    return this.storage.retrieve(LocalStorageLanguageKey) || LOCALIZATION_CONFIG.SUPPORTED_LANGUAGES.find(l => l.isDefault).code;
  }

  constructor(private storage: LocalStorageService, private translate: TranslateService) {
    this.languages = LOCALIZATION_CONFIG.SUPPORTED_LANGUAGES;
  }

  changeLanguage(language: ILanguageData) {
    this.storage.store(LocalStorageLanguageKey, language.code);
    this.translate.use(language.code);
  }
}

import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CountryListService } from './services/country-list/country-list.service';
import { DialogObserverService } from './services/dialog-observer/dialog-observer.service';
import { FormChoicesService } from './services/form-helpers/form-choises.service';
import { ResolutionHelperService } from './services/resolution/resolution.service';
import { RouteBoundDialogService } from './route-bound-dialog/route-bound-dialog.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import './other/date-prototype-modificator';
import { AuthService } from '../auth/services/auth.service';
import { ContactDetailsObserverService } from './services/contact-details-observer/contact-details-observer.service';
import { UserTokenService } from '../auth/services/user.token.service';
import { SocialModule } from '../auth/social/social.module';


// intro.js services
import { ContactsIntrojsService } from './services/intro.js/contacts/contacts-introjs.service';
import { InsuranceSummaryIntrojsService } from './services/intro.js/insurance-summary/insurance-summary-introjs.service';
import { SnapAndSyncIntrojsService } from './services/intro.js/snap-and-sync/snap-and-sync-introjs.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SocialModule,
  ],
  providers: [
    AuthService,
    ContactDetailsObserverService,
    ContactsIntrojsService,
    CountryListService,
    DialogObserverService,
    FormChoicesService,
    InsuranceSummaryIntrojsService,
    ResolutionHelperService,
    RouteBoundDialogService,
    SnapAndSyncIntrojsService,
    UserTokenService,
  ]
})
export class CoreModule {
  constructor( @Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

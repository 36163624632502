import { _t } from 'app/modules/core/other/translate-marker';

export interface IPaymentFormConfig {
  mode: string;
  actionDescriptionText: string | null;
  actionExtraText: string | null;
}

export class PaymentFormConfig {

  static CARD_ADD_SUCCESSES =  _t('shared.creditCardSuccess');
  static UPGRADE_SUCCESSES  = _t('shared.subscriptionPlanSuccess');
  static UPGRADE_ERROR      = _t('shared.somethingWentWrong');

  static get SIGN_UP_MODE(): IPaymentFormConfig {
    return {
      mode: 'sign-up-mode',
      actionDescriptionText: null,
      actionExtraText: null
    }
  }

  static get UPGRADE_MODE(): IPaymentFormConfig {
    return {
      mode: 'upgrade-mode',
      actionDescriptionText: _t('shared.planWillBeUpgraded'),
      actionExtraText: _t('shared.unusedPortionYour')
    }
  }
}

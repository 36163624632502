import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router, NavigationStart, Event } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ResolutionHelperService } from '../../resolution/resolution.service';
import { IIntrojsStep, StepDirection, IntroJSCurrentStep } from './../intro-js-models';
import { SNAP_AND_SYNC_STEPS } from './snap-and-sync-steps';

declare const introJs;

@Injectable()
export class SnapAndSyncIntrojsService {
  private introJsTour: any;
  private renderer: Renderer2;

  private tourIsProcessingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  stepBehaviourSubject: Subject<IntroJSCurrentStep> = new Subject();
  temporaryExitValue = 'temporary';
  private steps: IIntrojsStep[] = SNAP_AND_SYNC_STEPS;

  get introJsSnapAndSyncWasSeen(): boolean {
    return this.localStorageService.retrieve('introJsSnapAndSyncWasSeen');
  }

  get tourIsProcessing(): boolean {
    return this.tourIsProcessingSubject.value;
  }

  set tourIsProcessing(isProcessing: boolean) {
    this.tourIsProcessingSubject.next(isProcessing);
  }

  constructor(
    private localStorageService: LocalStorageService,
    private resolutionHelperService: ResolutionHelperService,
    private router: Router,
    private translateService: TranslateService,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  initTour(): void {
    this.tourIsProcessing = true;

    this.steps.forEach((step) => step.intro = this.translateService.instant(step.intro));

    this.introJsTour = introJs().setOptions({
      exitOnOverlayClick: false,
      keyboardNavigation: false,
      nextLabel: '',
      prevLabel: '',
      doneLabel: '',
      overlayOpacity: 0.4,
      showStepNumbers: false,
      steps: this.steps
    });

    this.localStorageService.store('introJsSnapAndSyncWasSeen', true);

    this.configureBeforeStepChanghes();
    this.configureAfterStepChanghes();
    this.configureExit();
  }


  startTour(step: number = 0): void {
    if (this.introJsTour) {
      if (step === 1) {
        this.introJsTour.start().goToStep(step + 2).previousStep();
      } else {
        this.introJsTour.start().goToStep(step + 1);
      }
      this.toggleBodyScrolling(false);
    }

    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        this.introJsTour.exit();
      }
    });
  }

  private configureBeforeStepChanghes(): void {
    this.introJsTour.onbeforechange(targetElement => {

      this.stepBehaviourSubject.next(new IntroJSCurrentStep(this.introJsTour._currentStep, this.introJsTour._direction));

      switch (this.introJsTour._currentStep) {
        case 0:
          this.showDialogOverlapingElements();
          break;
        case 1:
          if (this.introJsTour._direction === StepDirection.forward) {
            setTimeout(() => this.introJsTour.exit(this.temporaryExitValue));
          }
          break;
      }
    });
  }


  private configureAfterStepChanghes(): void {
    this.introJsTour.onafterchange(targetElement => {

      switch (this.introJsTour._currentStep) {
        case 0: {
          const fixParents = this.steps[0].custom.fixParents = document.querySelectorAll('.introjs-fixParent');

          [].forEach.call(fixParents, fixParent => {
            this.renderer.setStyle(fixParent, 'transition', 'unset');
            this.renderer.setStyle(fixParent, 'width', '100%');
            this.renderer.setStyle(fixParent, 'position', 'absolute');
          })
        } break;

        case 1: {
          if (this.introJsTour._direction === StepDirection.backward) {
            this.configurInDialogAfterStepChange(this.introJsTour._currentStep);
          }
        } break;
        case 2:
        case 3:
        case 4:
        case 5:
          this.configurInDialogAfterStepChange(this.introJsTour._currentStep);
          break;

        default: this.restoreStateAfterStep(1); break;
      }
    });
  }

  private configureExit(): void {
    this.introJsTour.onexit(() => {
      this.stepBehaviourSubject.next(new IntroJSCurrentStep(null, null));

      if (!this.introJsTour.exit.arguments || this.introJsTour.exit.arguments[0] !== this.temporaryExitValue) {
        this.tourIsProcessing = false;
        this.showDialogOverlapingElements();
      }

      this.restoreStateAfterStep(0);
      this.toggleBodyScrolling(true);
    })
  }

  private restoreStateAfterStep(step: number): void {
    switch (step) {
      case 0: {
          const fixParents = this.steps[step].custom.fixParents;

          if (fixParents) {
            [].forEach.call(fixParents, fixParent => {
              this.renderer.removeStyle(fixParent, 'width');
              this.renderer.removeStyle(fixParent, 'position');
              setTimeout(() => this.renderer.removeStyle(fixParent, 'transition'), 400);
            })
          }
      } break;

      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        this.restoreInDialogElements(step);
        break;
    }
  }

  private configurInDialogAfterStepChange(step: number): void {
    const fixParents = this.steps[1].custom.fixParents = document.querySelectorAll('.introjs-fixParent');

    [].forEach.call(fixParents, (fixParent: HTMLElement) => {
      if (fixParent.tagName.toLowerCase() === 'mat-dialog-container') {
        return;
      }

      this.renderer.setStyle(fixParent, 'position', 'absolute');
    });

    const mDOverlay = this.steps[1].custom.matDialogOverlay = document.querySelector('.cdk-overlay-backdrop');
    this.renderer.setStyle(mDOverlay, 'display', 'none');

    this.hideDialogOverlapingElements();
  }

  private restoreInDialogElements(step: number): void {
    const fixParents = this.steps[step].custom.fixParents;

    if (fixParents) {
      [].forEach.call(fixParents, fixParent => {
        this.renderer.removeStyle(fixParent, 'position');
      })
    }

    const mDOverlay = this.steps[step].custom.matDialogOverlay;
    if (mDOverlay) {
      this.renderer.removeStyle(mDOverlay, 'display');
    }
  }

  private toggleBodyScrolling(enable: boolean): void {
    if (enable) {
      this.renderer.removeStyle(document.body, 'overflow'); // enable body scrolling
    } else {
      this.renderer.setStyle(document.body, 'overflow', 'hidden'); // disable body scrolling
    }
  }

  private showDialogOverlapingElements(): void {
    const prioritiesCards = document.querySelector('.priorities-cards-grid');
    if (this.resolutionHelperService.isMobile && prioritiesCards) {
      this.renderer.removeStyle(prioritiesCards, 'display');
    }

    const toolbar = document.querySelector('#toolbar');
    if (toolbar) {
      this.renderer.removeStyle(toolbar, 'z-index');
    }
  }

  private hideDialogOverlapingElements(): void {
    const prioritiesCards = document.querySelector('.priorities-cards-grid');
    if (this.resolutionHelperService.isMobile && prioritiesCards) {
      this.renderer.setStyle(prioritiesCards, 'display', 'none');
    }

    this.renderer.setStyle(document.querySelector('#toolbar'), 'z-index', '0');
  }
}
